import React, { useCallback, useEffect } from "react";
import { renderToString } from "react-dom/server";
import { format } from "date-fns";
import { getCustomer } from "../../../services/api/CustomerService";
import { Config } from "datatables.net-dt";
import { Icon } from "../../../components/atoms/Icon";
import { useToast } from "../../../components/atoms/Toast";
import { useErrorHandler } from "../../../hooks/useErrorHandler";
import { CustomerFilters } from "../../../models/CustomerFilters";
// Configuring DataTables
import $ from "jquery";
import ptbr from "../../../assets/lib/js/dataTables/pt-br.json";
import "datatables.net-bs5";
import "datatables.net-responsive";
import "datatables.net-buttons-bs5";
import "datatables.net-buttons/js/buttons.html5";
import JSZip from "jszip";
import { Customer } from "../../../models/Customer";
// @ts-ignore
window.JSZip = JSZip;

interface CustomerListTableProps {
  tableRef: any;
  filters: CustomerFilters;
}

export const CustomerListTable: React.FC<CustomerListTableProps> = ({
  tableRef,
  filters,
}) => {
  const toast = useToast();
  const { handleError } = useErrorHandler();

  const fetchCustomer = useCallback(
    async (currentPage: number, length: number, draw: any, callback: any) => {
      try {
        const result = await getCustomer(currentPage, length, filters);
        callback({
          draw: draw,
          recordsTotal: result.recordsTotal,
          recordsFiltered: result.recordsFiltered,
          data: result.data 
            ? result.data.sort((a: Customer, b: Customer) => new Date(b.dateCreated!).getTime() - new Date(a.dateCreated!).getTime()) 
            : result.data
        });
      } catch (error) {
        handleError(error, toast);
      }
    },
    [filters, handleError, toast]
  );

  useEffect(() => {
    if (!tableRef.current) return;

    const dataTableOptions: Config = {
      dom: "B<'row my-1'<'col-6'l><'col-6'f>>rt<'row my-1'<'col-12 d-flex justify-content-center'p>>",
      language: ptbr,
      lengthChange: false,
      searching: false,
      columns: [
        { data: "customerId", width: "10%", type: "string" },
        { data: "cpfcnpj", width: "10%", type: "string" },
        { data: "name", width: "30%", type: "string" },
        { data: "resellerName", width: "12.5%", type: "string" },
        {
          data: "cotermDate",
          width: "12.5%",
          type: "string",
          render: (data: string) => {
            if (data) return format(new Date(data), "dd/MM/yyyy");
            else return "-";
          },
        },
        { data: "proRata", width: "12.5%", type: "string" },
        {
          data: "adobeStatus",
          width: "12.5%",
          render: (data: string) => {
            let statusText: string;

            switch (data) {
              case "Pending":
                statusText = "Pendente";
                break;
              case "Processing":
                statusText = "Processando";
                break;
              case "Completed":
                statusText = "Completo";
                break;
              case "Failed":
                statusText = "Falha";
                break;
              default:
                statusText = "Desconhecido";
                break;
            }
            return statusText;
          },
        },
        {
          data: "id",
          orderable: false,
          render: (id: number) => {
            return renderToString(
              <a href={"/customer/edit/" + id} className="d-block text-end">
                <Icon className="fa fa-fw fa-pen" />
              </a>
            );
          },
        },
      ],
      buttons: [
        {
          extend: "excelHtml5",
          text: '<i class="fa fa-file-excel"></i>',
          filename: "Clientes",
          className: "btn btn-success",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "csvHtml5",
          text: '<i class="fa fa-file-csv"></i>',
          filename: "Clientes",
          className: "btn btn-info",
          exportOptions: { columns: ":visible" },
          init: function (api, node) {
            $(node).css("margin-left", "5px");
          },
        },
      ],
      ordering: false,
      serverSide: true,
      processing: true,
      scrollY: "calc(100vh - 390px - 11.75em)",
      lengthMenu: [
        [10, 64, 128, 256, 512],
        [10, 64, 128, 256, 512],
      ],
      ajax: (data: any, callback: any) => {
        const { start, length, draw } = data;
        const currentPage = Math.floor(start / length);
        fetchCustomer(currentPage, length, draw, callback);
      },
    };

    if ($.fn.dataTable.isDataTable(tableRef.current))
      $(tableRef.current).DataTable().destroy();

    $(tableRef.current).DataTable({ ...dataTableOptions });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <table ref={tableRef} className="table table-sm table-striped">
      <thead>
        <tr>
          <th>Customer ID</th>
          <th>CNPJ</th>
          <th>Cliente</th>
          <th>Revenda</th>
          <th>Aniversário</th>
          <th>Pro rata</th>
          <th>Status</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody></tbody>
    </table>
  );
};
