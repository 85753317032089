import React, { useCallback, useEffect, useRef, useState } from 'react';
import DataTable, { DataTableRef } from 'datatables.net-react';
import DT from 'datatables.net-dt';
import ptbr from "../../../assets/lib/js/dataTables/pt-br.json";
import 'datatables.net-bs5';
import 'datatables.net-responsive';
import 'datatables.net-buttons-bs5';
import { useToast } from '../../../components/atoms/Toast';
import { Card } from '../../../components/mols/Card';
import { getActiveProductsByCustomerId } from '../../../services/api/CustomerService';
import { renderToString } from 'react-dom/server';
import { SwitchBtn } from '../../../components/atoms/SwitchBtn';

DataTable.use(DT);

interface CustomerActiveProductsProps {
    customerId: number;
};

export const CustomerActiveProducts: React.FC<CustomerActiveProductsProps> = ({ customerId }) => {
    const Toast = useToast();

    const dataTableRef = useRef<DataTableRef>(null);

    const loadTableRef = useRef<(data: any, callback: any) => void>();

    const loadTable = useCallback(async (data: any, callback: any) => {
        const { start, length, draw } = data;
        const currentPage = Math.floor(start / length);

        const newFilters = { page: currentPage, size: length };

        try {

            const result = await getActiveProductsByCustomerId(customerId);

            callback({
                draw: draw,
                recordsTotal: result?.length,
                recordsFiltered: result?.length,
                data: result
            });
        } catch (error) {
            if (error instanceof Error) {
                Toast.fire({
                    icon: "error",
                    title: error.message,
                });
            }

            callback({
                draw: draw,
                recordsTotal: 0,
                recordsFiltered: 0,
                data: [],
            });
        }
    }, [Toast, customerId]);

    useEffect(() => {
        loadTableRef.current = loadTable;
    }, [customerId, loadTable]);

    useEffect(() => {
        if (dataTableRef.current) {
            dataTableRef.current.dt()?.ajax.reload();
        }
    }, [customerId]);

    return (
        <Card>
            <DataTable
                ref={dataTableRef}
                className="table table-sm table-striped"
                options={{
                    language: ptbr,
                    ordering: false,
                    serverSide: true,
                    processing: true,
                    lengthChange: false,
                    search: false,
                    searching: false,
                    autoWidth: false,
                    columnDefs: [
                        { width: "25%", targets: 0 },
                        { width: "20%", targets: 1, className: "text-center" },
                        { width: "10%", targets: 2, className: "text-center" },
                        { width: "10%", targets: 3, className: "text-center" },
                        { width: "15%", targets: 4, className: "text-center" }
                    ],
                    ajax: (data: any, callback: any) => {

                        if (loadTableRef.current) {
                            loadTableRef.current(data, callback);
                        }
                    }
                }}
                columns={[
                    {
                        data: "productName",
                        title: "Produto",
                    },
                    {
                        data: "sku",
                        title: "SKU",
                        render: (data: string) =>
                            `<div style="text-align: center;">${data}</div>`
                        ,
                    },
                    {
                        data: "currentQuantity",
                        title: "Ativo",
                        render: (data: number) =>
                            `<div style="text-align: center;">${data}</div>`,
                    },
                    {
                        data: "usedQuantity",
                        title: "Disponível",
                        render: (data: number, type: string, row: any) => {
                            const availableQuantity = row.currentQuantity - row.usedQuantity; 
                            return `<div style="text-align: center;">${availableQuantity < 0 ? 0 : availableQuantity}</div>`;
                        },
                    },                    
                    {
                        data: "autoRenewal",
                        title: "Renovação Automática",
                        orderable: false,
                        render: (autoRenewal: boolean) => {
                            return `
                            <div style="display: flex; justify-content: center;">
                                ${renderToString(
                                    <SwitchBtn
                                        inline
                                        checked={autoRenewal}
                                        disabled
                                        className="m-0"
                                    />
                                )}
                            </div>`;
                        }
                    }                    
                ]}
            >
            </DataTable>
        </Card>
    );
};