import React, {ReactHTML, useCallback, useState} from "react";
import { Input } from "../../../components/atoms/Input";
import { Select } from "../../../components/atoms/Select";
import { Reseller } from "../../../models/Reseller";
import { Profile } from "../../../models/Profiles";
import { toOptionsProps } from "../../../models/OptionsProps";
import { Icon } from "../../../components/atoms/Icon";
import Autocomplete from "../../../components/atoms/Autocomplete";
import {autocompleteResellers} from "../../../services/api/ResellerService";

export interface Filters {
  user: string;
  reseller?: string;
  profile?: string;
}

interface UserFiltersProps {
  filters: Filters;
  setFilters: (value: Filters) => void;
  resellers: Reseller[];
  profiles: Profile[];
  isProfilesLoading: boolean;
  onFilter: () => void;
  filterReset: () => void;
}

export const UserFilters: React.FC<UserFiltersProps> = ({
  filters,
  setFilters,
  resellers,
  profiles,
  isProfilesLoading,
  onFilter,
  filterReset,
}) => {
  const { user, reseller, profile } = filters;
  const isReseller = JSON.parse(localStorage.getItem('authUser')!).profile.isReseller;

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onFilter();
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;
    setFilters({ ...filters, [id]: value });
  };

  const loadResellers = useCallback(async (search: string): Promise<Reseller[]> => {
        return await autocompleteResellers(search);
      },
      []);

  return (
    <form onSubmit={handleSubmit}>
      <div className="d-flex justify-content-between">
        <h1 className="h3">Usuários</h1>
        <div>
          <div className="d-flex gap-2">
            <a href="/user/add" className="btn btn-primary">
              <Icon className="fa fa-fw fa-plus" /> Novo usuário
            </a>
          </div>
        </div>
      </div>

      <div className="row mb-2">
        <div className="col col-6">
          <Input
            label="Usuário"
            placeholder="Nome ou e-mail"
            id="user"
            value={user}
            onChange={handleInputChange}
          />
        </div>

        {!isReseller &&
            <div className="col col-6">
              <label>Revenda</label>
              <Autocomplete<Reseller>
                  id='resellerId'
                  placeholder='Revenda'
                  dataFetcher={loadResellers}
                  keyField="tradeName"
                  onSelect={(data: Reseller) => {
                    const id = data?.id.toString() ?? '';
                    filters.reseller = id;
                  }}
                  onEmptyResult={() => {
                    filters.reseller = '';
                  }}
                  onClear={() => {
                    filters.reseller = '';
                  }}
              />
            </div>
        }


        <div className="col col-3">
          <Select
            label="Perfil"
            options={toOptionsProps(profiles, true)}
            disabled={isProfilesLoading}
            id="profile"
            value={profile}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="d-flex justify-content-end gap-3">
        <button className="btn btn-secondary" id="clearFilter" type="reset" onClick={filterReset}>
          Limpar Filtro
        </button>
        <button className="btn btn-primary" type="submit">
          Filtrar <Icon className="fa fa-fw fa-filter" />
        </button>
      </div>
    </form>
  );
};
