import apiClient from "./ApiClient";

interface BillingFilterParams {
  startDate?: string;
  endDate?: string;
  page?: number;
  size?: number;
}

// Função para obter dados de faturamento com base nos filtros
export const fetchBillingData = async (filters: BillingFilterParams) => {
  try {
    const response = await apiClient.get("/Billing", {
      params: {
        startDate: filters.startDate,
        endDate: filters.endDate,
        page: filters.page ?? 0,
        size: filters.size ?? 10,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar dados de faturamento:", error);
    throw error;
  }
};

// Função para gerar um novo arquivo de faturamento
export const generateBillingFile = async (data: { startDate: string; endDate: string; status: string }) => {
  try {
    const response = await apiClient.put("/Billing", data);
    return response.data;
  } catch (error) {
    console.error("Erro ao gerar o arquivo de faturamento:", error);
    throw error;
  }
};
