import { OptionsProps } from "../OptionsProps";

export enum BillingFor {
  Reseller,
  Customer,
}

export const billingForMap: { [key in BillingFor]: OptionsProps } = {
  [BillingFor.Reseller]: { id: "Reseller", text: "Revenda" },
  [BillingFor.Customer]: { id: "Customer", text: "Cliente" },
};
