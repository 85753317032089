import React from "react";
import { useAuth } from "../../context/AuthContext";
import Login from "../../pages/Login";
import ChangePasswordFirstAccess from "../../pages/ChangePasswordFirstAccess";
import { getAuthUser } from "../../utils/authUtils";
import { ErrorPage } from "../../pages/ErrorPage";

interface PrivateRouteProps {
  code: string;
  element: React.ReactElement;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  code,
  element,
}) => {
  const { isAuthenticated, isFirstLogin } = useAuth();

  if (!isAuthenticated) return <Login />;

  if (isFirstLogin) return <ChangePasswordFirstAccess />;

  const authUser = getAuthUser();
  if (
    !authUser ||
    !authUser.profile ||
    !Array.isArray(authUser.profile.modules)
  )
    return <Login />;

  const { modules } = authUser!.profile;

  if (code === "*" || modules.some((mod) => mod.code === code)) return element;

  return <ErrorPage statusCode="403" />;
};
