import React, { Dispatch, SetStateAction } from "react";
import { Input } from "../../components/atoms/Input";
import { Icon } from "../../components/atoms/Icon";

interface BillingFilterProps {
  fileStartDate: string;
  fileEndDate: string;
  setFileStartDate: Dispatch<SetStateAction<string>>;
  setFileEndDate: Dispatch<SetStateAction<string>>;
  onFilter: () => void;
}

export const BillingFilter: React.FC<BillingFilterProps> = ({
  fileStartDate,
  fileEndDate,
  setFileStartDate,
  setFileEndDate,
  onFilter,
}) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onFilter();
  };

  return (
    <form className="row mt-3" onSubmit={handleSubmit}>
      <div className="col col-md-3 col-6">
        <Input
          label="Data Inicial"
          type="date"
          id="fileStartDate"
          value={fileStartDate}
          onChange={(e) => setFileStartDate(e.target.value)}
        />
      </div>
      <div className="col col-md-3 col-6">
        <Input
          label="Data Final"
          type="date"
          id="fileEndDate"
          value={fileEndDate}
          onChange={(e) => setFileEndDate(e.target.value)}
        />
      </div>

      <div className="col col-md-6 col-12">
        <p className="mb-2">&nbsp;</p>
        <div className="d-flex justify-content-end gap-3">
          <button
            className="btn btn-secondary"
            id="clearFilter"
            type="submit"
            onClick={() => {
              setFileStartDate("");
              setFileEndDate("");
            }}
          >
            Limpar Filtro
          </button>
          <button className="btn btn-primary" type="submit">
            Filtrar <Icon className="fa fa-fw fa-filter" />
          </button>
        </div>
      </div>
    </form>
  );
};
