import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { SwitchBtn } from "../../components/atoms/SwitchBtn";
import { UserForm } from "./models/UserForm";
import { editUser, getUserById } from "../../services/api/UserService";
import { MainTemplate } from "../../components/templates/MainTemplate";
import { Card } from "../../components/mols/Card";
import { Icon } from "../../components/atoms/Icon";
import { Input } from "../../components/atoms/Input";
import { Select } from "../../components/atoms/Select";
import { useGetProfiles } from "../../hooks/profileHook";
import { toOptionsProps } from "../../models/OptionsProps";
import { useForm } from "../../hooks/formHook";
import { Loading } from "../../components/orgs/Loading";
import { Profile } from "../../models/Profiles"; // Importando o tipo Profile

import { autocompleteResellers } from "../../services/api/ResellerService";
import Autocomplete from "../../components/atoms/Autocomplete";
import { useToast } from "../../components/atoms/Toast";
import { Reseller } from "../../models/Reseller";
import { ProfileCode } from "../../models/enums/ProfileCode";

export const EditUser: React.FC = () => {
  const { id: idStr } = useParams(); // Obter o id da URL
  const id = BigInt(idStr ?? "0");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSave, setIsLoadingSave] = useState(false);

  const profile = JSON.parse(localStorage.getItem("authUser")!).profile;
  const isSuperAdmin = profile.profileCode === ProfileCode.SuperAdminPars;

  const toast = useToast();
  const { data: fetchedProfiles, loading: isProfileLoading } = useGetProfiles();
  const [preValue, setpreValue] = useState("");
  // Tipando o estado profiles como um array de Profile
  const [profiles, setProfiles] = useState<Profile[]>([]); // Agora o tipo é Profile[]
  const loadResellers = useCallback(
    async (search: string): Promise<Reseller[]> => {
      const data = await autocompleteResellers(search);
      return data;
    },
    []
  );

  const [formData, setFormData] = useState<UserForm>({
    id: 0,
    resellerId: "0",
    profileId: "0",
    name: "",
    email: "",
    activated: true,
  });

  const { handleInputChange, handleError } = useForm<UserForm>({ setFormData });

  useEffect(() => {
    const fetchUser = async () => {
      setIsLoading(true);
      try {
        const user = await getUserById(id); // Carregar os dados do usuário
        setFormData({
          id: user.id,
          name: user.name,
          email: user.email,
          activated: user.activated,
          profileId: String(user.profileId),
          resellerId: user.resellerId ? String(user.resellerId) : "",
        });
        setpreValue(user.resellerName);
      } catch (error) {
        handleError(error, toast);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUser();
  }, [id]);

  useEffect(() => {
    if (fetchedProfiles) {
      setProfiles(fetchedProfiles); // Atualiza os perfis assim que os dados forem carregados
    }
  }, [fetchedProfiles]);

  const handleSwitchChange = (checked: boolean) => {
    setFormData({
      ...formData,
      activated: checked, // Atualiza o estado 'activated' com o valor do switch
    });
  };

  const submitForm = async (evt: React.FormEvent) => {
    evt.preventDefault();

    try {
      setIsLoadingSave(true);
      const userDto = {
        ...formData,
        id: Number(formData.id),
        profileId: Number(formData.profileId),
        resellerId: Number(formData.resellerId),
      };
      await editUser(userDto); // Enviar os dados para atualizar o usuário
      toast.fire({
        icon: "success",
        title: "Usuário atualizado com sucesso!",
      });
      setTimeout(() => {
        window.location.href = "/users";
      }, 3000);
    } catch (error) {
      toast.fire({
        icon: "error",
        title: "Erro ao atualizar o usuário!",
      });
    } finally {
      setIsLoadingSave(false);
    }
  };

  if (isLoading) return <Loading />;

  return (
    <MainTemplate>
      <Card>
        <form onSubmit={submitForm}>
          <h3>Editar Usuário</h3>

          <div className="row">
            <div className="col col-sm-6 col-12">
              <Select
                label="Perfil"
                options={toOptionsProps(profiles)} // Carrega os perfis na lista
                id="profileId"
                onChange={handleInputChange}
                disabled={isProfileLoading}
                value={formData.profileId}
              />
            </div>

            <div className="col col-sm-6 col-12">
              <Input
                label="Usuário *"
                id="name"
                onChange={handleInputChange}
                value={formData.name}
                required
              />
            </div>
          </div>

          <div className="row">
            <div className="col col-sm-6 col-12">
              <Input
                label="Email"
                id="email"
                onChange={handleInputChange}
                value={formData.email}
                disabled
              />
            </div>
            {isSuperAdmin ? (
              <div className="col col-sm-6 col-12">
                <Autocomplete<Reseller>
                  id="autotomplete-resellers"
                  label="Revenda"
                  placeholder="Revenda"
                  dataFetcher={loadResellers}
                  keyField="tradeName"
                  preValue={preValue}
                  onSelect={(data: Reseller) => {
                    const id = data?.id.toString() ?? "";
                    setFormData((st) => ({ ...st, resellerId: id }));
                  }}
                  onEmptyResult={() => {
                    setFormData((st) => ({ ...st, resellerId: "" }));
                  }}
                  onClear={() => {
                    setFormData((st) => ({ ...st, resellerId: "" }));
                  }}
                />
              </div>
            ) : null}
          </div>

          <div className="col col-3">
            <SwitchBtn
              id="activated"
              label="Ativo"
              checked={formData.activated}
              onChange={handleSwitchChange}
            />
          </div>

          <button
            className="btn btn-primary btn-lg rounded float-rb"
            style={{ width: "3em", height: "3em" }}
            type="submit"
            disabled={isProfileLoading || isLoading || isLoadingSave}
          >
            {isLoadingSave ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              <Icon className="fa fa-fw fa-save" />
            )}
          </button>
        </form>
      </Card>
    </MainTemplate>
  );
};
