import { useEffect, useState } from "react";
import { Reseller } from "../models/Reseller";
import { getReseller, getResellerById } from "../services/api/ResellerService";
import { useFetchById } from "./useFetch";

let cachedResellers: Reseller[] | null = null;

export const useGetResellers = () => {
  const [data, setData] = useState<Reseller[] | null>(cachedResellers);
  const [loading, setLoading] = useState<boolean>(!cachedResellers);

  useEffect(() => {
    if (!cachedResellers) {
      const fetchData = async () => {
        const result = await getReseller(0, 1000);
        cachedResellers = result;

        setData(result);
        setLoading(false);
      };
      fetchData();
    }
  }, []);

  return { data, loading };
};

export const useGetReseller = (id: BigInt) => {
  const { data, error, loading } = useFetchById<Reseller>(
    id,
    getResellerById,
    "Erro ao carregar a revenda"
  );
  const reseller = data as Reseller;
  return { reseller, error, loading };
};
