import React from "react";
import { Icon } from "./Icon";

interface InputNumberProps {
  id: string;
  name: string;
  value?: number;
  minValue?: number;
  maxValue?: number;
  onChange: (value: number) => void;
}

export const InputNumber: React.FC<InputNumberProps> = ({
  id,
  name,
  value,
  minValue,
  maxValue,
  onChange,
}) => {
  const validateValue = (val: string) => {
    const num = parseInt(val, 10);
    if (isNaN(num)) return "";
    if (minValue !== undefined && num < minValue) return minValue;
    if (maxValue !== undefined && num > maxValue) return maxValue;
    return num;
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const allowedKeys = [
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
      "Delete",
    ];
    if (!allowedKeys.includes(event.key) && !/^[0-9]$/.test(event.key)) {
      event.preventDefault();
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const newValue = validateValue(event.target.value);
    event.target.value = newValue.toString();
    onChange(Number(newValue));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = validateValue(event.target.value);
    onChange(Number(newValue));
  };

  const handleClick = (increment: number) => {
    const input = document.getElementById(id) as HTMLInputElement;
    const newValue = validateValue(
      (parseInt(input.value, 10) + increment).toString()
    );
    input.value = newValue.toString();

    onChange(Number(newValue));
  };

  return (
    <div className="input-group" style={{ maxWidth: "10em" }}>
      <button
        className="btn btn-sm btn-primary"
        onClick={() => handleClick(-1)}
      >
        <Icon className="fa fa-fw fa-minus" />
      </button>
      <input
        id={id}
        name={name}
        type="text"
        className="form-control text-center"
        value={value}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        onChange={handleChange}
        autoComplete="off"
      />
      <button className="btn btn-sm btn-primary" onClick={() => handleClick(1)}>
        <Icon className="fa fa-fw fa-plus" />
      </button>
    </div>
  );
};
