import React, { useState } from "react";
import { Sidebar } from "../orgs/Sidebar";
import { Navbar } from "../orgs/Navbar";
import { TemplateProps } from "./interfaces/TemplateProps";
import { MainArea } from "../orgs/MainArea";
import { Order } from "../../models/Order";
import { useLocation } from 'react-router-dom';

interface MainTemplateProps extends TemplateProps {
  currentOrder?: Order;
}

export const MainTemplate: React.FC<MainTemplateProps> = ({
  currentOrder,
  children,
}) => {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(() =>
    localStorage.getItem("sidebarOpen") === "false" ? false : true
  );

  const location = useLocation();
  const isRootRoute = location.pathname === '/';

  const toggleSidebar = () => {
    setSidebarOpen((prevState) => {
      const newState = !prevState;
      localStorage.setItem("sidebarOpen", newState.toString());
      return newState;
    });
  };

  return (
    <div className="d-flex overflow-hidden">
      <Sidebar isSidebarOpen={sidebarOpen} />

      <main className={`${isRootRoute ? 'main-background' : ''} flex-grow-1`}>
        <Navbar toggleSidebar={toggleSidebar} currentOrder={currentOrder}/>

        <MainArea isSidebarOpen={sidebarOpen}>{children}</MainArea>
      </main>
    </div>
  );
};
