import React, { useCallback, useRef, useState } from "react";
import { Customer } from "../../../models/Customer";
import { Reseller } from "../../../models/Reseller";
import { autocompleteCustomers } from "../../../services/api/CustomerService";
import { autocompleteResellers } from "../../../services/api/ResellerService";
import Autocomplete from "../../../components/atoms/Autocomplete";
import { Card } from "../../../components/mols/Card";
import { Icon } from "../../../components/atoms/Icon";
import { Input } from "../../../components/atoms/Input";
import { Select } from "../../../components/atoms/Select";
import { getAuthUser } from "../../../utils/authUtils";

export interface FormFilterListRenew {
  resellerId: string;
  customerId: string;
  customerIdAdobe: string;
  days: string;
}

export interface FilterListRenewProps {
  onSearch(form: FormFilterListRenew): void;
}


const FilterListRenew: React.FC<FilterListRenewProps> = ({ onSearch }) => {
  const formRef = useRef<HTMLFormElement | null>(null);
  const isReseller = getAuthUser()?.profile.isReseller ?? false;

  const [formFilters, setFormFilters] = useState<FormFilterListRenew>({
    resellerId: "",
    customerId: "",
    customerIdAdobe: "",
    days: "90",
  });

  const loadResellers = useCallback(
    async (search: string): Promise<Reseller[]> => {
      const data = await autocompleteResellers(search);
      return data;
    },
    []
  );

  const loadCustomers = useCallback(
    async (search: string): Promise<Customer[]> => {
      const data = await autocompleteCustomers(search);
      return data;
    },
    []
  );

  const handleSearch = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      onSearch(formFilters);
    },
    [formFilters, onSearch]
  );

  return (
    <Card className="mb-3">
      <h1 className="h3">Renovação</h1>
      <form ref={formRef} noValidate onSubmit={handleSearch}>
        <div className="row">
          {!isReseller && (
            <div className="col col-3">
              <Autocomplete<Reseller>
                id="autotomplete-resellers"
                label="Revenda"
                placeholder="Revenda"
                dataFetcher={loadResellers}
                keyField="tradeName"
                onSelect={(data: Reseller) => {
                  const id = data?.id.toString() ?? "";
                  setFormFilters((st) => ({ ...st, resellerId: id }));
                }}
                onEmptyResult={() => {
                  setFormFilters((st) => ({ ...st, resellerId: "0" }));
                }}
                onClear={() => {
                  setFormFilters((st) => ({ ...st, resellerId: "" }));
                }}
              />
            </div>
          )}
          <div className="col col-3">
            <Autocomplete<Customer>
              id="autotomplete-customers"
              label="Cliente"
              placeholder="Cliente"
              dataFetcher={loadCustomers}
              keyField="name"
              onSelect={(data: Customer) => {
                const id = data?.id?.toString() ?? "";
                setFormFilters((st) => ({ ...st, customerId: id }));
              }}
              onEmptyResult={() => {
                setFormFilters((st) => ({ ...st, customerId: "0" }));
              }}
              onClear={() => {
                setFormFilters((st) => ({ ...st, customerId: "" }));
              }}
            />
          </div>
          <div className="col col-3">
            <Input
              label="Customer ID"
              placeholder="Customer ID"
              type="text"
              value={formFilters.customerIdAdobe}
              onChange={(e) =>
                setFormFilters((st) => ({
                  ...st,
                  customerIdAdobe: e.target.value,
                }))
              }
            />
          </div>
          <div className="col col-3">
            <Select
              label="Prazo de renovação"
              options={[
                { id: "30", text: "30 dias" },
                { id: "60", text: "60 dias" },
                { id: "90", text: "90 dias" },
              ]}
              value={formFilters.days}
              onChange={(e) =>
                setFormFilters((st) => ({ ...st, days: e.target.value }))
              }
            />
          </div>
        </div>

        <div className="d-flex justify-content-end gap-3">
          <button
            className="btn btn-secondary"
            id="clearFilter"
            type="reset"
            onClick={() => {
              setFormFilters({
                resellerId: "",
                customerId: "",
                customerIdAdobe: "",
                days: "90",
              });
            }}
          >
            Limpar Filtro
          </button>

          <button className="btn btn-primary" type="submit">
            Filtrar <Icon className="fa fa-fw fa-filter" />
          </button>
        </div>
      </form>
    </Card>
  );
};

export default FilterListRenew;
