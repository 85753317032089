import { AxiosError } from "axios";
import {
  CancelOrderRmaDto,
  FilterRmaList,
  RmaOrdersResponse,
} from "../../models/Rma";

import apiClient, { ErrorApi } from "./ApiClient";

export const getRmaOrders = async (
  filters: FilterRmaList
): Promise<RmaOrdersResponse> => {
  try {
    let response = await apiClient.get<RmaOrdersResponse>("rmas", {
      params: filters,
    });

    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      const err = error.response?.data as ErrorApi | undefined;
      console.error("Não foi possível carregar a lista de RMAs:", err?.message);
    }
    throw new Error("Não foi possível carregar a lista de RMAs.");
  }
};

export const cancelOrderItemsRma = async (
  request: CancelOrderRmaDto
): Promise<void> => {
  try {
    const response = await apiClient.put(`rmas`, request);

    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      const err = error.response?.data as ErrorApi | undefined;
      console.error(
        "Não foi possível cancelar os items do pedido RMA:",
        err?.message
      );
    }
    throw new Error("Não foi possível cancelar os items do pedido RMA.");
  }
};
