import React, { Dispatch, SetStateAction } from "react";
import { BillingFile } from "../models/BillingFile";
import { Pagination } from "./Pagination";
import { DataRow } from "./DataRow";

interface BillingTableProps {
  billingData: BillingFile[];
  currentPage: number;
  totalPages: number;
  onDownload: (fileUrl: string) => void;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

export const BillingTable: React.FC<BillingTableProps> = ({
  billingData,
  currentPage,
  totalPages,
  onDownload,
  setCurrentPage,
}) => {
  return (
    <>
      <table className="table table-sm table-striped">
        <thead>
          <tr>
            <th>Nome do arquivo</th>
            <th>Data Inicial</th>
            <th>Data Final</th>
            <th>Tamanho</th>
            <th>Ações</th>
          </tr>
        </thead>

        <tbody>
          {billingData.map((file, index) => (
            <DataRow file={file} index={index} onDownload={onDownload} />
          ))}
        </tbody>
      </table>

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
};
