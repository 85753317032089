import React from "react";
import { TemplateProps } from "./interfaces/TemplateProps";

interface SidebarTemplateProps extends TemplateProps {
  isSidebarOpen: boolean;
}

export const SidebarTemplate: React.FC<SidebarTemplateProps> = ({
  children,
  isSidebarOpen,
}) => {
  return (
    <div
      className={`sidebar secondary-bg ${
        isSidebarOpen ? "sidebar-open" : "sidebar-closed"
      }`}
      aria-hidden={!isSidebarOpen}
    >
      {children}
    </div>
  );
};
