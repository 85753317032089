import { Customer } from "./Customer";
import { BillingFor } from "./enums/BillingFor";
import { orderStatus } from "./enums/orderStatus";
import { OrderFinishItem, OrderItem } from "./OrderItem";
import { Reseller } from "./Reseller";
import { RmaOrders } from "./Rma";
import { User } from "./User";

export interface Order {
  id: number;
  customerId: number;
  resellerId: number;
  isRenewal: boolean;
  hubspotLink: string | null;
  pedCustomer: string | null;
  pedReseller: string | null;
  billingFor: BillingFor | null;
  paymentCondition: string | null;
  usedDolar: number;
  frozenDolar: number;
  resellerTotalPriceUSD: number;
  resellerTotalPriceBRL: number;
  invoiceTotalPriceUSD: number;
  invoiceTotalPriceBRL: number;
  dateCreated: Date;
  status: orderStatus | null;
  adobeOrderId: string | null;
  customer: Customer | null;
  reseller: Reseller | null;
  items: OrderItem[];
  isHubspotQuote: boolean;
  adobeProcessDate?: Date;
  userId: number;
  user?: User;
  rmas: RmaOrders[];
  observation?: string;
}

export interface OrderCreation {
  customerId: BigInt | number;
  isRenewal: boolean;
  hubspotLink?: string;
}

export interface OrderFinish {
  id: number;
  customerId: BigInt | number;
  isRenewal: boolean;
  pedCustomer: string;
  pedReseller: string;
  billingFor: string;
  paymentCondition: string;
  userId: BigInt | number;
  items: OrderFinishItem[];
}

export const newOrder = (): Order => {
  return {
    id: 0,
    customerId: 0,
    resellerId: 0,
    isRenewal: false,
    hubspotLink: null,
    pedCustomer: null,
    pedReseller: null,
    billingFor: null,
    paymentCondition: null,
    usedDolar: 0,
    frozenDolar: 0,
    resellerTotalPriceUSD: 0,
    resellerTotalPriceBRL: 0,
    invoiceTotalPriceUSD: 0,
    invoiceTotalPriceBRL: 0,
    dateCreated: new Date(Date.now()),
    status: null,
    adobeOrderId: null,
    customer: null,
    reseller: null,
    items: [],
    isHubspotQuote: false,
    userId: 0,
    rmas: [],
  };
};
