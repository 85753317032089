import React from "react";
import Logo from "../../assets/images/Logo.png";
import { SidebarItem } from "../atoms/SidebarItem";
import { useMenu } from "../../hooks/useMenu";
import { SidebarTemplate } from "../templates/SidebarTemplate";

interface SidebarProps {
  isSidebarOpen: boolean;
}

export const Sidebar: React.FC<SidebarProps> = React.memo(
  ({ isSidebarOpen }) => {
    const { data: menuItems, loading, error } = useMenu();
    const logo = (
      <div className="sidebar-logo">
        <img src={Logo} alt="PARS" />
        {isSidebarOpen && <span>Connect Hub</span>}
      </div>
    );
    if (loading) {
      return (
        <SidebarTemplate isSidebarOpen={isSidebarOpen}>
          {logo}
          <div className="skeleton"></div>
          <div className="skeleton"></div>
          <div className="skeleton"></div>
          <div className="hr" />
        </SidebarTemplate>
      );
    }
    if (error) {
      return (
        <SidebarTemplate isSidebarOpen={isSidebarOpen}>{error}</SidebarTemplate>
      );
    }
    if (!menuItems) {
      return (
        <SidebarTemplate isSidebarOpen={isSidebarOpen}>
          Erro ao buscar o menu
        </SidebarTemplate>
      );
    }

    const mappedItems = menuItems.map((btn, index) => (
      <SidebarItem
        key={index}
        icon={btn.icon}
        label={btn.label}
        isSidebarOpen={isSidebarOpen}
        href={btn.href}
      />
    ));

    return (
      <SidebarTemplate isSidebarOpen={isSidebarOpen}>
        {logo}
        <div className="hr" />
        {mappedItems}
      </SidebarTemplate>
    );
  }
);
