export interface CustomerFilters {
  startCotermDate?: string;
  endCotermDate?: string;
  status?: string;
  prorataValue?: string;
  customer?: string;
  resellerId?: string;
}

export const newCustomerFilters = (): CustomerFilters => {
  return {
    startCotermDate: "",
    endCotermDate: "",
    status: "",
    prorataValue: "",
    customer: "",
    resellerId: "",
  };
};
