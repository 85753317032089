import React from "react";
import { OptionsProps } from "../../models/OptionsProps";
import { Icon } from "./Icon";

interface CheckboxProps {
  id: string;
  label?: string;
  options: OptionsProps[];
  value: { [key: string]: boolean };
  onChange: (updatedValue: { [key: string]: boolean }) => void;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  id,
  label,
  options,
  value,
  onChange,
}) => {
  const handleCheckboxChange = (optionId: string) => {
    const updatedCheckedItems = {
      ...value,
      [optionId]: !value[optionId],
    };
    onChange(updatedCheckedItems);
  };

  const mappedOptions = options.map((opt) => {
    const optionId = opt.id ?? "0";
    const isChecked = value[optionId] || false;
    const className = isChecked
      ? "text-primary fa-square-check"
      : "text-secondary fa-square";

    return (
      <div key={opt.id} className="d-flex">
        <input
          type="checkbox"
          id={`${id}-${opt.id}`}
          className="d-none"
          checked={isChecked}
          onChange={() => handleCheckboxChange(optionId)}
        />
        <label htmlFor={`${id}-${opt.id}`} className="mr-2">
          <Icon
            className={`fa fa-fw ${className}`}
            style={{ fontSize: "1.5em" }}
          />
        </label>
        <label htmlFor={`${id}-${opt.id}`} className="pl-2">
          {opt.text}
        </label>
      </div>
    );
  });

  return (
    <div>
      {label && <label className="d-block">{label}</label>}
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ height: "calc(1em + 40px)" }}
      >
        {mappedOptions}
      </div>
    </div>
  );
};
