import {useCallback, useRef, useState} from "react";
import {orderStatus} from "../../../models/enums/orderStatus";
import {orderType} from "../../../models/enums/orderType";
import {Card} from "../../../components/mols/Card";
import {Input} from "../../../components/atoms/Input";
import {Select} from "../../../components/atoms/Select";
import {Icon} from "../../../components/atoms/Icon";
import {Reseller} from "../../../models/Reseller";
import {Customer} from "../../../models/Customer";
import {autocompleteResellers} from "../../../services/api/ResellerService";
import {autocompleteCustomers} from "../../../services/api/CustomerService";
import {useForm} from "../../../hooks/formHook";
import {InputCurrency} from "../../../components/atoms/InputCurrency";
import Autocomplete from "../../../components/atoms/Autocomplete";

export interface OrderHistoryFilterForm {
    startDate?: Date | string | null;
    endDate?: Date | string | null;
    resellerId?: number | null;
    customerId?: number | null;
    customerIdAdobe?: string | null;
    type?: orderType | null;
    status?: orderStatus | null;
    value?: number | null;
    pedCustomer?: string | null;
    pedReseller?: string | null;
    pedAdobe?: string | null;
}

export interface OrderHistoryFilterFormProps {
    onSearch(form: OrderHistoryFilterForm): void;
}

export const OrdersHistoryFilterList: React.FC<OrderHistoryFilterFormProps> = ({onSearch}) => {
    const formRef = useRef<HTMLFormElement | null>(null);
    const [orderHistoryForm, setOrderHistoryForm] = useState<OrderHistoryFilterForm>({
        startDate: null,
        endDate: null,
        resellerId: null,
        customerId: null,
        customerIdAdobe: "",
        type: orderType.All,
        status: orderStatus.All,
        value: 0,
        pedCustomer: "",
        pedReseller: "",
        pedAdobe: ""
    });

    const profile = JSON.parse(localStorage.getItem("authUser")!).profile;
    const isReseller = profile.isReseller;

    const {handleInputChange} = useForm<OrderHistoryFilterForm>({setFormData: setOrderHistoryForm});

    const loadResellers = useCallback(async (search: string): Promise<Reseller[]> => {
        const data = await autocompleteResellers(search);
        return data
    }, []);

    const loadCustomers = useCallback(async (search: string): Promise<Customer[]> => {
        const data = await autocompleteCustomers(search);
        return data
    }, []);

    const validateForm = ({orderHistoryFilterForm}: { orderHistoryFilterForm: OrderHistoryFilterForm }): void => {
        const {
            customerIdAdobe,
            pedCustomer,
            pedReseller,
            pedAdobe
        } = orderHistoryFilterForm;

        setOrderHistoryForm((prev) => {
            return ({
                ...prev,
                customerIdAdobe: customerIdAdobe?.trim() === "" ? null : customerIdAdobe,
                pedCustomer: pedCustomer?.trim() === "" ? null : pedCustomer,
                pedReseller: pedReseller?.trim() === "" ? null : pedReseller,
                pedAdobe: pedAdobe?.trim() === "" ? null : pedAdobe
            });
        });
    }

    const handleFilter = (e: React.FormEvent) => {
        e.preventDefault();
        validateForm({orderHistoryFilterForm: orderHistoryForm});

        onSearch(orderHistoryForm);
    };

    return (
        <Card className="mb-3">
            <form
                ref={formRef}
                onSubmit={handleFilter}
            >
                <h1 className="h3">Histórico de Pedidos</h1>

                <div className="row mb-2">
                    <div className="col-2">
                        <Input
                            label="Data Inicial"
                            type="date"
                            value={orderHistoryForm.startDate}
                            onChange={(e) => setOrderHistoryForm(form => ({...form, startDate: e.target.value}))}
                        />
                    </div>
                    <div className="col-2">
                        <Input
                            label="Data Final"
                            type="date"
                            value={orderHistoryForm.endDate}
                            onChange={(e) => setOrderHistoryForm(form => ({...form, endDate: e.target.value}))}
                        />
                    </div>
                    <div className={isReseller ? "col-6" : "col-3"}>
                        <Autocomplete<Customer>
                            id='autotomplete-customers'
                            label="Customer"
                            placeholder='Customer'
                            dataFetcher={loadCustomers}
                            keyField="name"
                            onSelect={(data: Customer) => {
                                const id = data?.id ?? null;
                                setOrderHistoryForm(form => ({...form, customerId: id}));
                            }}
                        />
                    </div>
                    {!isReseller && (
                        <div className="col-3">
                            <Autocomplete<Reseller>
                                id='autotomplete-resellers'
                                label="Reseller"
                                placeholder='Reseller'
                                dataFetcher={loadResellers}
                                keyField="tradeName"
                                onSelect={(data: Reseller) => {
                                    const id = data?.id ?? null;
                                    setOrderHistoryForm(form => ({...form, resellerId: id}));
                                }}
                            />
                        </div>
                    )}
                    <div className="col-2">
                        <Input
                            label="Customer ID"
                            type="text"
                            placeholder="Customer ID"
                            value={orderHistoryForm.customerIdAdobe}
                            onChange={(e) => setOrderHistoryForm(form => ({...form, customerIdAdobe: e.target.value}))}
                        />
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col-2">
                        <Select
                            label="Tipo"
                            id="type"
                            options={[
                                {id: orderType.All.toString(), text: "Todos"},
                                {id: orderType.New.toString(), text: "Novo Pedido"},
                                {id: orderType.Renewal.toString(), text: "Renovação"}]}
                            onChange={handleInputChange}
                            value={orderHistoryForm.type?.toString()}
                        />
                    </div>
                    <div className="col-2">
                        <Select
                            label="Status"
                            id="status"
                            options={[
                                {id: orderStatus.All.toString(), text: "Todos"},                             
                                {id: orderStatus.CreditAnalysis.toString(), text: "Em Análise de Crédito"},
                                {id: orderStatus.CreditDenied.toString(), text: "Crédito Negado"},
                                {id: orderStatus.FinancialRefused.toString(), text: "Recusado Financeiro"},
                                {id: orderStatus.WaitingSendingAdobe.toString(), text: "Aguardando Envio Adobe"},
                                {id: orderStatus.Processed.toString(), text: "Processado"},
                                {id: orderStatus.WaitingSendingBilling.toString(), text: "Aguardando Faturamento"},
                                {id: orderStatus.Billed.toString(), text: "Faturado"},
                                {id: orderStatus.ErrorSendingAdobe.toString(), text: "Erro no Envio Adobe"},
                            ]}
                            onChange={handleInputChange}
                            value={orderHistoryForm.status?.toString()}
                        />
                    </div>
                    <div className="col-2">
                        <InputCurrency
                            label="Valor"
                            value={orderHistoryForm.value || 0}
                            onChange={(newValue) =>
                                setOrderHistoryForm((form) => ({
                                    ...form,
                                    value: newValue || null,
                                }))
                            }
                        />
                    </div>
                    <div className="col-3">
                        <Input
                            label="Ped. Cliente"
                            type="text"
                            placeholder="Ped. Cliente"
                            value={orderHistoryForm.pedCustomer}
                            onChange={(e) => setOrderHistoryForm(form => ({...form, pedCustomer: e.target.value}))}
                        />
                    </div>
                    <div className="col-3">
                        <Input
                            label="Ped. Revenda"
                            type="text"
                            placeholder="Ped. Revenda"
                            value={orderHistoryForm.pedReseller}
                            onChange={(e) => setOrderHistoryForm(form => ({...form, pedReseller: e.target.value}))}
                        />
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col-3">
                        <Input
                            label="Ped. Adobe"
                            type="text"
                            placeholder="Ped. Adobe"
                            value={orderHistoryForm.pedAdobe}
                            onChange={(e) => setOrderHistoryForm(form => ({...form, pedAdobe: e.target.value}))}
                        />
                    </div>
                </div>

                <div className="d-flex justify-content-end">
                    <button className="btn btn-secondary" id="clearFilter" type="reset" onClick={() => {
                        setOrderHistoryForm({
                            startDate: null,
                            endDate: null,
                            resellerId: null,
                            customerId: null,
                            customerIdAdobe: "",
                            type: orderType.All,
                            status: orderStatus.All,
                            value: 0,
                            pedCustomer: "",
                            pedReseller: "",
                            pedAdobe: ""
                        });
                    }}>
                        Limpar Filtro
                    </button>

                    <button className="btn btn-primary" style={{marginLeft: "5px"}} type="submit">
                        Filtrar <Icon className="fa fa-fw fa-filter"/>
                    </button>
                </div>
            </form>
        </Card>
    )
};