export const transactionLevels = [
  "T1",
  "T2",
  "T3",
  "T4",
  "T5",
  "T6",
  "T7",
  "TA",
  "TB",
  "TC",
  "TD",
  "TE",
  "TF",
  "TG",
] as const;

export type TransactionLevel = (typeof transactionLevels)[number];

export const toTransactionLevel = (
  level?: string | null
): TransactionLevel | null => {
  return transactionLevels.includes(level as TransactionLevel)
    ? (level as TransactionLevel)
    : null;
};
