import React, { useEffect, useRef } from "react";
import autoComplete from "@tarekraafat/autocomplete.js";

import "./styles.css";

interface AutocompleteProps<T> {
  id: string;
  label?: string;
  placeholder: string;
  dataFetcher(search: string): T[] | Promise<T[]>;
  keyField: keyof T;
  onSelect?(selected: T | null): void;
  onEmptyResult?(): void;
  onClear?(): void;
  preValue?: string;
}

const Autocomplete = <T,>({
  id,
  label,
  placeholder,
  keyField,
  dataFetcher,
  preValue,
  onSelect = () => {},
  onEmptyResult = () => {},
  onClear = () => {},
}: AutocompleteProps<T>) => {
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (inputRef.current && preValue) {
      inputRef.current.value = preValue;
    }
  }, [preValue]);

  useEffect(() => {
    if (inputRef.current) {
      const autoCompleteJS = new autoComplete({
        selector: `#${id}`,
        placeHolder: placeholder,
        data: {
          src: dataFetcher,
          keys: [keyField as string],
        },
        resultsList: {
          element: (list: any, data: any) => {
            if (!data.results.length) {
              onEmptyResult();
            } else {
              autoCompleteJS.goTo(0);
            }
          },
          noResults: true,
        },
        resultItem: {
          highlight: true,
        },
        debounce: 300,
        events: {
          input: {
            selection: (event: any) => {
              const selection = event.detail.selection.value;
              console.log(dataFetcher);
              onSelect(selection);

              autoCompleteJS.input.value = selection[keyField];
            },
            blur: (event: any) => {
              const value = event.target.value;
              if (!value) onClear();
            },
          },
        },
      });

      document.querySelector(`#${id}`)?.removeEventListener("open", () => {});
      document.querySelector(`#${id}`)?.addEventListener("open", () => {
        autoCompleteJS.goTo(0);
      });

      return () => {
        autoCompleteJS.unInit();
      };
    }
  }, [
    dataFetcher,
    id,
    keyField,
    onClear,
    onEmptyResult,
    onSelect,
    placeholder,
  ]);

  return (
    <div className="autoComplete_wrapper">
      {label && <label {...(id && { htmlFor: id })}>{label}</label>}
      <input
        id={id}
        ref={inputRef}
        className="form-control my-2"
        type="search"
        dir="ltr"
        spellCheck={false}
        autoCorrect="off"
        autoComplete="off"
        autoCapitalize="off"
        defaultValue={preValue}
      />
    </div>
  );
};

export default Autocomplete;
