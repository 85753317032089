import React from "react";
import { TemplateProps } from "../templates/interfaces/TemplateProps";

interface MainAreaProps extends TemplateProps {
  isSidebarOpen: boolean;
}

export const MainArea: React.FC<MainAreaProps> = ({
  isSidebarOpen,
  children,
}) => {
  const className = isSidebarOpen ? "sidebar-open" : "sidebar-closed";

  return <section className={`main-area ${className}`}>{children}</section>;
};
