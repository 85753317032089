export const formatDocument = (value: string): string => {
  const cleanedValue = value.replace(/\D/g, "");

  // CPF
  if (cleanedValue.length === 11)
    return cleanedValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");

  // CNPJ
  if (cleanedValue.length === 14)
    return cleanedValue.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );

  return value;
};
