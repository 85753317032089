import { OrderItem } from "./OrderItem";

export interface RefreshSku {
  orderId: number;
  products: RefreshSkuItem[];
}

export interface RefreshSkuItem {
  productId: number;
  sku: string;
  quantity: number;
}

export interface RefreshedSkuItem {
  productId: number;
  license: string;
  sku: string;
  detail: string;
  quantity: number;
  resellerPriceUSD: number;
  resellerPriceBRL: number;
}

export interface RefreshedSku {
  licenseLevel: string;
  transactionLevel: string;
  orderId: number;
  products: RefreshedSkuItem[];
}

export const toRefreshSkuItemList = (items: OrderItem[]): RefreshSkuItem[] => {
  const skus: RefreshSkuItem[] = items.map((item) => {
    return {
      productId: item.productId,
      sku: item.product?.sku ?? "",
      quantity: item.quantity,
    };
  });

  return skus;
};
