import { AxiosError } from "axios";
import { Toast } from "../components/atoms/Toast";
import { useAuth } from "../context/AuthContext";

export const useErrorHandler = () => {
  const { logout } = useAuth();

  const handleError = (ex: unknown, toast: Toast, action?: string) => {
    const actionText = action ? `ao ${action}` : "";
    let title = "";
    let text = "";
    let icon: "warning" | "error" = "error";

    if (ex instanceof AxiosError) {
      const code = ex.code;
      const status = ex.response?.status;
      const message =
        ex.response?.data?.message ?? "Erro desconhecido no servidor.";

      if (code === "ERR_NETWORK") {
        title = "Sua sessão expirou";
        icon = "warning";
        logout();
      } else if (status === 406) {
        title = "Atenção";
        text = message;
        icon = "warning";
      } else {
        title = `Erro [${status ?? "desconhecido"}] ${actionText}.`;
        text = message;
      }
    } else if (ex instanceof Error) {
      title = `Erro ${actionText}.`;
      text = ex.message;
    } else {
      title = `Erro desconhecido ${actionText}.`;
      text = "Um erro inesperado ocorreu.";
    }

    toast.fire({
      icon,
      title,
      text,
    });
  };

  return { handleError };
};
