import { AxiosError } from "axios";
import apiClient, { ErrorApi } from "./api/ApiClient";

export const resetPassword = async (email: string): Promise<void> => {
  try {
    await apiClient.patch("/Auth/reset-password", { email });
  } catch (error) {
    if (error instanceof AxiosError) {
      const err = error.response?.data as ErrorApi | undefined;
      console.error("Erro ao solicitar reset de senha:", err?.message);
    }

    throw new Error("Erro ao solicitar reset de senha.");
  }
};

export interface ChangePasswordDTO {
  oldPassword: string;
  newPassword: string;
}
export const changePasssword = async (
  request: ChangePasswordDTO
): Promise<string> => {
  let message = "";

  try {
    await apiClient.patch("/Auth/change-password", request);
  } catch (error) {
    if (error instanceof AxiosError) {
      const err = error.response?.data as ErrorApi | undefined;
      console.error("Erro ao solicitar reset de senha:", err?.message);
      if (error.status === 406) throw new Error(err?.message);
    }

    throw new Error("Erro ao solicitar troca de senha.");
  }

  return message;
};
