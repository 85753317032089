import { OptionsProps } from "../OptionsProps";

export enum PaymentCondition {
  Cash,
  DebitNote,
  AnotherWay,
  Installment120Days,
  Installment30DaysCreditCard,
  Installment7Days,
  Installment9Days,
  Installment10Days,
  Installment14Days,
  Installment15Days,
  Installment18Days,
  Installment20Days,
  Installment21Days,
  Installment28Days,
  Installment30Days,
  Installment31Days,
  Installment40Days,
  Installment45Days,
  Installment60Days,
  Installment90Days,
  TwoInstallments40And70Days,
  TwoInstallments60And90Days,
  TwoInstallments10And30DaysEqual,
  TwoInstallments10And40DaysEqual,
  TwoInstallments15And30DaysEqual,
  TwoInstallments30And60DaysEqual,
  ThreeInstallments40And70And100Days,
  ThreeInstallments90And120And150Days,
  ThreeInstallments10And30And60DaysEqual,
  ThreeInstallments30And60And90DaysEqual,
  ThreeInstallments60And90And120DaysEqual,
  FourInstallments10And30And60And90DaysEqual,
  FourInstallments30And60And90And120DaysEqual,
  FourInstallments45And75And105And135DaysEqual,
  FiveInstallments10And30And60And90And120DaysEqual,
  FiveInstallments30And60And90And120And150DaysEqual,
  SixInstallments10And30And60And90And120And150DaysEqual,
  SixInstallments30And60And90And120And150And180DaysEqual,
  EightInstallments30And60And90And120And150,
  TenInstallments30And60And90,
  TwelveInstallments10And30And60And90And120And150And180And210And240And270,
  TwelveInstallments30And60And90And120DaysEqual,
  EighteenInstallments30And60And90DaysEqual,
  TwentyInstallments15And30And45And60And75And90And105And120And135Days,
  TwentyFourInstallments30And60And90DaysEqual,
}

export const paymentConditionMap: { [key in PaymentCondition]: OptionsProps } =
  {
    [PaymentCondition.Cash]: { id: "Cash", text: "À Vista" },
    [PaymentCondition.DebitNote]: { id: "DebitNote", text: "NOTA DE DÉBITO" },
    [PaymentCondition.AnotherWay]: {
      id: "AnotherWay",
      text: "Outras formas de pagamento",
    },
    [PaymentCondition.Installment120Days]: {
      id: "Installment120Days",
      text: "01 parcela 120 dias",
    },
    [PaymentCondition.Installment30DaysCreditCard]: {
      id: "Installment30DaysCreditCard",
      text: "01 parcela 30 DIAS - cartão de crédito",
    },
    [PaymentCondition.Installment7Days]: {
      id: "Installment7Days",
      text: "01 parcela: 07 dias",
    },
    [PaymentCondition.Installment9Days]: {
      id: "Installment9Days",
      text: "01 parcela: 09 dias",
    },
    [PaymentCondition.Installment10Days]: {
      id: "Installment10Days",
      text: "01 parcela: 10 dias",
    },
    [PaymentCondition.Installment14Days]: {
      id: "Installment14Days",
      text: "01 parcela: 14 dias",
    },
    [PaymentCondition.Installment15Days]: {
      id: "Installment15Days",
      text: "01 parcela: 15 dias",
    },
    [PaymentCondition.Installment18Days]: {
      id: "Installment18Days",
      text: "01 parcela: 18 dias",
    },
    [PaymentCondition.Installment20Days]: {
      id: "Installment20Days",
      text: "01 parcela: 20 dias",
    },
    [PaymentCondition.Installment21Days]: {
      id: "Installment21Days",
      text: "01 parcela: 21 dias",
    },
    [PaymentCondition.Installment28Days]: {
      id: "Installment28Days",
      text: "01 parcela: 28 dias",
    },
    [PaymentCondition.Installment30Days]: {
      id: "Installment30Days",
      text: "01 parcela: 30 dias",
    },
    [PaymentCondition.Installment31Days]: {
      id: "Installment31Days",
      text: "01 parcela: 31 dias",
    },
    [PaymentCondition.Installment40Days]: {
      id: "Installment40Days",
      text: "01 parcela: 40 dias",
    },
    [PaymentCondition.Installment45Days]: {
      id: "Installment45Days",
      text: "01 parcela: 45 dias",
    },
    [PaymentCondition.Installment60Days]: {
      id: "Installment60Days",
      text: "01 parcela: 60 dias",
    },
    [PaymentCondition.Installment90Days]: {
      id: "Installment90Days",
      text: "01 parcela: 90 dias",
    },
    [PaymentCondition.TwoInstallments40And70Days]: {
      id: "TwoInstallments40And70Days",
      text: "02 parcelas - 40/70 DIAS",
    },
    [PaymentCondition.TwoInstallments60And90Days]: {
      id: "TwoInstallments60And90Days",
      text: "02 parcelas - 60/90 DIAS",
    },
    [PaymentCondition.TwoInstallments10And30DaysEqual]: {
      id: "TwoInstallments10And30DaysEqual",
      text: "02 parcelas: 10/30 dias - iguais",
    },
    [PaymentCondition.TwoInstallments10And40DaysEqual]: {
      id: "TwoInstallments10And40DaysEqual",
      text: "02 parcelas: 10/40 dias - iguais",
    },
    [PaymentCondition.TwoInstallments15And30DaysEqual]: {
      id: "TwoInstallments15And30DaysEqual",
      text: "02 parcelas: 15/30 dias - iguais",
    },
    [PaymentCondition.TwoInstallments30And60DaysEqual]: {
      id: "TwoInstallments30And60DaysEqual",
      text: "02 parcelas: 30/60 dias - iguais",
    },
    [PaymentCondition.ThreeInstallments40And70And100Days]: {
      id: "ThreeInstallments40And70And100Days",
      text: "03 parcelas - 40/70/100 DIAS",
    },
    [PaymentCondition.ThreeInstallments90And120And150Days]: {
      id: "ThreeInstallments90And120And150Days",
      text: "03 parcelas 90/120/150 DIAS",
    },
    [PaymentCondition.ThreeInstallments10And30And60DaysEqual]: {
      id: "ThreeInstallments10And30And60DaysEqual",
      text: "03 parcelas: 10/30/60 dias - iguais",
    },
    [PaymentCondition.ThreeInstallments30And60And90DaysEqual]: {
      id: "ThreeInstallments30And60And90DaysEqual",
      text: "03 parcelas: 30/60/90 dias - iguais",
    },
    [PaymentCondition.ThreeInstallments60And90And120DaysEqual]: {
      id: "ThreeInstallments60And90And120DaysEqual",
      text: "03 parcelas: 60/90/120 dias - iguais",
    },
    [PaymentCondition.FourInstallments10And30And60And90DaysEqual]: {
      id: "FourInstallments10And30And60And90DaysEqual",
      text: "04 parcelas: 10/30/60/90 dias - iguais",
    },
    [PaymentCondition.FourInstallments30And60And90And120DaysEqual]: {
      id: "FourInstallments30And60And90And120DaysEqual",
      text: "04 parcelas: 30/60/90/120 dias - iguais",
    },
    [PaymentCondition.FourInstallments45And75And105And135DaysEqual]: {
      id: "FourInstallments45And75And105And135DaysEqual",
      text: "04 parcelas: 45/75/105/135 dias - iguais",
    },
    [PaymentCondition.FiveInstallments10And30And60And90And120DaysEqual]: {
      id: "FiveInstallments10And30And60And90And120DaysEqual",
      text: "05 parcelas: 10/30/60/90/120 dias - iguais",
    },
    [PaymentCondition.FiveInstallments30And60And90And120And150DaysEqual]: {
      id: "FiveInstallments30And60And90And120And150DaysEqual",
      text: "05 parcelas: 30/60/90/120/150 dias - iguais",
    },
    [PaymentCondition.SixInstallments10And30And60And90And120And150DaysEqual]: {
      id: "SixInstallments10And30And60And90And120And150DaysEqual",
      text: "06 parcelas: 10/30/60/90/120/150 dias - iguais",
    },
    [PaymentCondition.SixInstallments30And60And90And120And150And180DaysEqual]: {
      id: "SixInstallments30And60And90And120And150And180DaysEqual",
      text: "06 parcelas: 30/60/90/120/150/180 dias - iguais",
    },
    [PaymentCondition.EightInstallments30And60And90And120And150]: {
      id: "EightInstallments30And60And90And120And150",
      text: "08 parcelas - 30/60/90/120/150.....",
    },
    [PaymentCondition.TenInstallments30And60And90]: {
      id: "TenInstallments30And60And90",
      text: "10 parcelas - 30/60/90.....",
    },
    [PaymentCondition.TwelveInstallments10And30And60And90And120And150And180And210And240And270]:
      {
        id: "TwelveInstallments10And30And60And90And120And150And180And210And240And270",
        text: "12 parcelas - 10/30/60/90/120/150/180/210/240/270",
      },
    [PaymentCondition.TwelveInstallments30And60And90And120DaysEqual]: {
      id: "TwelveInstallments30And60And90And120DaysEqual",
      text: "12 parcelas: 30/60/90/120.... dias - iguais",
    },
    [PaymentCondition.EighteenInstallments30And60And90DaysEqual]: {
      id: "EighteenInstallments30And60And90DaysEqual",
      text: "18 parcelas: 30/60/90/... dias - iguais",
    },
    [PaymentCondition.TwentyInstallments15And30And45And60And75And90And105And120And135Days]:
      {
        id: "TwentyInstallments15And30And45And60And75And90And105And120And135Days",
        text: "20 parcelas : 15/30/45/60/75/90/105/120/135/..dias",
      },
    [PaymentCondition.TwentyFourInstallments30And60And90DaysEqual]: {
      id: "TwentyFourInstallments30And60And90DaysEqual",
      text: "24 parcelas: 30/60/90/... dias - iguais",
    },
  };

export const getPaymentConditionById = (id: string): OptionsProps | null => {
  const options = Object.values(paymentConditionMap);
  const found = options.find((condition) => condition.id === id);
  return found ?? null;
};

export const toPaymentCondition = (
  key: string | null
): PaymentCondition | null => {
  if (!key) return null;

  const paymentCondition = Object.entries(paymentConditionMap).find(
    ([, value]) => value.id === key
  );

  return paymentCondition
    ? (paymentCondition[0] as unknown as PaymentCondition)
    : null;
};
