import React, {useState} from "react";
import {Input} from "../../../components/atoms/Input";
import {Select} from "../../../components/atoms/Select";
import {CustomerContacts as Customercontact} from "../../../models/Customer";
import {TypeContact} from "../../../models/enums/TypeContact";
import { useToast } from "../../../../src/components/atoms/Toast";

import {
    resendAdminInvite
} from "../../../services/api/CustomerService"
import {EditCustomerForm} from "../models/CustomerForm";

interface CustomerContactsProps {
    contactTypes: any;
    disabled?: boolean;
    formData: any;
    handleInputChange: any;
    handleSelectChange: any;
}

export const CustomerContacts: React.FC<CustomerContactsProps> = ({
                                                                      handleSelectChange,
                                                                      handleInputChange,
                                                                      formData,
                                                                      contactTypes,
                                                                      disabled = false,
                                                                  }) => {

    const toast = useToast();
    const [isLoadingSendInvite, setIsLoadingSendInvite] = useState<boolean>(false);


    const validateResponsible = (): boolean => {
        const requiredFields = [
            {
                id: "signingResponsibleName",
                label: "Nome do Responsável pela Assinatura",
            },
            {
                id: "signingResponsibleTypePhone",
                label: "Tipo de Telefone do Responsável pela Assinatura",
            },
            {
                id: "signingResponsibleNumber",
                label: "Telefone do Responsável pela Assinatura",
            },
            {
                id: "signingResponsibleEmail",
                label: "Email do Responsável pela Assinatura",
            }
        ];

        const newErrors: Record<string, boolean> = {};
        let isValid = true;

        for (const field of requiredFields) {
            const fieldValue = formData[field.id as keyof EditCustomerForm];
            if (!fieldValue && field.id !== "customerId") {
                newErrors[field.id] = true;
                isValid = false;
                toast.fire({
                    icon: "warning",
                    title: `O campo ${field.label} é obrigatório.`,
                });
                break;
            }

            if(field.id === "signingResponsibleTypePhone")
            {
                if(fieldValue <= 0)
                {
                    isValid = false;
                    toast.fire({
                        icon: "warning",
                        title: `O campo ${field.label} é obrigatório.`,
                    });
                    break;
                }
            }

            if (field.id.includes("ResponsibleName") && fieldValue) {
                if (typeof fieldValue === "string") {
                    const nameParts = fieldValue.trim().split(" ");
                    if (nameParts.length < 2) {
                        newErrors[field.id] = true;
                        isValid = false;
                        toast.fire({
                            icon: "warning",
                            title: `O campo ${field.label} deve conter nome e sobrenome.`,
                        });
                    }
                }
            }
        }

        return isValid;
    };

    const handleSendInvite = async () => {

        if(validateResponsible())
        {
            try {
                setIsLoadingSendInvite(true);

                const contact: Customercontact = {
                    id:0,
                    name: formData.signingResponsibleName,
                    email: formData.signingResponsibleEmail,
                    typeContact: TypeContact.Signature,
                    phoneNumber: formData.signingResponsibleNumber,
                    typePhone: formData.signingResponsibleTypePhone,
                    customerId: formData.id,
                };

                await resendAdminInvite(contact);

            } catch (error) {

                if (error.status === 406) {
                    toast.fire({
                        icon: "warning",
                        title: error.response.data.message,
                    });
                } else {
                    toast.fire({
                        icon: "error",
                        title: "Falha ao reenviar convite",
                    });
                }
            } finally {
                setIsLoadingSendInvite(false);

                toast.fire({
                    icon: "success",
                    title: "Convite enviado com sucesso!",
                });
            }
        }
    };

    return (
        <>
            <div className="row">
                <div className="col col-12">
                    <strong>Responsavel pela assinatura</strong>
                </div>
                <div className="col col-3">
                    <Input
                        label="Nome Completo *"
                        id="signingResponsibleName"
                        onChange={handleInputChange}
                        value={formData.signingResponsibleName}
                        disabled={disabled}
                        placeholder="-"
                    />
                </div>
                <div className="col col-2">
                    <Select
                        label="Tipo *"
                        options={contactTypes}
                        id="signingResponsibleTypePhone"
                        onChange={handleSelectChange}
                        value={formData.signingResponsibleTypePhone}
                        disabled={disabled}
                    />
                </div>
                <div className="col col-2">
                    <Input
                        label="Telefone *"
                        id="signingResponsibleNumber"
                        placeholder="(00) 00000-0000"
                        mask="(99) 99999-9999"
                        onChange={handleInputChange}
                        value={formData.signingResponsibleNumber}
                        disabled={disabled}
                    />
                </div>
                <div className="col col-3">
                    <Input
                        label="Email *"
                        id="signingResponsibleEmail"
                        type="email"
                        onChange={handleInputChange}
                        value={formData.signingResponsibleEmail}
                        disabled={disabled}
                        placeholder="-"
                    />
                </div>
                <div className="col col-2">
                    <button className="btn btn-primary btn-sm form-control" type="button" style={{marginTop: '32px'}}
                            onClick={handleSendInvite}
                            disabled={isLoadingSendInvite}>

                        {isLoadingSendInvite ? (
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        ) : (
                            <i className="fa fa-fw fa-paper-plane"></i>
                        )}
                        Reenviar convite
                    </button>
                </div>
            </div>
            <div className="row">
                <div className="col col-12">
                    <strong>Responsavel pela compra</strong>
                </div>
                <div className="col col-4">
                    <Input
                        label="Nome Completo"
                        id="purchasingResponsibleName"
                        onChange={handleInputChange}
                        value={formData.purchasingResponsibleName}
                        disabled={disabled}
                        placeholder="-"
                    />
                </div>
                <div className="col col-2">
                    <Select
                        label="Tipo"
                        options={contactTypes}
                        id="purchasingResponsibleTypePhone"
                        onChange={handleSelectChange}
                        value={formData.purchasingResponsibleTypePhone}
                        disabled={disabled}
                    />
                </div>
                <div className="col col-3">
                    <Input
                        label="Telefone"
                        id="purchasingResponsibleNumber"
                        placeholder="(00) 00000-0000"
                        mask="(99) 99999-9999"
                        onChange={handleInputChange}
                        value={formData.purchasingResponsibleNumber}
                        disabled={disabled}
                    />
                </div>
                <div className="col col-3">
                    <Input
                        label="Email"
                        id="purchasingResponsibleEmail"
                        type="email"
                        onChange={handleInputChange}
                        value={formData.purchasingResponsibleEmail}
                        disabled={disabled}
                        placeholder="-"
                    />
                </div>
            </div>
            <div className="row">
                <div className="col col-12">
                    <strong>Responsavel pelo financeiro</strong>
                </div>
                <div className="col col-4">
                    <Input
                        label="Nome Completo"
                        id="financialResponsibleName"
                        onChange={handleInputChange}
                        value={formData.financialResponsibleName}
                        disabled={disabled}
                        placeholder="-"
                    />
                </div>
                <div className="col col-2">
                    <Select
                        label="Tipo"
                        options={contactTypes}
                        id="financialResponsibleTypePhone"
                        onChange={handleSelectChange}
                        value={formData.financialResponsibleTypePhone}
                        disabled={disabled}
                    />
                </div>
                <div className="col col-3">
                    <Input
                        label="Telefone"
                        id="financialResponsibleNumber"
                        placeholder="(00) 00000-0000"
                        mask="(99) 99999-9999"
                        onChange={handleInputChange}
                        value={formData.financialResponsibleNumber}
                        disabled={disabled}
                    />
                </div>
                <div className="col col-3">
                    <Input
                        label="Email"
                        id="financialResponsibleEmail"
                        type="email"
                        onChange={handleInputChange}
                        value={formData.financialResponsibleEmail}
                        disabled={disabled}
                        placeholder="-"
                    />
                </div>
            </div>
        </>
    );
};
