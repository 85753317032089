import React from "react";

import { Modal } from "../../../components/mols/Modal";
import { Loader } from "../../../components/atoms/Loader";

interface ModalCancelItemRmaProps {
  isShown: boolean;
  isLoading: boolean;
  onConfirm(): void;
  onClose(): void;
}

export const ModalCancelItemRma: React.FC<ModalCancelItemRmaProps> = ({
  isShown,
  isLoading,
  onConfirm,
  onClose,
}) => {
  return (
    <Modal
      isShown={isShown}
      title="Cancelamento"
      body={
        <>
          <p className="mb-2">
            Todos os itens selecionados serão cancelados, tem certeza que deseja
            continuar com esta ação?
          </p>
          <p>
            Não é possível reverter esta ação, tem certeza que deseja cancelar?
          </p>
        </>
      }
      footer={
        <div className="w-100 d-flex justify-content-center gap-2">
          <button
            className="btn btn-secondary"
            onClick={onClose}
            disabled={isLoading}
          >
            Cancelar {isLoading && <Loader />}
          </button>

          <button
            className="btn btn-primary"
            onClick={onConfirm}
            disabled={isLoading}
          >
            Continuar {isLoading && <Loader />}
          </button>
        </div>
      }
      onClose={onClose}
    />
  );
};
