import { MainTemplate } from "../../../components/templates/MainTemplate";

interface NotFoundProps {
    message: string;
}

const NotFound: React.FC<NotFoundProps> = ({ message }) => {
    return (
        <div style={styles.message}>
            <span style={styles.icon}>❌</span>
            <span>{message}</span>
        </div>
    );
};

const styles = {
    message: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#f5c6cb',
        color: '#721c24',
        padding: '15px 30px',
        borderRadius: '5px',
        fontSize: '18px',
        fontWeight: 'bold',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    },
    icon: {
        marginRight: '10px',
        fontSize: '22px',
    },
};

export const OrderNotFound: React.FC = () => {
    return (
        <MainTemplate>
            <NotFound message="Pedido não encontrado" />
        </MainTemplate>
    );
};