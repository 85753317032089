// Opções correspondentes ao enum OrderStatus no backend
export const orderStatusOptions = [
  { value: "Draft", text: "Rascunho" },
  { value: "CreditAnalysis", text: "Análise de Crédito" },
  { value: "CreditDenied", text: "Crédito Negado" },
  { value: "FinancialRefused", text: "Recusado Financeiro" },
  { value: "WaitingSendingAdobe", text: "Aguardando Envio Adobe" },
  { value: "Processed", text: "Processado" },
  { value: "WaitingSendingBilling", text: "Aguardando Envio Faturamento" },
  { value: "Billed", text: "Faturado" },
  { value: "ErrorSendingAdobe", text: "Erro Envio Adobe" },
];
