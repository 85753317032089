import React from "react";

interface SwitchBtnProps {
  id?: string;
  label?: string;
  checked?: boolean;
  inline?: boolean;
  disabled?: boolean;
  className?: string;
  onChange?: (checked: boolean) => void;
}

export const SwitchBtn: React.FC<SwitchBtnProps> = ({
  id,
  label = null,
  inline = false,
  checked = false,
  disabled = false,
  className = null,
  onChange,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.checked);
  };

  const domLabel = label && (
    <label className="form-check-label" htmlFor={id}>
      {label}
    </label>
  );

  const inlineClassName = inline ? "inline" : "";

  return (
    <div className="switchBtn">
      {!inline ? domLabel : ""}
      <div className={`form-check form-switch ${inlineClassName} ${className}`}>
        <input
          className={`form-check-input mb-2 ${inlineClassName}`}
          type="checkbox"
          role="switch"
          id={id}
          checked={checked}
          onChange={handleChange}
          disabled={disabled}
        />
        {inline && domLabel}
      </div>
    </div>
  );
};
