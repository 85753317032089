import React, { useState, useRef, useEffect } from "react";
import { renderToString } from "react-dom/server";
import { MainTemplate } from "../../components/templates/MainTemplate";
import { Card } from "../../components/mols/Card";
import { Icon } from "../../components/atoms/Icon";
import { useGetResellers } from "../../hooks/resellerHook";
import { useGetProfiles } from "../../hooks/profileHook";
import ptbr from "../../assets/lib/js/dataTables/pt-br.json";
import $ from "jquery";
import { Filters, UserFilters } from "./components/UserFilters";
import { getUsers } from "../../services/api/UserService";
import { Config } from "datatables.net-dt";

import 'datatables.net-bs5';
import 'datatables.net-responsive';
import 'datatables.net-buttons-bs5';

import JSZip from 'jszip';
// @ts-ignore
window.JSZip = JSZip;

export const UserList: React.FC = () => {
  const { data: resellers, loading: isResellersLoading } = useGetResellers();
  const { data: profiles, loading: isProfilesLoading } = useGetProfiles();
  const tableRef = useRef<any>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [filters, setFilters] = useState<Filters>({
    user: "",
    reseller: "",
    profile: "",
  });
  const filterReset = () => {
    setFilters({
      user: "",
      reseller: "",
      profile: "",
    })
  }
  const tableColumns = [
    { data: "name" },
    { data: "email" },
    {
      data: "resellerName",
      render: (resellerName: string) => {
        return resellerName ? resellerName : "Todas";
      },
    },
    { data: "profileName" },
    {
      data: "id",
      orderable: false,
      render: (id: number) => {
        return renderToString(
          <a href={`/user/edit/${id}`} className="d-block text-end">
            <Icon className="fa fa-fw fa-pen" />
          </a>
        );
      },
    },
  ];

  const dataTableOptions: Config = {
    dom: 'Bfrtip',
    buttons: [
      {
        extend: "excelHtml5",
        text: '<i class="fa fa-file-excel"></i>',
        filename: "Revendas",
        className: "btn btn-success",
        exportOptions: {
          columns: ":visible",
        }
      },
      {
        extend: "csvHtml5",
        text: '<i class="fa fa-file-csv"></i>',
        filename: "Revendas",
        className: "btn btn-info",
        exportOptions: {
          columns: ":visible",
        },
        init: function (api, node) {
          $(node).css("margin-left", "5px");
        }

      }
    ],
    language: ptbr,
    columns: tableColumns,
    ordering: false,
    serverSide: true,
    processing: true,
    lengthMenu: [
      [64, 128, 256, 512],
      [64, 128, 256, 512],
    ],
    ajax: (data: any, callback: any) => {
      const { start, length, draw } = data;
      const currentPage = Math.floor(start / length);

      getUsers(currentPage, length).then((result) => {
        callback({
          draw: draw,
          recordsTotal: result.recordsTotal,
          recordsFiltered: result.recordsFiltered,
          data: result.data,
        });
      });
    },
  };

  useEffect(() => {
    if (tableRef.current) {
      if ($.fn.dataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }
        $(tableRef.current).DataTable({
        ...dataTableOptions,
        ajax: (data: any, callback: any) => {
          const { start, length, draw } = data;
          const currentPage = Math.floor(start / length);
  
          getUsers(
            currentPage || 0,
            length+1 || 10,
            filters.user,
            Number(filters.reseller),
            Number(filters.profile)
          ).then((result) => {
            callback({
              draw: draw,
              recordsTotal: result.recordsTotal,
              recordsFiltered: result.recordsFiltered,
              data: result.data,
            });
          });
        },
      });
    }
  }, [filters]);

  const handleFilter = () => {
    const { user, reseller, profile } = filters;

    const normalizedReseller =
      reseller === "Todos" ? undefined : reseller?.toLowerCase();
    const normalizedProfile =
      profile === "Todos" ? undefined : profile?.toLowerCase();
    const normalizedUser = user?.toLowerCase();

    $.fn.dataTable.ext.search.push((settings: any, data: string[]) => {
      const [userData, __, resellerData, profileData] = data.map((value) =>
        value?.toLowerCase()
      );

      const userMatches = !normalizedUser || userData.includes(normalizedUser);
      const resellerMatches =
        !normalizedReseller || resellerData?.includes(normalizedReseller);
      const profileMatches =
        !normalizedProfile || profileData?.includes(normalizedProfile);

      return userMatches && resellerMatches && profileMatches;
    });

    const table = $(tableRef.current).DataTable();
    table.page("first").draw();
  };

  return (
    <MainTemplate>
      <Card className="mb-3">
        <UserFilters
          filters={filters}
          setFilters={setFilters}
          resellers={resellers ?? []}
          profiles={profiles ?? []}
          isProfilesLoading={isProfilesLoading}
          onFilter={handleFilter}
          filterReset={filterReset}
        />
      </Card>

      <Card>
        <table ref={tableRef} className="table table-sm table-striped">
          <thead>
            <tr>
              <th>Usuário</th>
              <th>E-mail</th>
              <th>Revenda</th>
              <th>Perfil</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </Card>
    </MainTemplate>
  );
};
