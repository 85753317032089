import { Profile } from "./Profiles";
import { Reseller } from "./Reseller";

export interface OptionsProps {
  id?: string;
  text: string;
  selected?: boolean;
  disabled?: boolean;
}

const optionAll: OptionsProps = { id: "0", text: "Todos" };
const selectOpt: OptionsProps = { id: "0", text: "Selecione", disabled: true };

const convertToOptionsProps = (
  items: any[] | null,
  labelKey: string,
  hasAll: boolean = false
): OptionsProps[] => {
  if (!items) return [];

  let options: OptionsProps[] = items.map((item) => {
    const id = item.id.toString();
    const text = (item as any)[labelKey];

    return { id, text };
  });

  if (hasAll) return [optionAll, ...options];
  else return [selectOpt, ...options];
};

export const toOptionsProps = (
  items: (Reseller | Profile)[] | null,
  hasAll: boolean = false
): OptionsProps[] => {
  if (!items || items.length === 0) return [optionAll];

  if ("tradeName" in items[0]) {
    return convertToOptionsProps(items as Reseller[], "tradeName", hasAll);
  } else if ("name" in items[0]) {
    return convertToOptionsProps(items as Profile[], "name", hasAll);
  }

  return [];
};
