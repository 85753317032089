/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback } from "react";
import { CustomerProduct } from "../models/Product";
import { getProductsByCustomer } from "../services/api/ProductService";
import { Customer } from "../models/Customer";
import { useToast } from "../components/atoms/Toast";
import { useErrorHandler } from "./useErrorHandler";

export const useProducts = (
  customer: Customer | null,
  customerId: BigInt | number
) => {
  const productsPerPage = 20;
  const toast = useToast();
  const { handleError } = useErrorHandler();

  const [products, setProducts] = useState<CustomerProduct[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const fetchProducts = useCallback(
    async (
      licenseLevel: string,
      transactionLevel: string,
      resetPage: boolean = false,
      filter: string = ""
    ) => {
      if (!customer) return;
      setIsLoading(true);

      try {
        if (resetPage) setProducts([]);

        const currentPage = resetPage ? 0 : page;
        const productResponse = await getProductsByCustomer(
          customerId,
          currentPage,
          productsPerPage,
          licenseLevel,
          transactionLevel,
          filter
        );

        if (resetPage) {
          setProducts(productResponse.data);
          setPage(1);
        } else {
          setProducts((previousProducts) => [
            ...previousProducts,
            ...productResponse.data,
          ]);
          setPage((prevPage) => prevPage + 1);
        }

        setHasMore(productResponse.data.length > 0);
        setIsLoading(false);
      } catch (error) {
        handleError(error, toast, "listar os produtos");
      }
    },
    [customer, customerId, page]
  );

  return { products, isLoading, hasMore, fetchProducts };
};
