export const states = [
    { id: "0", text: "Nenhum" },
    { id: "AC", text: "AC" },
    { id: "AL", text: "AL" },
    { id: "AP", text: "AP" },
    { id: "AM", text: "AM" },
    { id: "BA", text: "BA" },
    { id: "CE", text: "CE" },
    { id: "DF", text: "DF" },
    { id: "ES", text: "ES" },
    { id: "GO", text: "GO" },
    { id: "MA", text: "MA" },
    { id: "MT", text: "MT" },
    { id: "MS", text: "MS" },
    { id: "MG", text: "MG" },
    { id: "PA", text: "PA" },
    { id: "PB", text: "PB" },
    { id: "PR", text: "PR" },
    { id: "PE", text: "PE" },
    { id: "PI", text: "PI" },
    { id: "RJ", text: "RJ" },
    { id: "RN", text: "RN" },
    { id: "RS", text: "RS" },
    { id: "RO", text: "RO" },
    { id: "RR", text: "RR" },
    { id: "SC", text: "SC" },
    { id: "SP", text: "SP" },
    { id: "SE", text: "SE" },
    { id: "TO", text: "TO" },
  ];