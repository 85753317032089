import React, { useCallback, useRef, useState } from "react";
import { Customer } from "../../../models/Customer";
import { Reseller } from "../../../models/Reseller";
import { autocompleteCustomers } from "../../../services/api/CustomerService";
import { autocompleteResellers } from "../../../services/api/ResellerService";
import Autocomplete from "../../../components/atoms/Autocomplete";
import { Icon } from "../../../components/atoms/Icon";
import { Input } from "../../../components/atoms/Input";
import { getAuthUser } from "../../../utils/authUtils";

export interface FormFilterListRma {
  startDate: string;
  endDate: string;
  resellerId: string;
  customerId: string;
  customerIdAdobe: string;
  rmaCode: string;
  pedAdobe: string;
  pedCustomer: string;
  pedReseller: string;
}
export interface FilterListRmaProps {
  onSearch(form: FormFilterListRma): void;
}

const FilterListRma: React.FC<FilterListRmaProps> = ({ onSearch }) => {
  const isReseller = getAuthUser()!.profile.isReseller;

  const formRef = useRef<HTMLFormElement | null>(null);
  const [formFilters, setFormFilters] = useState<FormFilterListRma>({
    startDate: "",
    endDate: "",
    resellerId: "",
    customerId: "",
    customerIdAdobe: "",
    rmaCode: "",
    pedAdobe: "",
    pedCustomer: "",
    pedReseller: "",
  });
  const resetFilters = () => {
    setFormFilters({
      startDate: "",
      endDate: "",
      resellerId: "",
      customerId: "",
      customerIdAdobe: "",
      rmaCode: "",
      pedAdobe: "",
      pedCustomer: "",
      pedReseller: "",
    });
  };

  const loadResellers = useCallback(
    async (search: string): Promise<Reseller[]> => {
      const data = await autocompleteResellers(search);
      return data;
    },
    []
  );

  const loadCustomers = useCallback(
    async (search: string): Promise<Customer[]> => {
      const data = await autocompleteCustomers(search);
      return data;
    },
    []
  );

  const handleSearch = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      onSearch(formFilters);
    },
    [formFilters, onSearch]
  );

  return (
    <form ref={formRef} noValidate onSubmit={handleSearch}>
      <div className="row">
        {!isReseller && (
          <div className="col col-md-6 col-12">
            <Autocomplete<Reseller>
              id="autotomplete-resellers"
              label="Revenda"
              placeholder="Revenda"
              dataFetcher={loadResellers}
              keyField="tradeName"
              onSelect={(data: Reseller) => {
                const id = data?.id.toString() ?? "";
                setFormFilters((st) => ({ ...st, resellerId: id }));
              }}
              onEmptyResult={() => {
                setFormFilters((st) => ({ ...st, resellerId: "0" }));
              }}
              onClear={() => {
                setFormFilters((st) => ({ ...st, resellerId: "" }));
              }}
            />
          </div>
        )}
        <div className={`col col-md-${!isReseller ? 6 : 12} col-12`}>
          <Autocomplete<Customer>
            id="autotomplete-customers"
            label="Cliente"
            placeholder="Cliente"
            dataFetcher={loadCustomers}
            keyField="name"
            onSelect={(data: Customer) => {
              const id = data?.id?.toString() ?? "";
              setFormFilters((st) => ({ ...st, customerId: id }));
            }}
            onEmptyResult={() => {
              setFormFilters((st) => ({ ...st, customerId: "0" }));
            }}
            onClear={() => {
              setFormFilters((st) => ({ ...st, customerId: "" }));
            }}
          />
        </div>

        <div className="col col-md-3 col-6">
          <Input
            label="Cód. RMA"
            placeholder="Cód. RMA"
            type="text"
            value={formFilters.rmaCode}
            onChange={(e) =>
              setFormFilters((st) => ({ ...st, rmaCode: e.target.value }))
            }
          />
        </div>
        <div className="col col-md-3 col-6">
          <Input
            label="Ped. Adobe"
            placeholder="Ped. Adobe"
            type="text"
            value={formFilters.pedAdobe}
            onChange={(e) =>
              setFormFilters((st) => ({ ...st, pedAdobe: e.target.value }))
            }
          />
        </div>
        <div className="col col-md-3 col-6">
          <Input
            label="Ped. Cliente"
            placeholder="Ped. Cliente"
            type="text"
            value={formFilters.pedCustomer}
            onChange={(e) =>
              setFormFilters((st) => ({ ...st, pedCustomer: e.target.value }))
            }
          />
        </div>
        <div className="col col-md-3 col-6">
          <Input
            label="Ped. Revenda"
            placeholder="Ped. Revenda"
            type="text"
            value={formFilters.pedReseller}
            onChange={(e) =>
              setFormFilters((st) => ({ ...st, pedReseller: e.target.value }))
            }
          />
        </div>
      </div>

      <div className="d-flex justify-content-end gap-3">
        <button
          className="btn btn-secondary"
          id="clearFilter"
          type="reset"
          onClick={resetFilters}
        >
          Limpar Filtro
        </button>
        <button className="btn btn-primary" type="submit">
          Filtrar <Icon className="fa fa-fw fa-filter" />
        </button>
      </div>
    </form>
  );
};

export default FilterListRma;
