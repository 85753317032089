import React, { useMemo } from "react";
import { format } from "date-fns";
import { Card } from "../../../components/mols/Card";
import { Input } from "../../../components/atoms/Input";
import { Order } from "../../../models/Order";
import { billingForMap } from "../../../models/enums/BillingFor";
import { paymentConditionMap } from "../../../models/enums/PaymentCondition";

interface HeaderDetailRmaProps {
  data: Order;
}

const HeaderDetailRma: React.FC<HeaderDetailRmaProps> = ({ data }) => {
  const orderDate = useMemo(() => {
    if (!data.dateCreated) return "-";

    return format(new Date(data.dateCreated), "dd/MM/yyyy");
  }, [data.dateCreated]);

  const customer = useMemo(() => {
    if (!data.customer)
      throw new Error("Não foi possível identificar o cliente");

    return data.customer;
  }, [data.customer]);

  const reseller = useMemo(() => {
    if (!data.reseller)
      throw new Error("Não foi possível identificar a revenda");

    return data.reseller;
  }, [data.reseller]);

  const resellerName = useMemo(() => {
    return `${reseller.resellerId?.toString()} - ${reseller.tradeName}`;
  }, [reseller.resellerId, reseller.tradeName]);

  const customerName = useMemo(() => {
    return `${customer.customerId.toString()} - ${customer.name}`;
  }, [customer.customerId, customer.name]);

  const proRata = useMemo(() => {
    return `${customer.proRata.toString()}`;
  }, [customer.proRata]);

  const cotermDate = useMemo(() => {
    if (!customer.cotermDate) return "-";

    return format(new Date(customer.cotermDate), "dd/MM/yyyy");
  }, [customer.cotermDate]);

  const billingFor = useMemo(() => {
    const key = data.billingFor ?? "";
    const value = Object.values(billingForMap).find(
      (item) => item.id === key
    )?.text;
    return value ?? "-";
  }, [data.billingFor]);

  const paymentCondition = useMemo(() => {
    const key = data.paymentCondition ?? "";
    const value = Object.values(paymentConditionMap).find(
      (item) => item.id === key
    )?.text;
    return value ?? "-";
  }, [data.paymentCondition]);

  return (
    <Card className="mb-3">
      <h1 className="h2">RMA</h1>
      <h2 className="h3">Número MP: {data.id}</h2>
      
      <form>
        <div className="row">
          <div className="col col-md-2 col-6">
            <Input label="Data" type="text" value={orderDate} disabled />
          </div>
          <div className="col col-md-2 col-6">
            <Input
              label="Tipo"
              type="text"
              value={data.isRenewal ? "Renovação" : "Novo"}
              disabled
            />
          </div>
          <div className="col col-md-4 col-12">
            <Input label="Revenda" type="text" value={resellerName} disabled />
          </div>
          <div className="col col-md-4 col-12">
            <Input label="Cliente" type="text" value={customerName} disabled />
          </div>

          <div className="col col-md-2 col-3">
            <Input
              label="Aniversário"
              type="text"
              value={cotermDate}
              disabled
            />
          </div>
          <div className="col col-md-2 col-3">
            <Input label="Pro Rata" type="text" value={proRata} disabled />
          </div>
          <div className="col col-md-2 col-6">
            <Input
              label="No MP"
              type="text"
              value={data.id?.toString() ?? "-"}
              disabled
            />
          </div>
          <div className="col col-md-2 col-6">
            <Input
              label="Ped. Cliente"
              type="text"
              value={data.pedCustomer ?? "-"}
              disabled
            />
          </div>
          <div className="col col-md-2 col-6">
            <Input
              label="Ped. Revenda"
              type="text"
              value={data.pedReseller ?? "-"}
              disabled
            />
          </div>
          <div className="col col-md-2 col-6">
            <Input
              label="No Adobe"
              type="text"
              value={data.adobeOrderId ?? "-"}
              disabled
            />
          </div>

          <div className="col col-md-2 col-6">
            <Input
              label="Fatura para"
              type="text"
              value={billingFor}
              disabled
            />
          </div>
          <div className="col col-md-4 col-6">
            <Input
              label="Forma de pagamento"
              type="text"
              value="Boleto"
              disabled
            />
          </div>
          <div className="col col-md-4 col-6">
            <Input
              label="Condições de faturamento"
              type="text"
              value={paymentCondition}
              disabled
            />
          </div>
        </div>
      </form>
    </Card>
  );
};

export default HeaderDetailRma;
