import React from "react";
import { Card } from "../../../components/mols/Card";

interface ProductProps {
  id: number;
  name: string;
  price: string;
  description: string;
  isSold: boolean;
  onBuy: () => void;
}

export const ProductCard: React.FC<ProductProps> = ({
  id,
  name,
  price,
  description,
  isSold,
  onBuy,
}) => {
  return (
    <Card
      id={`prd-${id}`}
      key={`prd-${id}`}
      className={`w-100 mb-3 ${isSold && "selected"}`}
      bodyClass="d-flex flex-column justify-content-between"
    >
      <div>
        <h1 className="h5 text-center text-muted">{name}</h1>
        <h2 className="h1 text-center text-bold">{price}</h2>
      </div>
      <p className="text-justify">{description}</p>
      <button className="w-100 btn btn-primary" onClick={onBuy}>
        {isSold ? "Alterar" : "Comprar"}
      </button>
    </Card>
  );
};
