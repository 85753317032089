import React, { useCallback, useEffect, useRef } from 'react';
import { Card } from '../../../components/mols/Card';
import { useNavigate } from 'react-router-dom';
import ptbr from "../../../assets/lib/js/dataTables/pt-br.json";
import DataTable, { DataTableRef } from 'datatables.net-react';
import DT from 'datatables.net-dt';
import { useToast } from '../../../components/atoms/Toast';
import { getOrdersHistory } from '../../../services/api/OrderService';
import { orderStatus } from '../../../models/enums/orderStatus';
import { OrderHistoryFilterRequest } from './OrdersHistoryList';

import 'datatables.net-bs5';
import 'datatables.net-responsive';
import 'datatables.net-buttons-bs5';

import JSZip from 'jszip';
import $ from "jquery";
import { OrderHistoryResponse } from '../../../models/OrderHistory';
// @ts-ignore
window.JSZip = JSZip;

DataTable.use(DT);

function mapOrderHistoryResponse(apiData: OrderHistoryResponse[]): OrderHistoryResponse[] | null {
    if (!(apiData.length > 0)) {
        return null;
    }

    return apiData.map((item) => ({
        id: item.id,
        customerIdAdobe: item.customer.split(" - ")[0]?.trim() || "",
        reseller: item.reseller,
        customer: item.customer,
        orderDate: `${new Date(item.orderDate).toLocaleDateString('pt-BR')} ${new Date(item.orderDate).toLocaleTimeString('pt-BR')}`,
        totalValue: item.totalValue,
        pedCustomer: item.pedCustomer,
        pedReseller: item.pedReseller,
        pedAdobe: item.pedAdobe,
        status: item.status as orderStatus,
    }));
}

interface OrdersHistoryDataTableProps {
    filters: OrderHistoryFilterRequest;
};

export const OrdersHistoryDataTable: React.FC<OrdersHistoryDataTableProps> = ({ filters }) => {
    const Toast = useToast();
    const navigate = useNavigate();
    const dataTableRef = useRef<DataTableRef>(null);

    const handleDetail = useCallback((item: OrderHistoryResponse) => {
        navigate(`/orders/status/${item.id}`);
    }, [navigate]);

    const loadTableRef = useRef<(data: any, callback: any) => void>();

    const loadTable = useCallback(async (data: any, callback: any) => {

        const { start, length, draw } = data;
        const currentPage = Math.floor(start / length);

        const newFilters = { ...filters, page: currentPage, size: length };

        try {
            const result = await getOrdersHistory(newFilters);

            const mappedData = mapOrderHistoryResponse(result?.content ?? []);

            callback({
                draw: draw,
                recordsTotal: result?.total,
                recordsFiltered: result?.total,
                data: mappedData
            });
        } catch (error) {
            if (error instanceof Error) {
                Toast.fire({
                    icon: "error",
                    title: error.message,
                });
            }

            callback({
                draw: draw,
                recordsTotal: 0,
                recordsFiltered: 0,
                data: [],
            });
        }
    }, [Toast, filters]);

    useEffect(() => {
        loadTableRef.current = loadTable;
    }, [filters, loadTable]);

    useEffect(() => {
        if (dataTableRef.current) {
            dataTableRef.current.dt()?.ajax.reload();
        }
    }, [filters, handleDetail]);

    return (
        <Card>
            <DataTable
                ref={dataTableRef}
                className="table table-sm table-striped"
                options={{
                    dom: 'Bfrtip',
                    buttons: [
                        {
                            extend: "excelHtml5",
                            text: '<i class="fa fa-file-excel"></i>',
                            filename: "Importacoes",
                            className: "btn btn-success",
                            exportOptions: {
                                columns: ":visible",
                            }
                        },
                        {
                            extend: "csvHtml5",
                            text: '<i class="fa fa-file-csv"></i>',
                            filename: "Importacoes",
                            className: "btn btn-info",
                            exportOptions: {
                                columns: ":visible",
                            },
                            init: function (api, node) {
                                $(node).css("margin-left", "5px");
                            }

                        }
                    ],
                    language: ptbr,
                    lengthMenu: [
                        [64, 128, 256, 512],
                        [64, 128, 256, 512],
                    ],
                    ordering: false,
                    serverSide: true,
                    processing: true,
                    lengthChange: false,
                    columnDefs: [
                        { width: "10%", targets: 0 },
                        { width: "20%", targets: 1 },
                        { width: "20%", targets: 2 },
                        { width: "15%", targets: 3 },
                        { width: "7%", targets: 4 },
                        { width: "7%", targets: 5 },
                        { width: "9%", targets: 6 },
                        { width: "7%", targets: 7 },
                        { width: "5%", targets: 8 },
                    ],
                    ajax: (data: any, callback: any) => {

                        if (loadTableRef.current) {
                            loadTableRef.current(data, callback);
                        }
                    },
                    createdRow: (row: Node, data: any, dataIndex: number) => {
                        const tr = row as HTMLTableRowElement;
                        tr.classList.add('pointer');
                    },
                    initComplete: () => {
                        if (dataTableRef.current) {
                            dataTableRef.current.dt()?.table().off('click', 'tbody tr');
                            dataTableRef.current.dt()?.table().on('click', 'tbody tr', function () {
                                const rowData = dataTableRef.current!.dt()?.row(this).data();
                                if (rowData) {
                                    handleDetail(rowData as OrderHistoryResponse);
                                }
                            });
                        }
                    }
                }}
                columns={[
                    {
                        data: "customerIdAdobe",
                        title: "Customer ID",
                        className: "text-center",
                        render: (data: string) =>
                            `<div style="text-align: center;">${data}</div>`,
                    },
                    {
                        data: "reseller",
                        title: "Revenda",
                        className: "text-center",
                        render: (data: string) =>
                            `<div style="text-align: center;">${data}</div>`,
                    },
                    {
                        data: "customer",
                        title: "Cliente",
                        className: "text-center",
                        render: (data: string) =>
                            `<div style="text-align: center;">${data}</div>`,
                    },
                    {
                        data: "orderDate",
                        title: "Data do Pedido",
                        className: "text-center",
                        render: (data: string) =>
                            `<div style="text-align: center;">${data}</div>`,
                    },
                    {
                        data: "totalValue",
                        title: "Valor Total",
                        className: "text-center",
                        render: (data: number) =>
                            `<div style="text-align: center;">
                            R$ ${new Intl.NumberFormat('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }).format(data)}
                          </div>`,
                    },
                    {
                        data: "pedReseller",
                        title: "Ped. Revenda",
                        className: "text-center",
                        render: (data: string) =>
                            `<div style="text-align: center;">${data ?? "-"}</div>`,
                    },
                    {
                        data: "pedCustomer",
                        title: "Ped. Cliente",
                        className: "text-center",
                        render: (data: string) =>
                            `<div style="text-align: center;">${data ?? "-"}</div>`,
                    },
                    {
                        data: "pedAdobe",
                        title: "Ped. Adobe",
                        className: "text-center",
                        render: (data: string) =>
                            `<div style="text-align: center;">${data ?? "-"}</div>`,
                    },
                    {
                        data: "status",
                        title: "Status",
                        className: "text-center",
                        render: (data: string) => {
                            let statusText = "";

                            switch (data) {
                                case "Draft":
                                    statusText = "Rascunho";
                                    break;
                                case "CreditAnalysis":
                                    statusText = "Em Análise de Crédito";
                                    break;
                                case "CreditDenied":
                                    statusText = "Crédito Negado";
                                    break;
                                case "FinancialRefused":
                                    statusText = "Recusado Financeiro";
                                    break;
                                case "WaitingSendingAdobe":
                                    statusText = "Aguardando Envio Adobe";
                                    break;
                                case "Processed":
                                    statusText = "Processado";
                                    break;
                                case "WaitingSendingBilling":
                                    statusText = "Aguardando Faturamento";
                                    break;
                                case "Billed":
                                    statusText = "Faturado";
                                    break;
                                case "ErrorSendingAdobe":
                                    statusText = "Erro no Envio Adobe";
                                    break;
                                default:
                                    statusText = "Desconhecido";
                                    break;
                            }

                            return `<div style="text-align: center;">${statusText}</div>`;
                        },
                    }
                ]}
            >
            </DataTable>
        </Card>
    );
};