import { DTData } from "../../models/DTData";
import { CreateUserDto, EditUserDto, User } from "../../models/User";
import apiClient, { jsonConfig } from "./ApiClient";

export const getUsers = async (
  page: number,
  size: number,
  user?: string,
  reseller?: number,
  profile?: number
): Promise<DTData<User>> => {
  let complementUrl = "";
  if (user) complementUrl += "&User=" + user;
  if (reseller) complementUrl += "&ResellerId=" + reseller;
  if (profile) complementUrl += "&ProfileId=" + profile;

  let url = `/users?Page=${page}&Size=${size}${complementUrl}`;
  let { data } = await apiClient.get<any>(url);

  let users: User[] = data.content ?? [];
  let total = data.total;
  let length = data.content.length;

  users.sort((a, b) => a.name.localeCompare(b.name));

  const response: DTData<User> = {
    data: users,
    recordsTotal: total,
    recordsFiltered: length,
  };

  return response;
};

export const getUserById = async (id: BigInt): Promise<User> => {
  let response = await apiClient.get(`/Users/${id}`);

  return response.data as User;
};

export const addUser = async (user: CreateUserDto): Promise<string> => {
  return new Promise(async (res, rej) => {
    await apiClient
      .put("/Users", user, jsonConfig)
      .then(() => {
        res("");
      })
      .catch((error: string) => {
        rej(error);
      });
  });
};

export const editUser = async (user: EditUserDto): Promise<void> => {
  console.log(user.resellerId === 0);
  if (user.resellerId === 0) {
    user.resellerId = null;
  }

  await apiClient.post("/Users", user, jsonConfig);
};
