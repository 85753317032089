import qs from "qs";

import { AxiosError } from "axios";
import { Order, OrderCreation, OrderFinish } from "../../models/Order";

import apiClient, { jsonConfig } from "./ApiClient";
import { AddOrderItem } from "../../models/OrderItem";
import { BaseListResponse } from "../../models/Base";
import { OrderHistoryFilterRequest } from "../../pages/Orders/OrdersHistory/OrdersHistoryList";
import { OrderHistoryResponse } from "../../models/OrderHistory";
import {
  ProductOrderPrice,
  ProductOrderRefreshedPrices,
} from "../../models/Product";
import { orderStatus } from "../../models/enums/orderStatus";
import { orderType } from "../../models/enums/orderType";

export const getOrderById = async (
  id: BigInt | number | undefined
): Promise<Order> => {
  if (!id) throw new Error("[id] não pode ser nulo!");

  const { data: response } = await apiClient.get<Order>(`orders/${id}`);
  return response;
};

export const putNewOrder = async (order: OrderCreation): Promise<Order> => {
  const { data } = await apiClient.put<Order>("orders", order, jsonConfig);

  return data;
};

export const patchOrderItems = async (
  orderId: number,
  orderItems: AddOrderItem[]
): Promise<void> => {
  const url = `orders/${orderId}/order-items`;
  await apiClient.patch(url, orderItems, jsonConfig);
};

export const postFinishOrder = async (
  orderId: number,
  order: OrderFinish
): Promise<void> => {
  const url = `orders/${orderId}/finish`;
  await apiClient.post(url, order, jsonConfig);
};

export const refreshProductOrderPrice = async (
  data: ProductOrderPrice
): Promise<ProductOrderRefreshedPrices> => {
  const result = await apiClient.post<ProductOrderRefreshedPrices>(
    "/orders/refresh-product-order-prices",
    data,
    jsonConfig
  );

  return result.data;
};

export const getOrdersHistory = async (
  filters: OrderHistoryFilterRequest
): Promise<BaseListResponse<OrderHistoryResponse> | null> => {
  if (filters.status === orderStatus.All) filters.status = null;

  if (filters.type === orderType.All) filters.type = null;

  const queryString = qs.stringify(filters);
  const url = `/orders/history?${queryString}`;

  try {
    let response = await apiClient.get<BaseListResponse<OrderHistoryResponse>>(
      url
    );
    return response.data as BaseListResponse<OrderHistoryResponse>;
  } catch (error) {
    throw new Error("Não foi possível carregar os pedidos");
  }
};

export const approveCreditAnalysis = async (
  orderId: number,
  comments: string
): Promise<void> => {
  try {
    await apiClient.patch(
      `/orders/${orderId}/credit-analysis/approve`,
      { commentaries: comments },
      jsonConfig
    );
  } catch (error) {
    throw new Error("Erro ao aprovar análise de crédito.");
  }
};

export const rejectCreditAnalysis = async (
  orderId: number,
  comments: string
): Promise<void> => {
  try {
    await apiClient.patch(
      `/orders/${orderId}/credit-analysis/reject`,
      { commentaries: comments },
      jsonConfig
    );
  } catch (error) {
    throw new Error("Erro ao reprovar análise de crédito.");
  }
};
