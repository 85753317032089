import { AxiosError } from "axios";
import apiClient, { ErrorApi } from "./ApiClient";
import { AuthUser } from "../../models/AuthUser";

interface LoginInfo {
  email: string;
  password: string;
}

export const sendLogin = async (request: LoginInfo): Promise<AuthUser> => {
  if (!request.email || !request.password) {
    throw new Error("Email e senha são obrigatórios.");
  }

  try {
    const { data } = await apiClient.post<AuthUser>("/Auth/login", request);

    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      const err = error.response?.data as ErrorApi | undefined;
      console.error("Erro ao logar:", err?.message);
    }
    throw new Error("Failed to Login.");
  }
};
