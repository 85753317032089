import React from "react";

export const LoadingSearch: React.FC = () => {
  return (
      <div className="loadSearch">
        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
  );
};
