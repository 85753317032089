import { FilterRenewList, RenewOrdersResponse } from "../../models/Renew";
import apiClient from "./ApiClient";

export const getRenewOrders = async (
  filters: FilterRenewList
): Promise<RenewOrdersResponse> => {
  let response = await apiClient.get<RenewOrdersResponse>("renewals", {
    params: filters,
  });

  return response.data;
};
