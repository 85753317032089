import React, { useState, useEffect } from "react";
import { MainTemplate } from "../../components/templates/MainTemplate";
import { Card } from "../../components/mols/Card";
import {
  fetchBillingData,
  generateBillingFile,
} from "../../services/api/BillingService";
import { useToast } from "../../components/atoms/Toast";
import { BillingTable } from "./BillingTable/BillingTable";
import { BillingFile } from "./models/BillingFile";
import { BillingFilter } from "./BillingFilter";
import { GenerateForm } from "./GenerateForm";

export const BillingReport: React.FC = () => {
  const Toast = useToast();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState("WaitingSendingAdobe");
  const [fileStartDate, setFileStartDate] = useState("");
  const [fileEndDate, setFileEndDate] = useState("");
  const [billingData, setBillingData] = useState<BillingFile[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);

  const handleGenerateCSV = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      billingData
        .map(
          (file) =>
            `${file.fileName};${file.startDate};${file.endDate};${
              file.fileSize ? `${file.fileSize} KB` : "N/A"
            }`
        )
        .join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "relatorio_faturamento.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownload = (fileUrl: string) => {
    if (fileUrl) {
      window.open(fileUrl, "_blank");
    } else {
      Toast.fire({
        icon: "error",
        title: "Arquivo não disponível para download.",
      });
    }
  };

  const loadData = async (page = 0) => {
    try {
      const filters = {
        startDate: fileStartDate,
        endDate: fileEndDate,
        page: page,
        size: pageSize,
      };
      const response = await fetchBillingData(filters);
      setBillingData(response.content);
      setTotalPages(response.totalPages);
      setCurrentPage(page);
    } catch (error) {
      console.error("Erro ao carregar dados de faturamento:", error);
      Toast.fire({
        icon: "error",
        title: "Erro ao carregar dados de faturamento.",
      });
    }
  };

  const handleFilter = () => {
    setCurrentPage(0);
    loadData(0);
  };

  const handleGenerateBillingFile = async () => {
    if (!startDate || !endDate || !status) {
      Toast.fire({
        icon: "warning",
        title:
          "Por favor, preencha todos os campos obrigatórios para gerar o arquivo de faturamento.",
      });
      return;
    }

    try {
      const data = {
        startDate: new Date(startDate).toISOString(),
        endDate: new Date(endDate).toISOString(),
        status,
      };
      const response = await generateBillingFile(data);
      Toast.fire({
        icon: "success",
        title: "Arquivo de faturamento gerado com sucesso!",
      });
      console.log(response);
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: "Erro ao gerar o arquivo de faturamento.",
      });
      console.error("Erro ao enviar a requisição:", error);
    }
  };

  useEffect(() => {
    loadData(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <MainTemplate>
      <Card className="mb-3">
        <h1 className="h3">Gerar arquivo de faturamento</h1>

        <GenerateForm
          startDate={startDate}
          endDate={endDate}
          status={status}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setStatus={setStatus}
          onGenerateFile={handleGenerateBillingFile}
        />
      </Card>

      <Card className="mb-3">
        <h3 className="h5">Filtros do Relatório de Arquivos</h3>

        <BillingFilter
          fileStartDate={fileStartDate}
          fileEndDate={fileEndDate}
          setFileStartDate={setFileStartDate}
          setFileEndDate={setFileEndDate}
          onFilter={handleFilter}
        />
      </Card>

      <Card>
        <h3 className="h5">Relatório de Arquivos</h3>

        <BillingTable
          billingData={billingData}
          currentPage={currentPage}
          totalPages={totalPages}
          onDownload={handleDownload}
          setCurrentPage={setCurrentPage}
        />
      </Card>
    </MainTemplate>
  );
};
