import React, { useState } from "react";
import { Input } from "../../../components/atoms/Input";
import { Icon } from "../../../components/atoms/Icon";
import { useForm } from "../../../hooks/formHook";

interface SearchProductProps {
  onFilterChange: (filter: string) => void;
}

interface FormData {
  searchTerm: string;
}

export const SearchProduct: React.FC<SearchProductProps> = ({
  onFilterChange,
}) => {
  const [formData, setFormData] = useState<FormData>({ searchTerm: "" });
  const { handleInputChange } = useForm<FormData>({ setFormData });

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    onFilterChange(formData.searchTerm);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col col-10">
          <Input
            placeholder="Pesquisar produto ou SKU"
            id="searchTerm"
            value={formData.searchTerm}
            onChange={handleInputChange}
          />
        </div>
        <div className="col col-2">
          <button type="submit" className="btn btn-primary d-block w-100 my-2">
            Pesquisar <Icon className="fa fa-fw fa-filter" />
          </button>
        </div>
      </div>
    </form>
  );
};
