import React from "react";
import { MainTemplate } from "../templates/MainTemplate";
import { Loader } from "../atoms/Loader";

export const Loading: React.FC = () => {
  return (
    <MainTemplate>
      <div className="load">
        <Loader />
      </div>
    </MainTemplate>
  );
};
