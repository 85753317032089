import React from "react";

interface TextAreaProps {
  id?: string;
  label?: string;
  placeholder?: string;
  value?: string | Date | null;
  mask?: string | Array<string | RegExp>;
  required?: boolean;
  pattern?: string;
  error?: string;
  disabled?: boolean;
  rows?: number;
  maskChar?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
}

export const TextArea: React.FC<TextAreaProps> = ({
  id,
  label,
  placeholder,
  value,
  required,
  pattern,
  error,
  disabled,
  rows,
  onChange,
  onKeyDown,
}) => {
  const valueStr = value?.toString() || "";

  return (
    <div className="form-group">
      {label && <label htmlFor={id}>{label}</label>}
      <textarea
        id={id}
        className="form-control my-2"
        placeholder={placeholder}
        value={valueStr}
        onChange={onChange}
        onKeyDown={onKeyDown}
        autoComplete="off"
        required={required}
        disabled={disabled}
        rows={rows}
      />
      <div className="invalid-feedback">{error}</div>
    </div>
  );
};
