import React from "react";

interface CardProps {
  id?: string;
  className?: string;
  bodyClass?: string;
  children?: React.ReactNode;
}

export const Card: React.FC<CardProps> = ({
  id,
  className: customClass,
  bodyClass,
  children,
}) => {
  const className = customClass ? `card ${customClass}` : "card";
  const bodyClassName = bodyClass ? `card-body ${bodyClass}` : "card-body";

  return (
    <section className={className} id={id}>
      <div className={bodyClassName}>{children}</div>
    </section>
  );
};
