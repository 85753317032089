import { AuthUser } from "../models/AuthUser";

export const getAuthUser = (): AuthUser | null => {
  const storedAuthUser = localStorage.getItem("authUser");

  if (!storedAuthUser) throw new Error("Sua sessão expirou!");

  try {
    return JSON.parse(storedAuthUser) as AuthUser;
  } catch (error) {
    throw new Error(`Erro ao analisar o authUser. ${error}`);
  }
};
