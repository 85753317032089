import React, { useEffect, useRef, useState } from "react";
import { Card } from "../../../components/mols/Card";
import ptbr from "../../../assets/lib/js/dataTables/pt-br.json";
import DataTable, { DataTableRef } from "datatables.net-react";
import DT from "datatables.net-dt";
import { formatMoneyBRL, formatMoneyUSD } from "../../../utils/currency";
import { OrderItem } from "../../../models/OrderItem";
import { Order } from "../../../models/Order";

DataTable.use(DT);

export interface OrderStatusDataTableProps {
  order: Order;
  data: OrderItem[];
}

export const OrderStatusDataTable: React.FC<OrderStatusDataTableProps> = ({
  order,
  data,
}) => {
  const [orderItems, setOrderItems] = useState(data);
  const dataTableRef = useRef<DataTableRef>(null);

  useEffect(() => {
    setOrderItems(data);
  }, [data]);

  return (
    <Card className="mb-3">
      <h2 className="h5">Produtos</h2>

      <DataTable
        ref={dataTableRef}
        className="table table-sm table-striped"
        options={{
          dom: "lt",
          language: ptbr,
          data: orderItems ?? [],
          lengthMenu: [[512], [512]],
          ordering: false,
          serverSide: false,
          processing: true,
          lengthChange: false,
        }}
        columns={[
          {
            title: "#",
            data: null,
            className: "text-center",
            render: (data: any, type: any, row: any, meta: any) =>
              `<div>${meta.row + 1}</div>`,
          },
          { data: "product.license", title: "Produto" },
          {
            data: "product.sku",
            title: "SKU",
          },
          {
            data: "quantity",
            title: "Qtd.",
            className: "text-center",
          },
          {
            data: "resellerPriceUSD",
            title: "Unit. Tab US$",
            className: "border-start text-end",
            render: (data: number) => formatMoneyUSD(data),
          },
          {
            title: "Total US$",
            className: "border-end text-end",
            data: (data: OrderItem) =>
              formatMoneyUSD(data.quantity * data.resellerPriceUSD),
          },
          {
            data: "invoicePriceUSD",
            title: "Unit. Tab US$",
            className: "border-start text-end",
            render: (data: number) => formatMoneyUSD(data),
          },
          {
            data: "totalInvoicePriceUSD",
            title: "Total US$",
            className: "border-end text-end",
            render: (data: number) => formatMoneyUSD(data),
          },
          {
            data: "invoicePriceBRL",
            title: "Unit. Tab R$",
            className: "border-start text-end",
            render: (data: number) => formatMoneyBRL(data),
          },
          {
            data: "totalInvoicePriceBRL",
            title: "Total R$",
            className: "border-end text-end",
            render: (data: number) => formatMoneyBRL(data),
          },
        ]}
      >
        <thead>
          <tr>
            <th rowSpan={2}>#</th>
            <th rowSpan={2}>Produto</th>
            <th rowSpan={2}>SKU</th>
            <th rowSpan={2} className="text-center">
              Qtd.
            </th>
            <th colSpan={2} className="text-center border-start border-end">
              Revenda
            </th>
            <th colSpan={4} className="text-center border-start border-end">
              Faturamento
            </th>
          </tr>
          <tr>
            <th className="border-start text-center">Unit. Tab U$</th>
            <th className="border-end text-center">Total U$</th>
            <th className="border-start text-center">Unit. Tab U$</th>
            <th className="border-end text-center">Total U$</th>
            <th className="border-start text-center">Unit. Tab R$</th>
            <th className="border-end text-center">Total R$</th>
          </tr>
        </thead>
        <tfoot>
          <tr>
            <th colSpan={4}>&nbsp;</th>
            <th className="text-end border-start">Total:</th>
            <th className="text-center boder-end">
              {formatMoneyUSD(order.resellerTotalPriceUSD)}
            </th>
            <th className="border-start">&nbsp;</th>
            <th className="text-center border-end">
              {formatMoneyUSD(order.invoiceTotalPriceUSD)}
            </th>
            <th className="border-start">&nbsp;</th>
            <th className="text-center border-end">
              {formatMoneyBRL(order.invoiceTotalPriceBRL)}
            </th>
          </tr>
        </tfoot>
      </DataTable>
    </Card>
  );
};
