import { CreateUserDto, EditUserDto, User } from "../../../models/User";

export interface UserForm {
  id?: BigInt | number;
  resellerId: string;
  profileId: string;
  name: string;
  email: string;
  activated: boolean;
}

export const toCreateUserDto = (formData: UserForm): CreateUserDto => {
  const { resellerId, profileId, ...data } = formData;
  const isReseller = resellerId != "0";

  if (profileId == "0")
    throw new Error("É necessário selecionar um perfil para o usuário!");
  if (data.name.trim() == "") throw new Error("Nome é um campo obrigatório!");
  if (data.email.trim() == "")
    throw new Error("E-mail é um campo obrigatório!");

  const user: CreateUserDto = {
    isReseller: isReseller,
    resellerId: isReseller ? Number(resellerId) : null,
    profileId: Number(profileId),
    ...data,
  };

  return user;
};

export const toEditUserDto = (formData: UserForm): EditUserDto => {
  const { profileId,resellerId, id, name, activated } = formData;
  if (name.trim() == "") throw new Error("Nome é um campo obrigatório!");

  const user: EditUserDto = {
    id: Number(id) ?? 0,
    profileId: Number(profileId),
    resellerId: Number(resellerId),
    name,
    activated, 
  };

  return user;
};

export const toUserForm = (user: User): UserForm => {
  const { id, resellerId, profileId, name, email,activated } = user;

  const userForm: UserForm = {
    id: id,
    resellerId: resellerId?.toString() ?? "0",
    profileId: profileId?.toString() ?? "0",
    name: name,
    email: email,
    activated: activated, 
  };

  return userForm;
};
