import React, { useCallback, useMemo, useRef, useState } from "react";
import { Input } from "../components/atoms/Input";
import { useToast } from "../components/atoms/Toast";
import { useAuth } from "../context/AuthContext";
import { Link } from "react-router-dom";
import "../assets/styles/css/login.css";

const Login: React.FC = () => {
  const Toast = useToast();
  const { login } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formRef = useRef<HTMLFormElement | null>(null);

  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState("");

  const [password, setPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState("");

  const classError = useMemo(() => {
    return errorEmail || errorPassword ? "was-validated" : "";
  }, [errorEmail, errorPassword]);

  const isValid = useCallback((): boolean => {
    let isValid = true;

    const elements = formRef.current?.elements;
    if (!elements) return true;

    const emailRef = elements.namedItem("txtEmail") as HTMLInputElement;
    const passwordRef = elements.namedItem("txtPassword") as HTMLInputElement;

    if (emailRef.validity.valueMissing) {
      setErrorEmail("E-mail obrigatório");
      isValid = false;
    } else if (emailRef.validity.patternMismatch) {
      setErrorEmail("E-mail inválido");
      isValid = false;
    }

    if (passwordRef.validity.valueMissing) {
      setErrorPassword("Senha obrigatório");
      isValid = false;
    }

    return isValid;
  }, []);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!isValid()) {
        Toast.fire({
          icon: "warning",
          title: "Por favor verifique os campos.",
        });
        return;
      }

      try {
        setIsLoading(true);
        await login(email, password);
      } catch (error) {
        Toast.fire({
          icon: "error",
          title: "Credenciais inválidas",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [Toast, email, isValid, login, password]
  );

  return (
    <div className="login-background">
      <div className="container d-flex justify-content-center align-items-center vh-100">
        <form
          ref={formRef}
          className={`card p-4 col-4 needs-validation ${classError} login-card`}
          onSubmit={handleSubmit}
          noValidate
        >
          <h2 className="text-center">Bem-vindo</h2>
          <Input
            id="txtEmail"
            placeholder="E-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            pattern="[a-zA-Z0-9.]+@[a-zA-Z0-9]+\.[a-zA-Z]+(\.([a-zA-Z]+))?$"
            error={errorEmail}
          />
          <Input
            id="txtPassword"
            placeholder="Senha"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            error={errorPassword}
          />
          {!isLoading && (
            <Link
              to="/forgot-password"
              className="forgot-password my-2 text-end"
            >
              Esqueceu sua senha?
            </Link>
          )}

          <button
            type="submit"
            className="btn btn-primary login-button w-100"
            disabled={isLoading}
          >
            {isLoading && (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            Acessar
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
