import React, { useCallback, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Input } from "../../components/atoms/Input";
import { useToast } from "../../components/atoms/Toast";

import { resetPassword } from "../../services/PasswordService";

import "../../assets/styles/css/login.css";

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const Toast = useToast();

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const classError = useMemo(() => {
    return error ? 'was-validated' : '';
  }, [error]);

  const isValid = useCallback((event: React.FormEvent<HTMLFormElement>): boolean => {
    let isValid = true;

    const form = event.currentTarget;

    const inputRef = form.elements.namedItem('txtEmail') as HTMLInputElement;
    if (inputRef.validity.valueMissing) {
      setError("E-mail obrigatório");
      isValid = false;
    } 

    if (inputRef.validity.patternMismatch) {
      setError("E-mail inválido");
      isValid = false;
    }

    return isValid;
  }, []);

  const handleSubmit = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
    if (!isValid(event)) {
      Toast.fire({
        icon: "warning",
        title: "Por favor verifique os campos.",
      });
      return;
    }

    try {
      await resetPassword(email);

      Toast.fire({
        icon: "success",
        title: "Se o email estiver cadastrado, você receberá instruções para recuperação de senha!",
      });

      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: "Erro ao solicitar a recuperação de senha.",
      });
    }
  }, [Toast, email, isValid, navigate]);

  return (
    <div className="login-background">
      <div className="container d-flex justify-content-center align-items-center vh-100">
          <form className={
            `card p-4 col-4 needs-validation ${classError} login-card`
          } 
            onSubmit={handleSubmit} noValidate
          >
            <h2 className="text-center">Solicitar nova senha</h2>
            <Input
              type="email"
              id="txtEmail"
              placeholder="E-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              pattern="[a-z0-9.]+@[a-z0-9]+\.[a-z]+(\.([a-z]+))?$"
              error={error}
            />

            <button
              type="submit"
              className="btn btn-primary login-button my-3"
            >
              Enviar
            </button>

            <Link 
              to="/"
              className="btn btn-secondary"
            >
              Voltar
            </Link>
          </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
