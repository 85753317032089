import React, { useState } from "react";
import { OrderItem } from "../../../../models/OrderItem";
import { FormQuantity } from "./FormQuantity";
import { FormPrice } from "./FormPrice";
import { Loader } from "../../../../components/atoms/Loader";

interface ModalBodyProps {
  item: OrderItem;
  quantity: number;
  totalBRL: number;
  type: "quantity" | "price";
  isFinishingCart: boolean;
  onChangeQuantity: (value: number) => void;
  onChangePrice: (value: number) => void;
  onSave: () => Promise<void>;
  onClose: () => void;
}

export const ModalBody: React.FC<ModalBodyProps> = ({
  item,
  quantity,
  totalBRL,
  type,
  isFinishingCart,
  onChangeQuantity,
  onChangePrice,
  onSave,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = () => {
    setIsLoading(true);
    onSave().finally(() => {
      setIsLoading(false);
    });
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <p>
        <b>Produto:</b> {item.product.license}
        <br />
        <b>SKU:</b> {item.product.sku}
      </p>
      <div className="hr"></div>
      <div className="row">
        <div className="col col-12">
          {type === "price" ? (
            <FormPrice value={totalBRL} onChange={onChangePrice} />
          ) : (
            <FormQuantity value={quantity} onChange={onChangeQuantity} />
          )}
        </div>
      </div>

      <p className="text-muted">
        Se você alterar a quantidade de algum produto, o valor total de todo o
        carrinho será recalculado.
      </p>

      <div className="d-flex justify-content-center gap-2">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={handleClose}
          disabled={isFinishingCart || isLoading}
        >
          Cancelar {(isFinishingCart || isLoading) && <Loader />}
        </button>
        <button
          type="submit"
          className="btn btn-primary"
          onClick={handleSubmit}
          disabled={isFinishingCart || isLoading}
        >
          Salvar alterações {(isFinishingCart || isLoading) && <Loader />}
        </button>
      </div>
    </>
  );
};
