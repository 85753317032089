import React from "react";
import { CurrencyInput } from "react-currency-mask";

interface FormPriceProps {
  value: number;
  onChange: (value: number) => void;
}

export const FormPrice: React.FC<FormPriceProps> = ({ value, onChange }) => {
  const handleBlur = (evt: any, originalValue: any, maskedValue: any) => {
    onChange(originalValue);
  };

  return (
    <div className="form-group">
      <label htmlFor="totalBRL">Total R$</label>

      <CurrencyInput
        onChangeValue={() => {}} // This shit is required, but it breaks the code '-'
        onBlur={handleBlur}
        defaultValue={value}
        InputElement={<input className="form-control my-2" id="totalBRL" />}
      />
    </div>
  );
};
