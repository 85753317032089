export const states = [
  { id: "ac", text: "AC" },
  { id: "al", text: "AL" },
  { id: "ap", text: "AP" },
  { id: "am", text: "AM" },
  { id: "ba", text: "BA" },
  { id: "ce", text: "CE" },
  { id: "df", text: "DF" },
  { id: "es", text: "ES" },
  { id: "go", text: "GO" },
  { id: "ma", text: "MA" },
  { id: "mt", text: "MT" },
  { id: "ms", text: "MS" },
  { id: "mg", text: "MG" },
  { id: "pa", text: "PA" },
  { id: "pb", text: "PB" },
  { id: "pr", text: "PR" },
  { id: "pe", text: "PE" },
  { id: "pi", text: "PI" },
  { id: "rj", text: "RJ" },
  { id: "rn", text: "RN" },
  { id: "rs", text: "RS" },
  { id: "ro", text: "RO" },
  { id: "rr", text: "RR" },
  { id: "sc", text: "SC" },
  { id: "sp", text: "SP" },
  { id: "se", text: "SE" },
  { id: "to", text: "TO" },
];
