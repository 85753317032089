import Swal, {
  SweetAlertIcon,
  SweetAlertOptions,
  SweetAlertResult,
} from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const toastTimer: number = 3000;

export type Toast = {
  fire: (options: SweetAlertOptions) => Promise<SweetAlertResult>;
};

export function useToast(): Toast {
  const MySwal = withReactContent(Swal);

  const toast = MySwal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: toastTimer,
    timerProgressBar: true,
    didOpen: (toastElement: HTMLElement) => {
      toastElement.addEventListener("mouseenter", Swal.stopTimer);
      toastElement.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  return {
    fire: (options: SweetAlertOptions) => toast.fire(options),
  };
}

export function toastAndRedirect(
  toast: Toast,
  icon: SweetAlertIcon,
  title: string,
  href: string,
  text?: string
): void {
  toast
    .fire({
      icon,
      title,
      text,
    })
    .then(() => {
      setTimeout(() => {
        window.location.href = href;
      }, toastTimer);
    });
}
