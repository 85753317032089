import React, { useEffect, useState } from "react";
import { Accordion } from "../../../components/mols/Accordion";
import { AccordionItem } from "../../../components/atoms/AccordionItem";
import { Input } from "../../../components/atoms/Input";
import { Checkbox } from "../../../components/atoms/Checkbox";
import { activities } from "../models/activities";
import { Icon } from "../../../components/atoms/Icon";
import { Select } from "../../../components/atoms/Select";
import { phoneTypes } from "../../../models/enums/PhoneTypes";
import { states } from "../../../models/enums/States";
import { accountLevel } from "../../../models/enums/AccountLevel";
import { ResellerForm } from "../models/ResellerForm";
import { getByCnpj } from "../../../services/api/ResellerService";
import { Toast, useToast } from "../../../components/atoms/Toast";

interface ResellerFormProps {
  title: string;
  formData: ResellerForm;
  setFormData: React.Dispatch<React.SetStateAction<ResellerForm>>
  submitForm: (e: any) => void;
  handleInputChange: (e: any) => void;
  handleCheckboxChange: (e: any) => void;
  isLoading?: boolean;
}

export const ResellerFormTemplate: React.FC<ResellerFormProps> = ({
  title,
  formData,
  setFormData,
  submitForm,
  handleInputChange,
  handleCheckboxChange,
  isLoading,
}) => {
  const toast = useToast();
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const handleCnpjChange = async () => {
    try {
      setIsLoadingSearch(true);

      const response = await getByCnpj(formData.cnpj);
      
      setFormData(form => ({
        ...form,
        corporateName: response.nome,
        zipCode: response.cep,
        city: response.municipio,
        state: response.uf,
        street: response.logradouro,
        neighborhood: response.bairro,
        number: response.numero,
        addressComplement: response.complemento,
      }));
    } catch (error) {
      toast.fire({ icon: "error", title: "Erro ao buscar informações do CNPJ!" });
    } finally {
      setIsLoadingSearch(false);
    }
  };

  useEffect(() => {
    if (isFocused && formData.cnpj.length > 13) {
      handleCnpjChange();
    }
  }, [formData.cnpj]);

  return (
    <form onSubmit={submitForm}>
      <h1 className="h3">{title}</h1>
      <Accordion id="reseller-accordion" alwaysOpen>
        <AccordionItem header="Dados da Revenda" id={1} show>
          <div className="row">
            <div className="col col-sm-6 col-xs-12 col-md-4">
              <Input
                id="cnpj"
                label="CNPJ *"
                placeholder="00.000.000/0000-00"
                onChange={(e) => setFormData(form => ({ ...form, cnpj: e.target.value.replace(/\D/g, "").trim() }))}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                value={formData.cnpj}
                mask="99.999.999/9999-99"
              />
            </div>
            <div className="col col-sm-6 col-xs-12 col-md-4">
              <Input
                id="stateRegistration"
                label="Inscrição Estadual"
                placeholder="00000000000000"
                onChange={handleInputChange}
                value={formData.stateRegistration}
              />
            </div>
            <div className="col col-12 col-md-4">
              <Input
                id="municipalRegistration"
                label="Inscrição Municipal"
                placeholder="00000000000000"
                onChange={handleInputChange}
                value={formData.municipalRegistration}
              />
            </div>
          </div>

          {formData.resellerId && (
            <div className="row">
              <div className="col col-12 col-md-4">
                <Input
                  id="resellerId"
                  label="Reseller ID"
                  value={formData.resellerId}
                  disabled
                />
              </div>
            </div>
          )}

          <div className="row">
            <div className="col col-sm-6 col-xs-12 col-md-6">
              <Input
                id="corporateName"
                label="Razão Social *"
                onChange={handleInputChange}
                value={formData.corporateName}
              />
            </div>
            <div className="col col-sm-6 col-xs-12 col-md-6">
              <Input
                id="tradeName"
                label="Nome Fantasia *"
                onChange={handleInputChange}
                value={formData.tradeName}
              />
            </div>
          </div>

          <div className="row">
            <div className="col col-md-8 col-12">
              <Checkbox
                label="Segmento *"
                id="activity"
                options={activities}
                onChange={handleCheckboxChange}
                value={formData.activity}
              />
            </div>
            <div className="col col-sm-6 col-xs-12 col-md-4">
              <Input
                id="membership"
                label="Membership *"
                onChange={handleInputChange}
                value={formData.membership}
              />
            </div>

            <div className="col col-sm-6 col-xs-12 col-md-6">
              <Select
                label="Nível da conta *"
                id="accountLevel"
                options={accountLevel}
                onChange={handleInputChange}
                value={formData.accountLevel}
              />
            </div>

            <div className="col col-sm-6 col-xs-12 col-md-6">
              <Input
                label="Data de expiração *"
                id="expirationDate"
                type="date"
                onChange={handleInputChange}
                value={formData.expirationDate}
              />
            </div>
          </div>
        </AccordionItem>

        <AccordionItem header="Endereço" id={2} show>
          <div className="row">
            <div className="col col-sm-6 col-xs-12 col-md-2">
              <Input
                label="CEP *"
                id="zipCode"
                placeholder="00.000-000"
                mask="99.999-999"
                onChange={handleInputChange}
                value={formData.zipCode}
              />
            </div>
            <div className="col col-sm-6 col-xs-12 col-md-4">
              <Input
                label="Cidade *"
                id="city"
                onChange={handleInputChange}
                value={formData.city}
              />
            </div>
            <div className="col col-sm-6 col-xs-12 col-md-2">
              <Select
                label="UF *"
                id="state"
                options={states}
                onChange={handleInputChange}
                value={formData.state}
              />
            </div>
            <div className="col col-sm-6 col-xs-12 col-md-4">
              <Input
                label="Logradouro"
                id="street"
                onChange={handleInputChange}
                value={formData.street}
              />
            </div>
          </div>

          <div className="row">
            <div className="col col-sm-8 col-xs-12 col-md-5">
              <Input
                label="Bairro *"
                id="neighborhood"
                onChange={handleInputChange}
                value={formData.neighborhood}
              />
            </div>
            <div className="col col-sm-4 col-xs-12 col-md-2">
              <Input
                label="Número *"
                id="number"
                onChange={handleInputChange}
                value={formData.number}
              />
            </div>
            <div className="col col-12 col-md-5">
              <Input
                label="Complemento"
                id="addressComplement"
                onChange={handleInputChange}
                value={formData.addressComplement}
              />
            </div>
          </div>
        </AccordionItem>

        <AccordionItem header="Contato" id={3} show>
          <div className="row">
            <div className="col col-12">
              <strong>Responsável</strong>
            </div>
            <div className="col col-sm-6 col-xs-12 col-md-4">
              <Input
                label="Nome Completo *"
                id="contactName"
                onChange={handleInputChange}
                value={formData.contactName}
              />
            </div>
            <div className="col col-sm-6 col-xs-12 col-md-2">
              <Select
                label="Tipo *"
                id="phoneType"
                options={phoneTypes}
                onChange={handleInputChange}
                value={formData.phoneType}
              />
            </div>
            <div className="col col-sm-6 col-xs-12 col-md-3">
              <Input
                label="Telefone *"
                id="contactPhone"
                placeholder="(00) 00000-0000"
                mask="(99) 99999-9999"
                onChange={handleInputChange}
                value={formData.contactPhone}
              />
            </div>
            <div className="col col-sm-6 col-xs-12 col-md-3">
              <Input
                label="Email *"
                id="contactEmail"
                type="email"
                onChange={handleInputChange}
                value={formData.contactEmail}
              />
            </div>
          </div>
        </AccordionItem>
      </Accordion>

      <button
        className="btn btn-primary btn-lg rounded float-rb"
        type="submit"
        style={{ width: "3em", height: "3em" }}
        disabled={isLoading}
      >
        {isLoading ? (
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        ) : (
          <Icon className="fa fa-fw fa-save" />
        )}
      </button>
    </form>
  );
};