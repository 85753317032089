import { MainTemplate } from "../../components/templates/MainTemplate";
import { Input } from "../../components/atoms/Input";
import DataTable from "datatables.net-react";
import DT from "datatables.net-dt";
import "datatables.net-bs5";
import React, { useCallback, useEffect, useState } from "react";
import { Card } from "../../components/mols/Card";
import { toastAndRedirect, useToast } from "../../components/atoms/Toast";
import { Accordion } from "../../components/mols/Accordion";
import { AccordionItem } from "../../components/atoms/AccordionItem";
import { Select } from "../../components/atoms/Select";
import { SwitchBtn } from "../../components/atoms/SwitchBtn";
import { Icon } from "../../components/atoms/Icon";
import { addCustomer, getByCnpj } from "../../services/api/CustomerService";
import { useForm } from "../../hooks/formHook";
import { CustomerForm, toCreateCustomerDto } from "./models/CustomerForm";
import { CustomerContacts } from "./components/CustomerContacts";
import { LoadingSearch } from "../../components/orgs/LoadingSearch";
import { contactTypes } from "./customerEnum/ContactTypes";
import { duration } from "./customerEnum/Duration";
import { segments } from "./customerEnum/Segments";
import { states } from "./customerEnum/State";
import { typeClassProduc } from "./customerEnum/TypeClassProduct";
import Autocomplete from "../../components/atoms/Autocomplete";
import { Reseller } from "../../models/Reseller";
import { autocompleteResellers } from "../../services/api/ResellerService";
import { AddressForm, useValidateAddressLength } from "../../hooks/useValidateAddressLength";

DataTable.use(DT);

const NewCustomer: React.FC = () => {
  const toast = useToast();
  const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false);
  const isReseller = JSON.parse(localStorage.getItem("authUser")!).profile.isReseller;
  const [resellerId, setResellerId] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { validateAddressLength } = useValidateAddressLength();

  const [formData, setFormData] = useState<CustomerForm>({
    customerId: "",
    cpfcnpj: "",
    name: "",
    adobeName: "",
    isJuridicPerson: true,
    country: "",
    cep: "",
    city: "",
    state: "",
    address: "",
    neighborhood: "",
    number: "",
    adressComplement: "",
    isThreeYears: false,
    segment: "",
    adobeStatus: "",
    durationContract: "",
    typeClassProduct: "",
    resellerId: "",

    signingResponsibleName: "",
    signingResponsibleTypePhone: "",
    signingResponsibleNumber: "",
    signingResponsibleEmail: "",

    financialResponsibleName: "",
    financialResponsibleTypePhone: "",
    financialResponsibleNumber: "",
    financialResponsibleEmail: "",

    purchasingResponsibleName: "",
    purchasingResponsibleTypePhone: "",
    purchasingResponsibleNumber: "",
    purchasingResponsibleEmail: "",
  });

  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const [isFocused, setIsFocused] = useState(false);

  const { handleInputChange, handleError } = useForm<CustomerForm>({
    setFormData,
  });

  const handleSelectChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    handleInputChange(e);
    const { id } = e.target;
    const newValue = (e.target as HTMLSelectElement).selectedOptions[0].id;
    setFormData((prevData) => ({
      ...prevData,
      [id]: newValue,
    }));
  };

  const FillInFieldsCnpj = async () => {
    try {
      setIsLoadingSearch(true);
      const response = await getByCnpj(formData.cpfcnpj);

      setFormData(form => ({
        ...form,
        name: response.nome,
        country: "Brasil",
        cep: response.cep,
        city: response.municipio,
        state: response.uf,
        address: response.logradouro,
        neighborhood: response.bairro,
        number: response.numero,
        adressComplement: response.complemento,
      }));
    } catch (error) {
      handleError(error, toast, "buscar informação do CNPJ");
    } finally {
      setIsLoadingSearch(false);
    }
  };

  useEffect(() => {
    if (isFocused && formData.cpfcnpj.length > 13 && formData.isJuridicPerson) {
      FillInFieldsCnpj();
    }
  }, [formData.cpfcnpj]);

  const validateForm = (): boolean => {
    const requiredFields = [
      { id: "cpfcnpj", label: "CNPJ" },
      { id: "name", label: "Nome ou Razão Social" },
      { id: "adobeName", label: "Nome Adobe" },
      { id: "cep", label: "CEP" },
      { id: "city", label: "Cidade" },
      { id: "state", label: "UF" },
      { id: "address", label: "Logradouro" },
      { id: "neighborhood", label: "Bairro" },
      { id: "number", label: "Número" },
      {
        id: "signingResponsibleName",
        label: "Nome do Responsável pela Assinatura",
      },
      {
        id: "signingResponsibleTypePhone",
        label: "Tipo de Telefone do Responsável pela Assinatura",
      },
      {
        id: "signingResponsibleNumber",
        label: "Telefone do Responsável pela Assinatura",
      },
      {
        id: "signingResponsibleEmail",
        label: "Email do Responsável pela Assinatura",
      },
      { id: "segment", label: "Segmento" },
      { id: "durationContract", label: "Duração" },
      { id: "typeClassProduct", label: "Tipo" },
    ];

    const newErrors: Record<string, boolean> = {};
    let isValid = true;

    for (const field of requiredFields) {
      const fieldValue = formData[field.id as keyof CustomerForm];
      if (!fieldValue && field.id !== "customerId") {
        newErrors[field.id] = true;
        isValid = false;
        toast.fire({
          icon: "warning",
          title: `O campo ${field.label} é obrigatório.`,
        });
        break;
      }

      if (field.id.includes("ResponsibleName") && fieldValue) {
        if (typeof fieldValue === "string") {
          const nameParts = fieldValue.trim().split(" ");
          if (nameParts.length < 2) {
            newErrors[field.id] = true;
            isValid = false;
            toast.fire({
              icon: "warning",
              title: `O campo ${field.label} deve conter nome e sobrenome.`,
            });
          }
        }
      }
    }

    if (
      !isReseller &&
      (resellerId === undefined ||
        resellerId?.length === 0 ||
        parseInt(resellerId) === 0)
    ) {
      toast.fire({
        icon: "warning",
        title: `O campo Revenda é obrigatório.`,
      });
    }

    const addressForm: AddressForm = {
      address: formData.address,
      number: formData.number,
      neighborhood: formData.neighborhood,
      addressComplement: formData.adressComplement
    };

    if (!validateAddressLength(addressForm)) {
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const submitForm = async (evt: React.FormEvent) => {
    evt.preventDefault();

    if (!validateForm()) return;

    try {
      setIsLoading(true);
      const customers = toCreateCustomerDto(formData);
      customers.resellerId = parseInt(resellerId);

      await addCustomer(customers);
      toastAndRedirect(
        toast,
        "success",
        "Customer criado",
        "/customers",
        "Você será redirecionado para a tela de customers em 3s"
      );
    } catch (ex) {
      if (ex.status == 406) {
        toast.fire({
          icon: "warning",
          title: ex.response.data.message,
        });
      } else {
        handleError(ex, toast, "criar customers");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const loadResellers = useCallback(
    async (search: string): Promise<Reseller[]> => {
      return await autocompleteResellers(search);
    }, []
  );

  const handleSwitchChange = (checked: boolean) => {
    setFormData({ ...formData, isJuridicPerson: checked });
  };  

  return (
    <MainTemplate>
      <Card>
        {isLoadingSearch ? <LoadingSearch /> : <b></b>}
        <form onSubmit={submitForm}>
          <h1 className="h3">Novo cliente</h1>
          <Accordion id="customer-accordion" alwaysOpen>
            <AccordionItem header="Dados do cliente" id={1} show key="cliente">
              <div className="row">
                {!isReseller && (
                  <div className="col col-6">
                    <label>Revenda *</label>
                    <Autocomplete<Reseller>
                      id="resellerId"
                      placeholder="Revenda"
                      dataFetcher={loadResellers}
                      keyField="tradeName"
                      onSelect={(data: Reseller) => {
                        const id = data?.id.toString() ?? "";
                        setResellerId(id);
                      }}
                      onEmptyResult={() => {
                        setResellerId("");
                      }}
                      onClear={() => {
                        setResellerId("");
                      }}
                    />
                  </div>
                )}

                <div className="col col-3">
                  <SwitchBtn
                    id="isJuridicPerson"
                    label="Pessoa Jurídica?"
                    checked={formData.isJuridicPerson}
                    onChange={handleSwitchChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col col-3">
                  <Input
                    label={formData.isJuridicPerson ? "CNPJ *" : "CPF *"}
                    id="cpfcnpj"
                    placeholder={
                      formData.isJuridicPerson
                        ? "00.000.000/0000-00"
                        : "000.000.000-00"
                    }
                    type="text"
                    mask={
                      formData.isJuridicPerson
                        ? "99.999.999/9999-99"
                        : "999.999.999-99"
                    }
                    onChange={(e) => setFormData(form => ({...form, cpfcnpj: e.target.value.replace(/\D/g, "").trim()}))}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    value={formData.cpfcnpj}
                  />
                </div>
                <div className="col col-5">
                  <Input
                    label="Nome ou Razão Social *"
                    id="name"
                    placeholder="Nome completo ou Razão Social"
                    type="text"
                    onChange={handleInputChange}
                    value={formData.name}
                  />
                </div>
                <div className="col col-4">
                  <Input
                    label="Nome Adobe *"
                    id="adobeName"
                    placeholder="Nome que aparece na Adobe"
                    type="text"
                    onChange={handleInputChange}
                    value={formData.adobeName}
                  />
                </div>
              </div>
            </AccordionItem>

            <AccordionItem header="Endereço" id={2} show key="endereco">
              <div className="row">
                <div className="col col-2">
                  <Input
                    label="CEP *"
                    id="cep"
                    placeholder="00.000-000"
                    type="text"
                    onChange={handleInputChange}
                    value={formData.cep}
                  />
                </div>
                <div className="col col-4">
                  <Input
                    label="Cidade *"
                    id="city"
                    placeholder=""
                    type="text"
                    onChange={handleInputChange}
                    value={formData.city}
                  />
                </div>
                <div className="col col-2">
                  <Select
                    id="state"
                    label="UF *"
                    options={states}
                    onChange={handleSelectChange}
                    value={formData.state}
                  />
                </div>
                <div className="col col-4">
                  <Input
                    label="Logradouro *"
                    id="address"
                    placeholder=""
                    type="text"
                    onChange={handleInputChange}
                    value={formData.address}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col col-5">
                  <Input
                    label="Bairro *"
                    id="neighborhood"
                    placeholder=""
                    type="text"
                    onChange={handleInputChange}
                    value={formData.neighborhood}
                  />
                </div>
                <div className="col col-2">
                  <Input
                    label="Número *"
                    id="number"
                    placeholder=""
                    type="text"
                    onChange={handleInputChange}
                    value={formData.number}
                  />
                </div>
                <div className="col col-5">
                  <Input
                    label="Complemento"
                    id="adressComplement"
                    placeholder=""
                    type="text"
                    onChange={handleInputChange}
                    value={formData.adressComplement}
                  />
                </div>
              </div>
            </AccordionItem>

            <AccordionItem header="Contato" id={3} key="contato">
              <CustomerContacts
                handleInputChange={handleInputChange}
                handleSelectChange={handleSelectChange}
                formData={formData}
                contactTypes={contactTypes}
              />
            </AccordionItem>

            <AccordionItem header="Assinatura" id={4} key="assinatura">
              <div className="row">
                <div className="col col-4">
                  <Select
                    id="segment"
                    label="Segmento *"
                    options={segments}
                    onChange={handleSelectChange}
                    value={formData.segment}
                  />
                </div>
                <div className="col col-3">
                  <Select
                    id="durationContract"
                    label="Duração *"
                    options={duration}
                    onChange={handleSelectChange}
                    value={formData.durationContract}
                  />
                </div>
                <div className="col col-4">
                  <Select
                    id="typeClassProduct"
                    label="Tipo *"
                    options={typeClassProduc}
                    onChange={handleSelectChange}
                    value={formData.typeClassProduct}
                  />
                </div>
                <div className="col col-1">
                  <SwitchBtn
                    id="threeYear"
                    label="3 Year"
                    checked={formData.isThreeYears}
                    onChange={(checked) =>
                      setFormData({ ...formData, isThreeYears: checked })
                    }
                  />
                </div>
              </div>
            </AccordionItem>
          </Accordion>

          <button
            className="btn btn-primary btn-lg rounded float-rb"
            style={{ width: "3em", height: "3em" }}
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              <Icon className="fa fa-fw fa-save" />
            )}
          </button>
        </form>
      </Card>
    </MainTemplate>
  );
};

export { NewCustomer };
