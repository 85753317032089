import React, { Dispatch, SetStateAction } from "react";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

export const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  setCurrentPage,
}) => {
  return (
    <div className="row">
      <div className="col-12 d-flex justify-content-center">
        <div className="dt-paging">
          <nav aria-label="pagination">
            <ul className="pagination">
              <li
                className={`dt-paging-button page-item ${
                  currentPage === 0 ? "disabled" : ""
                }`}
              >
                <button
                  className="page-link previous"
                  onClick={() =>
                    setCurrentPage((prev) => Math.max(prev - 1, 0))
                  }
                >
                  Anterior
                </button>
              </li>
              {[...Array(totalPages)].map((_, index) => (
                <li
                  className={`dt-paging-button page-item ${
                    index === currentPage ? "active" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => setCurrentPage(index)}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
              <li
                className={`dt-paging-button page-item ${
                  currentPage === totalPages - 1 ? "disabled" : ""
                }`}
              >
                <button
                  className="page-link next"
                  onClick={() =>
                    setCurrentPage((prev) => Math.min(prev + 1, totalPages - 1))
                  }
                >
                  Próxima
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};
