import React, { useEffect, useState } from "react";
import { MainTemplate } from "../../components/templates/MainTemplate";
import { Card } from "../../components/mols/Card";
import {
  convertToEditResellerDto,
  convertToResellerForm,
} from "./models/ResellerForm";
import {
  editReseller,
  getResellerById 
} from "../../services/api/ResellerService";
import { useResellerForm } from "./hooks/useResellerForm";
import { toastAndRedirect, useToast } from "../../components/atoms/Toast";
import { useParams } from "react-router-dom";
import { ResellerFormTemplate } from "./templates/ResellerFormTemplate";
import { Loading } from "../../components/orgs/Loading";
import { AddressForm, useValidateAddressLength } from "../../hooks/useValidateAddressLength";

export const EditReseller: React.FC = () => {
  const { id: idStr } = useParams();
  const id = BigInt(idStr ?? 0);
  const toast = useToast();
  const { validateAddressLength } = useValidateAddressLength();

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false); 
  const {
    formData,
    setFormData,
    handleInputChange,
    handleCheckboxChange,
    handleError,
  } = useResellerForm();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const reseller = await getResellerById(id);
        const resellerForm = {
          ...convertToResellerForm(reseller),
          expirationDate: reseller.expirationDate
            ? reseller.expirationDate.split("T")[0]
            : "",
        };
        setFormData(resellerForm);
      } catch (error) {
        handleError(error, toast);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const submitForm = async (evt: React.FormEvent) => {
    evt.preventDefault();

    const addressForm: AddressForm = {
      address: formData.street,
      number: formData.number,
      neighborhood: formData.neighborhood,
      addressComplement: formData.addressComplement
    };

    if (!validateAddressLength(addressForm)) {
      return;
    }

    try {
      setIsSubmitting(true);
      const reseller = convertToEditResellerDto(formData);
      await editReseller(reseller);
      toastAndRedirect(
        toast,
        "success",
        "Revenda editada",
        "/resellers",
        "Você será redirecionado para a tela de revendas em 3s"
      );
    } catch (ex) {
      handleError(ex, toast);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <MainTemplate>
      <Card>
        <ResellerFormTemplate
          title="Editar Revenda"
          formData={formData}
          setFormData={setFormData}
          handleCheckboxChange={handleCheckboxChange}
          handleInputChange={handleInputChange}
          submitForm={submitForm}
          isLoading={isSubmitting}
        />
      </Card>
    </MainTemplate>
  );
};
