import React from "react";
import { OptionsProps } from "../../models/OptionsProps";

interface SelectProps {
  id?: string;
  label?: string;
  value?: string | number;
  options: OptionsProps[];
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

export const Select: React.FC<SelectProps> = ({
  id,
  label,
  value,
  options,
  disabled,
  onChange,
}) => {
  const mappedOptions = options.map((opt, i) => (
    <option
      key={opt.id ?? `ind-${i}`}
      {...(opt.id && { id: opt.id })}
      value={opt.id}
      {...((!opt.id || opt.disabled) && { disabled: true })}
    >
      {opt.text}
    </option>
  ));

  return (
    <div className="form-group">
      {label && <label {...(id && { htmlFor: id })}>{label}</label>}
      <select
        id={id}
        className="form-select my-2"
        onChange={onChange}
        disabled={disabled}
        value={value || "0"}
      >
        {mappedOptions}
      </select>
    </div>
  );
};