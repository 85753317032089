import React from "react";
import { AccordionItemProps } from "../atoms/AccordionItem";

interface AccordionProps {
  children: React.ReactElement<AccordionItemProps>[];
  id: string;
  alwaysOpen?: boolean;
}

export const Accordion: React.FC<AccordionProps> = ({
  children,
  id,
  alwaysOpen = false,
}) => {
  const modifiedChildren = alwaysOpen
    ? children
    : React.Children.map(children, (child) => {
        if (React.isValidElement<AccordionItemProps>(child)) {
          return React.cloneElement(child, { parentId: id });
        }
      });

  return (
    <div className="accordion" id={id}>
      {modifiedChildren}
    </div>
  );
};
