import React, { useState } from "react";
import { MainTemplate } from "../components/templates/MainTemplate";

const Home: React.FC = () => {
  return (
    <MainTemplate>

    </MainTemplate>
  );
};

export default Home;
