import moment from "moment";
import { BillingFor, billingForMap } from "../../../models/enums/BillingFor";
import { orderStatus, orderStatusMap } from "../../../models/enums/orderStatus";
import { getPaymentConditionById } from "../../../models/enums/PaymentCondition";
import { Order, OrderFinish } from "../../../models/Order";
import { getAuthUser } from "../../../utils/authUtils";
import { OrderFinishItem, OrderItem } from "../../../models/OrderItem";
import { storage } from "../../../utils/storage";

export interface CartFormData {
  id: string;
  isRenewal: boolean;
  pedCustomer: string | null;
  pedReseller: string | null;
  billingFor?: string;
  paymentCondition?: string;
  dateCreated: string;
  status?: string;
  adobeOrderId: string | null;
  customerId?: BigInt | number;
  customer?: string;
  prorata?: string;
  resellerId?: BigInt | number;
  reseller?: string;
  anniversary: string;
  hubspotLink?: string;
}

export const newCartFormData = (): CartFormData => {
  const billingFor = storage.getStr("billingFor") ?? "Reseller";

  return {
    id: "",
    isRenewal: false,
    pedCustomer: "",
    pedReseller: "",
    billingFor: billingFor,
    paymentCondition: "",
    dateCreated: "",
    status: "",
    adobeOrderId: "",
    customer: "",
    prorata: "0",
    reseller: "",
    anniversary: "",
  };
};

export const toCartFormData = (
  prevData: CartFormData,
  order: Order,
  resetData: boolean
): CartFormData => {
  let billingFor: string | undefined;
  let paymentCondition: string | undefined;
  let status: string | undefined;
  let dateCreated: string = "";
  let anniversary: string = "";
  let pedCustomer: string = "";
  let pedReseller: string = "";

  const { customer, reseller } = order;

  if (resetData) {
    const billingForOpts =
      billingForMap[order.billingFor ?? BillingFor.Reseller];
    billingFor = billingForOpts?.id ?? prevData.billingFor;

    const paymentConditionOpts = getPaymentConditionById(
      order.paymentCondition ?? "Cash"
    );
    paymentCondition = paymentConditionOpts?.id ?? prevData.paymentCondition;

    const statusOpts = orderStatusMap[order.status ?? orderStatus.Draft];
    status = statusOpts?.id ?? prevData.status;
    dateCreated = moment(order.dateCreated ?? Date.now()).format("DD/MM/YYYY");
    anniversary = customer?.cotermDate
      ? moment(customer?.cotermDate).format("DD/MM/YYYY")
      : "";
    pedCustomer = order.pedCustomer ?? "";
    pedReseller = order.pedReseller ?? "";
  } else {
    billingFor = prevData.billingFor;
    paymentCondition = prevData.paymentCondition;
    status = prevData.status;
    dateCreated = prevData.dateCreated;
    anniversary = prevData.anniversary;
    pedCustomer = prevData.pedCustomer ?? "";
    pedReseller = prevData.pedReseller ?? "";
  }

  const formData: CartFormData = {
    id: order.id.toString(),
    isRenewal: order.isRenewal,
    pedCustomer: pedCustomer,
    pedReseller: pedReseller,
    billingFor: billingFor,
    paymentCondition: paymentCondition,
    dateCreated: dateCreated,
    status: status,
    adobeOrderId: order.adobeOrderId,
    customer: customer?.name,
    customerId: order.customerId,
    prorata: customer?.proRata?.toString(),
    reseller: reseller?.resellerId
      ? `${reseller?.resellerId} - ${reseller?.corporateName}`
      : reseller?.corporateName,
    anniversary: anniversary,
  };

  return formData;
};

export const toOrderFinish = (
  orderId: number,
  cart: CartFormData,
  orderItems: OrderItem[]
): OrderFinish => {
  if (!cart.pedCustomer)
    throw new Error("[Ped. Cliente] é um campo obrigatório!");
  if (!cart.pedReseller)
    throw new Error("[Ped. Revenda] é um campo obrigatório!");
  if (!cart.billingFor)
    throw new Error("[Faturar para] é um campo obrigatório!");
  if (!cart.paymentCondition)
    throw new Error("[Coondições de faturamento] é um campo obrigatório!");

  const authUser = getAuthUser()?.userId ?? 0;

  const items: OrderFinishItem[] = orderItems.map((item) => {
    return { ...item };
  });

  return {
    id: orderId,
    customerId: cart.customerId ?? 0,
    isRenewal: cart.isRenewal,
    pedCustomer: cart.pedCustomer,
    pedReseller: cart.pedReseller,
    billingFor: cart.billingFor,
    paymentCondition: cart.paymentCondition,
    userId: authUser,
    items: items,
  };
};
