import React, { useMemo } from "react";
import { formatMoneyBRL, formatMoneyUSD } from "../../../utils/currency";
import { OrderItem } from "../../../models/OrderItem";

interface TableDetailRmaItemProps {
  index: number;
  data: OrderItem;
  allowCancel: boolean;
  onChecked(item: OrderItem, checked: boolean): void;
}

const TableDetailRmaItem: React.FC<TableDetailRmaItemProps> = ({
  index,
  data,
  allowCancel = false,
  onChecked,
}) => {
  const resellerTotalPriceUSD = useMemo((): number => {
    return data.resellerPriceUSD * data.quantity;
  }, [data.quantity, data.resellerPriceUSD]);

  const invoiceTotalPriceUSD = useMemo(() => {
    return data.invoicePriceUSD * data.quantity;
  }, [data.quantity, data.invoicePriceUSD]);

  const invoiceTotalPriceBRL = useMemo(() => {
    return data.invoicePriceBRL * data.quantity;
  }, [data.quantity, data.invoicePriceBRL]);

  return (
    <tr>
      <td style={{ textAlign: "center" }}>{index}</td>
      <td>{data.product.license}</td>
      <td style={{ textAlign: "center" }}>{data.product.sku}</td>
      <td style={{ textAlign: "center" }}>{data.quantity}</td>
      <td className="border-start" style={{ textAlign: "center" }}>
        {formatMoneyUSD(data.resellerPriceUSD)}
      </td>
      <td className="border-end" style={{ textAlign: "center" }}>
        {formatMoneyUSD(resellerTotalPriceUSD)}
      </td>
      <td className="border-start" style={{ textAlign: "center" }}>
        {formatMoneyUSD(data.invoicePriceUSD)}
      </td>
      <td className="border-end" style={{ textAlign: "center" }}>
        {formatMoneyUSD(invoiceTotalPriceUSD)}
      </td>
      <td className="border-start" style={{ textAlign: "center" }}>
        {formatMoneyBRL(data.invoicePriceBRL)}
      </td>
      <td className="border-end" style={{ textAlign: "center" }}>
        {formatMoneyBRL(invoiceTotalPriceBRL)}
      </td>
      <td style={{ textAlign: "center" }}>
        {allowCancel ? (
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              onChange={(e) => onChecked(data, e.target.checked)}
              checked
              readOnly
            />
          </div>
        ) : (
          <>&nbsp;</>
        )}
      </td>
    </tr >
  );  
};

export default TableDetailRmaItem;
