import { Icon } from "../../../components/atoms/Icon";
import { Input } from "../../../components/atoms/Input";
import { CustomerFilters } from "../../../models/CustomerFilters";
import { Select } from "../../../components/atoms/Select";
import { AdobeStatus, adobeStatusMap } from "../../../models/enums/AdobeStatus";
import Autocomplete from "../../../components/atoms/Autocomplete";
import { Reseller } from "../../../models/Reseller";

interface CustomerListFiltersProps {
  isReseller: boolean;
  filters: CustomerFilters;
  loadResellers: any;
  setFilters: React.Dispatch<React.SetStateAction<CustomerFilters>>;
  onInputChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  onFilter: () => void;
}

export const CustomerListFilters: React.FC<CustomerListFiltersProps> = ({
  isReseller,
  filters,
  loadResellers,
  setFilters,
  onInputChange,
  onFilter,
}) => {
  const setResellerId = (id: string): void => {
    setFilters((prev) => {
      return { ...prev, resellerId: id };
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onFilter();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col col-3">
          <Input
            label="Data inicial - Aniversário"
            type="date"
            id="startCotermDate"
            value={filters.startCotermDate}
            onChange={onInputChange}
          />
        </div>
        <div className="col col-3">
          <Input
            label="Data final - Aniversário"
            type="date"
            id="endCotermDate"
            value={filters.endCotermDate}
            onChange={onInputChange}
          />
        </div>
        <div className="col col-3">
          <Select
            label="Status"
            id="status"
            options={[
              { id: "0", text: "Todos" },
              { id: AdobeStatus.Processing, text: "Processando" },
              { id: AdobeStatus.Pending, text: "Pendente" },
              { id: AdobeStatus.Completed, text: "Completo" },
              { id: AdobeStatus.Failed, text: "Falha" }]}
            value={filters.status?.toString()}
            onChange={onInputChange}
          />
        </div>
        <div className="col col-3">
          <Input
            label="Pro rata"
            type="number"
            id="prorataValue"
            value={filters.prorataValue}
            onChange={onInputChange}
          />
        </div>
      </div>
      <div className="row">
        <div className="col col-6">
          <Input
            label="Cliente"
            type="text"
            id="customer"
            placeholder="Pesquise pelo nome, CNPJ ou CustomerID do cliente"
            value={filters.customer}
            onChange={onInputChange}
          />
        </div>

        {!isReseller && (
          <div className="col col-6">
            <Autocomplete<Reseller>
              id='autotomplete-resellers'
              label="Revenda"
              placeholder='Reseller'
              dataFetcher={loadResellers}
              keyField="tradeName"
              onSelect={(data: Reseller) => {
                const id = data?.id ?? null;
                setResellerId(id.toString());
              }}
            />
          </div>
        )}
      </div>

      <div className="d-flex justify-content-end gap-3">
        <button className="btn btn-secondary" id="clearFilter" type="reset" onClick={() => {
          setFilters({
            startCotermDate: "",
            endCotermDate: "",
            status: "",
            prorataValue: "",
            customer: "",
            resellerId: ""
          })
        }}>
          Limpar Filtro
        </button>
        <button className="btn btn-primary" type="submit">
          Filtrar <Icon className="fa fa-fw fa-filter" />
        </button>
      </div>
    </form>
  );
};
