import { OptionsProps } from "../OptionsProps";

export enum RmaStatus {
  Availlable,
  WaitingSendingAdobe,
  PartialProcessed,
  Processed,
  Failed,
}

export const rmaStatusMap: { [key in RmaStatus]: OptionsProps } = {
  [RmaStatus.Availlable]: { id: "Availlable", text: "Disponível" },
  [RmaStatus.WaitingSendingAdobe]: {
    id: "WaitingSendingAdobe",
    text: "Aguardando Envio Adobe",
  },
  [RmaStatus.PartialProcessed]: {
    id: "PartialProcessed",
    text: "Processado Parcialmente",
  },
  [RmaStatus.Processed]: { id: "Processed", text: "Processado" },
  [RmaStatus.Failed]: { id: "Failed", text: "Falhou" },
};

export const getRmaStatusById = (id: string): OptionsProps | null => {
  return Object.values(rmaStatusMap).find((rma) => rma.id === id) || null;
};
