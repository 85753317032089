import axios from "axios";

export interface ErrorApi {
  message: string
}

const apiClient = axios.create({  
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 60000,
});

apiClient.interceptors.request.use(
  config => {
    const newConfig = { ...config };
    const token = localStorage.getItem('authToken');

    if (token) {
      newConfig.headers.authorization = `Bearer ${token}`;
      newConfig.headers['Access-Control-Allow-Origin'] = 'http://localhost:3000';
      newConfig.headers['Access-Control-Allow-Credentials'] = true;      
    }

    return newConfig;
  },
  error => Promise.reject(error),
);

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);

export const jsonConfig = {
  headers: {
    "Content-Type": "application/json",
  },
};

export default apiClient;