import { MainTemplate } from "../../components/templates/MainTemplate";
import { Input } from "../../components/atoms/Input";
import DataTable, { DataTableRef } from "datatables.net-react";
import DT from "datatables.net-dt";
import "datatables.net-bs5";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useToast } from "../../components/atoms/Toast";
import { Accordion } from "../../components/mols/Accordion";
import { AccordionItem } from "../../components/atoms/AccordionItem";
import { Select } from "../../components/atoms/Select";
import { SwitchBtn } from "../../components/atoms/SwitchBtn";
import { getCustomerById } from "../../services/api/CustomerService";
import { useGetResellers } from "../../hooks/resellerHook";
import { useParams } from "react-router-dom";
import { Loading } from "../../components/orgs/Loading";
import { toOptionsProps } from "../../models/OptionsProps";
import { Customer } from "../../models/Customer";
import { format } from "date-fns";
import { CustomerContacts } from "./components/CustomerContacts";
import { CustomerOrderHistory } from "./components/CustomerOrderHistory";
import { CustomerActiveProducts } from "./components/CustomerActiveProducts";

DataTable.use(DT);

const DetailCustomer: React.FC = () => {
  const { id: idStr } = useParams();
  const id = BigInt(idStr ?? 0);

  const Toast = useToast();
  const { data: resellers } = useGetResellers();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<Customer>();

  const contactTypes = [
    { id: "0", text: "Selecione" },
    { id: "CellPhone", text: "Celular" },
    { id: "Telephone ", text: "Telephone" },
  ];

  const contactsData = useMemo(() => {
    if (!data) return null;

    return {
      signingResponsibleName:
        data.customerContacts?.find(item => item.typeContact === "Signature")?.name || "",
      signingResponsibleTypePhone:
        data.customerContacts?.find(item => item.typeContact === "Signature")?.typePhone || "",
      signingResponsibleNumber:
        data.customerContacts?.find(item => item.typeContact === "Signature")?.phoneNumber || "",
      signingResponsibleEmail:
        data.customerContacts?.find(item => item.typeContact === "Signature")?.email || "",
      financialResponsibleName:
        data.customerContacts?.find(item => item.typeContact === "Financial")?.name || "",
      financialResponsibleTypePhone:
        data.customerContacts?.find(item => item.typeContact === "Financial")?.typePhone || "",
      financialResponsibleNumber:
        data.customerContacts?.find(item => item.typeContact === "Financial")?.phoneNumber || "",
      financialResponsibleEmail:
        data.customerContacts?.find(item => item.typeContact === "Financial")?.email || "",
      purchasingResponsibleName:
        data.customerContacts?.find(item => item.typeContact === "Purchasing")?.name || "",
      purchasingResponsibleTypePhone:
        data.customerContacts?.find(item => item.typeContact === "Purchasing")?.typePhone || "",
      purchasingResponsibleNumber:
        data.customerContacts?.find(item => item.typeContact === "Purchasing")?.phoneNumber || "",
      purchasingResponsibleEmail:
        data.customerContacts?.find(item => item.typeContact === "Purchasing")?.email || ""
    };
  }, [data]);

  useEffect(() => {
    async function load() {
      try {
        setIsLoading(true);

        const customer = await getCustomerById(id);

        setData(customer);
      } catch (error) {
        if (error instanceof Error) {
          Toast.fire({
            icon: "error",
            title: error.message,
          });
        }
      } finally {
        setIsLoading(false);
      }
    }
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (isLoading) return <Loading />;

  if (!data) return null;

  return (
    <MainTemplate>
      <form>
        <Accordion id="customer-accordion" alwaysOpen>

            <AccordionItem header="Dados do cliente" id={1} show key="cliente">
              <div className="row">
                <div className="col col-6">
                  <Select
                    id="customer-resellerId"
                    label="Revenda *"
                    options={toOptionsProps(resellers, true)}
                    value={data.resellerId.toString()}
                    disabled
                  />
                </div>

                <div className="col col-3">
                  <Input
                    label="CostumerId"
                    id="customer-customerId"
                    type="text"
                    value={data.customerId}
                    disabled
                  />
                </div>

                <div className="col col-3">
                  <SwitchBtn
                    id="customer-isJuridicPerson"
                    label="Pessoa Jurídica?"
                    checked={data.isJuridicPerson}
                    disabled
                  />
                </div>
              </div>
              <div className="row">
                <div className="col col-3">
                  <Input
                    label="CPF/CNPJ"
                    id="customer-cpfcnpj"
                    placeholder="00.000.000/0000-00"
                    type="text"
                    value={data.cpfcnpj}
                    disabled
                  />
                </div>
                <div className="col col-5">
                  <Input
                    label="Nome ou Razão Social"
                    placeholder="Nome completo ou Razão Social"
                    type="text"
                    id="customer-name"
                    value={data.name}
                    disabled
                  />
                </div>
                <div className="col col-4">
                  <Input
                    label="Nome Adobe"
                    placeholder="Nome que aparece na Adobe"
                    type="text"
                    id="customer-adobeName"
                    value={data.adobeName}
                    disabled
                  />
                </div>
              </div>
            </AccordionItem>

            <AccordionItem header="Endereço" id={2} show key="endereco">
              <div className="row">
                <div className="col col-2">
                  <Input
                    label="CEP"
                    type="text"
                    mask={"99.999-999"}
                    id="address-cep"
                    value={data.cep}
                    disabled
                  />
                </div>
                <div className="col col-4">
                  <Input
                    label="Cidade"
                    type="text"
                    id="address-city"
                    value={data.city}
                    disabled
                  />
                </div>
                <div className="col col-2">
                  <Select
                    id="address-state"
                    label="UF"
                    options={[
                      { id: "0", text: "Selecione" },
                      { id: "AC", text: "AC" },
                      { id: "AL", text: "AL" },
                      { id: "AP", text: "AP" },
                      { id: "AM", text: "AM" },
                      { id: "BA", text: "BA" },
                      { id: "CE", text: "CE" },
                      { id: "DF", text: "DF" },
                      { id: "ES", text: "ES" },
                      { id: "GO", text: "GO" },
                      { id: "MA", text: "MA" },
                      { id: "MT", text: "MT" },
                      { id: "MS", text: "MS" },
                      { id: "MG", text: "MG" },
                      { id: "PA", text: "PA" },
                      { id: "PB", text: "PB" },
                      { id: "PR", text: "PR" },
                      { id: "PE", text: "PE" },
                      { id: "PI", text: "PI" },
                      { id: "RJ", text: "RJ" },
                      { id: "RN", text: "RN" },
                      { id: "RS", text: "RS" },
                      { id: "RO", text: "RO" },
                      { id: "RR", text: "RR" },
                      { id: "SC", text: "SC" },
                      { id: "SP", text: "SP" },
                      { id: "SE", text: "SE" },
                      { id: "TO", text: "TO" },
                    ]}
                    value={data.state}
                    disabled
                  />
                </div>
                <div className="col col-4">
                  <Input
                    label="Logradouro"
                    type="text"
                    id="address-address"
                    value={data.address}
                    disabled
                  />
                </div>
              </div>
              <div className="row">
                <div className="col col-5">
                  <Input
                    label="Bairro"
                    type="text"
                    id="address-neighborhood"
                    value={data.neighborhood}
                    disabled
                  />
                </div>
                <div className="col col-2">
                  <Input
                    label="Número"
                    type="text"
                    id="address-number"
                    value={data.number}
                    disabled
                  />
                </div>
                <div className="col col-5">
                  <Input
                    label="Complemento"
                    id="address-adressComplement"
                    type="text"
                    value={data.adressComplement}
                    disabled
                  />
                </div>
              </div>
            </AccordionItem>

            <AccordionItem header="Contato" id={3} key="contato">
              <CustomerContacts
                handleInputChange={() => { }}
                handleSelectChange={() => { }}
                formData={contactsData}
                contactTypes={contactTypes}
                disabled
              ></CustomerContacts>
            </AccordionItem>

            <AccordionItem header="Assinatura" id={4} key="assinatura">
            <div className="row">
              <div className="col col-4">
                <Select
                  id="assinatura-segment"
                  label="Segmento"
                  options={[
                    { id: "0", text: "Selecione" },
                    { id: "Commercial", text: "Commercial" },
                    { id: "Education", text: "Education" },
                    { id: "Government", text: "Government" },
                  ]}
                  value={data.marketSegment}
                  disabled
                />
              </div>
              <div className="col col-3">
                <Select
                  id="assinatura-duration"
                  label="Duração"
                  options={[
                    { id: "0", text: "Selecione" },
                    { id: "TwelveMonths", text: "1 ano" },
                    { id: "TwentyFourMonths", text: "2 anos" },
                    { id: "ThirtySixMonths", text: "3 anos" },
                  ]}
                  value={data.durationContract}
                  disabled
                />
              </div>
              <div className="col col-3">
                <Input
                  label="Aniversario"
                  id="assinatura-cotermDate"
                  type="text"
                  placeholder="-"
                  value={
                    data.cotermDate
                      ? new Date(data.cotermDate).toLocaleDateString('pt-BR')
                      : ""
                  }
                  disabled
                />
              </div>
              <div className="col col-2">
                <Input
                  label="Prorata"
                  id="assinatura-proRata"
                  type="text"
                  value={data.proRata.toString()}
                  disabled
                />
              </div>
            </div>
          </AccordionItem>

            <AccordionItem header="Histórico de Pedidos" id={5} key="history">
              <CustomerOrderHistory customerId={data.id!} resellerId={Number(data.resellerId)} />
            </AccordionItem>

            <AccordionItem header="Produtos Ativos" id={6} key="product">
              <CustomerActiveProducts customerId={data.id!} />
            </AccordionItem>
          
        </Accordion>
      </form>
    </MainTemplate>
  );
};

export default DetailCustomer;
