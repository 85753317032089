import React, {useState, useEffect, useCallback} from "react";
import { MainTemplate } from "../../components/templates/MainTemplate";
import { Card } from "../../components/mols/Card";
import { Icon } from "../../components/atoms/Icon";
import { Select } from "../../components/atoms/Select";
import { Input } from "../../components/atoms/Input";
import { useGetResellers } from "../../hooks/resellerHook";
import { toOptionsProps } from "../../models/OptionsProps";
import { useForm } from "../../hooks/formHook";
import { toCreateUserDto, UserForm } from "./models/UserForm";
import { toastAndRedirect, useToast } from "../../components/atoms/Toast";
import { addUser } from "../../services/api/UserService";
import { Profile } from "../../models/Profiles";
import { getProfiles } from "../../services/api/ProfileService";
import Autocomplete from "../../components/atoms/Autocomplete";
import {Reseller} from "../../models/Reseller";
import {autocompleteResellers} from "../../services/api/ResellerService";

export const NewUser: React.FC = () => {
  const toast = useToast();
  const { data: resellers, loading: isResellerLoading } = useGetResellers();
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [isProfileLoading, setIsProfileLoading] = useState<boolean>(true);
  const isReseller = JSON.parse(localStorage.getItem('authUser')!).profile.isReseller;
  const [resellerId, setResellerId] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [formData, setFormData] = useState<UserForm>({
    resellerId: "0",
    profileId: "0",
    name: "",
    email: "",
    activated: true,
  });

  const { handleInputChange, handleError } = useForm<UserForm>({ setFormData });

  useEffect(() => {
    const fetchData = async () => {
      const isReseller = formData.resellerId !== "0";
      const result: Profile[] = await getProfiles(0, 1000, isReseller);

      setProfiles(result);
      setIsProfileLoading(false);
    };

    fetchData();
  }, [formData.resellerId]);

  const submitForm = async (evt: React.FormEvent) => {
    evt.preventDefault();

    try {
      setIsLoading(true);
      const user = toCreateUserDto(formData);
      user.resellerId = parseInt(resellerId);

      await addUser(user);
      toastAndRedirect(
        toast,
        "success",
        "Usuário criado",
        "/users",
        "Você será redirecionado para a tela de usuários em 3s"
      );
    } catch (ex) {
      handleError(ex, toast, "criar usuário");
    }
    finally {
      setIsLoading(false);
    }
  };

  const loadResellers = useCallback(async (search: string): Promise<Reseller[]> => {
        return await autocompleteResellers(search);
      },
      []);

  return (
    <MainTemplate>
      <Card>
        <form onSubmit={submitForm}>
          <h1 className="h3">Cadastrar Usuário</h1>

          <div className="row">

            {!isReseller &&
                <div className="col col-sm-6 col-12">
                  <label>Revenda</label>
                  <Autocomplete<Reseller>
                      id='resellerId'
                      placeholder='Revenda'
                      dataFetcher={loadResellers}
                      keyField="tradeName"
                      onSelect={(data: Reseller) => {
                        const id = data?.id.toString() ?? '';
                        setResellerId(id);
                      }}
                      onEmptyResult={() => {
                        setResellerId('');
                      }}
                      onClear={() => {
                        setResellerId('');
                      }}
                  />
                </div>
            }

            <div className="col col-sm-6 col-12">
              <Select
                  label="Perfil"
                  options={toOptionsProps(profiles)}
                  id="profileId"
                  onChange={handleInputChange}
                  disabled={isProfileLoading}
                  value={formData.profileId}
              />
            </div>

            <div className="col col-sm-6 col-12">
              <Input
                  label="Nome *"
                  id="name"
                  onChange={handleInputChange}
                  value={formData.name}
                  required
              />
            </div>

            <div className="col col-sm-6 col-12">
              <Input
                  label="E-mail *"
                  type="email"
                  id="email"
                  onChange={handleInputChange}
                  value={formData.email}
                  required
              />
            </div>
          </div>

          <button
              className="btn btn-primary btn-lg rounded float-rb"
              style={{width: "3em", height: "3em"}}
              type="submit"
              disabled={isLoading}
          >
            {
              isLoading
                  ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> :
                  <Icon className="fa fa-fw fa-save"/>
            }
          </button>

        </form>
      </Card>
    </MainTemplate>
  );
};
