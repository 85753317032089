import React from "react";
import { Modal } from "../../../components/mols/Modal";

interface ModalRenewListProps {
  isShown: boolean;
  onConfirm(): void;
  onClose(): void;
}

export const ModalRenewList: React.FC<ModalRenewListProps> = ({
  isShown,
  onConfirm,
  onClose,
}) => {
  return (
    <Modal
      isShown={isShown}
      title="Renovação de cliente"
      body={
        <p className="mb-2">Tem certeza que deseja renovar este cliente?</p>
      }
      footer={
        <div className="w-100 d-flex justify-content-center gap-2">
          <button className="btn btn-secondary" onClick={onClose}>
            Cancelar
          </button>
          <button className="btn btn-primary" onClick={onConfirm}>
            Continuar
          </button>
        </div>
      }
      onClose={onClose}
    />
  );
};
