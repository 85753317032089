import React, { useEffect, useState } from "react";
import { MainTemplate } from "../../../components/templates/MainTemplate";
import { Card } from "../../../components/mols/Card";
import { CartHeader } from "./CartHeader";
import { CartTable } from "./CartTable/CartTable";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../../components/orgs/Loading";
import { useToast } from "../../../components/atoms/Toast";
import { useForm } from "../../../hooks/formHook";
import {
  CartFormData,
  newCartFormData,
  toCartFormData,
  toOrderFinish,
} from "../models/CartFormData";
import { OrderItem } from "../../../models/OrderItem";
import { storage } from "../../../utils/storage";
import { newOrder, Order } from "../../../models/Order";
import { CartModal } from "./CartModal/CartModal";
import { getOrderById } from "../../../services/api/OrderService";
import { ConfirmationModal } from "./ConfirmationModal";
import { Customer } from "../../../models/Customer";
import { BillingFor, billingForMap } from "../../../models/enums/BillingFor";
import {
  RefreshSkuItem,
  toRefreshSkuItemList,
} from "../../../models/RefreshSku";
import {
  dropOrder,
  editProductPrice,
  finishOrder,
  refreshCart,
  resetProductPrices,
  updateSkuList,
} from "../../../services/cartService";
import { Loader } from "../../../components/atoms/Loader";

interface OrderCartProps {}

export const OrderCart: React.FC<OrderCartProps> = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isRenewal, setIsRenewal] = useState<boolean>(true);
  const [isFinishing, setIsFinishing] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  const [order, setOrder] = useState<Order>(newOrder());
  const [formData, setFormData] = useState<CartFormData>(newCartFormData());
  const [orderId, setOrderId] = useState<number>(0);
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [selectedItem, setSelectedItem] = useState<OrderItem | null>(null);
  const [modalType, setModalType] = useState<"quantity" | "price">("quantity");

  const { handleError } = useForm({ setFormData });

  const listOrderItems = (items: OrderItem[]) => {
    const itemMap = new Map<number, OrderItem>();
    items.forEach((item) => {
      itemMap.set(item.productId, item);
    });
    return Array.from(itemMap.values()).sort((item) => item.quantity);
  };

  const updateOrder = (curOrder: Order, resetForm: boolean): void => {
    if (curOrder.id === 0) return;
    const skus = toRefreshSkuItemList(curOrder.items);

    storage.set("currentOrder", curOrder);
    storage.set("skuList", skus);

    setOrder(curOrder);
    setCustomer(curOrder?.customer);
    setIsRenewal(curOrder.isRenewal);
    setFormData((prev) => toCartFormData(prev, curOrder, resetForm));
  };

  const handleEditProductQuantity = async (
    productId: number,
    quantity: number
  ): Promise<void> => {
    try {
      setIsEditing(true);

      const prevList: RefreshSkuItem[] = toRefreshSkuItemList(order.items);
      const product: RefreshSkuItem = { productId, quantity, sku: "" };
      const curList = updateSkuList(product, prevList);

      await refreshCart(orderId, curList);

      const curOrder = await getOrderById(orderId);
      updateOrder(curOrder, false);
    } catch (error) {
      handleError(error, toast, "atualizar o carrinho");
    } finally {
      setIsEditing(false);
    }
  };

  const handleEditProductPrice = async (
    productId: number,
    quantity: number,
    invoicePriceBRL: number
  ): Promise<void> => {
    try {
      await editProductPrice(
        orderId,
        customer?.id ?? -8,
        order.items,
        productId,
        quantity,
        invoicePriceBRL
      );

      const curOrder = await getOrderById(orderId);
      updateOrder(curOrder, false);
    } catch (error) {
      handleError(error, toast, "atualizar produto");
    }
  };

  const handleResetProductPrices = async () => {
    try {
      await resetProductPrices(order.id, order.items);

      const curOrder = await getOrderById(orderId);
      updateOrder(curOrder, false);
    } catch (error) {
      handleError(error, toast, "atualizar o carrinho");
    } finally {
      setIsEditing(false);
    }
  };

  const handleSelectItem = (
    item: OrderItem,
    type: "quantity" | "price"
  ): void => {
    setSelectedItem(item);
    setModalType(type);
  };

  const handleDeleteItem = (item: OrderItem): void => {
    handleEditProductQuantity(item.productId, 0);
  };

  const handleFinishOrder = async (fromModal = false) => {
    try {
      setIsFinishing(true);

      const isForCustomer =
        formData.billingFor === BillingFor[BillingFor.Customer];
      if (!fromModal && isForCustomer) {
        setShowConfirmModal(true);
        return;
      }

      const orderCreation = toOrderFinish(orderId, formData, order.items);
      await finishOrder(orderId, orderCreation);

      toast.fire({
        icon: "success",
        title: "Pedido criado",
        text: "Você será redirecionado para o histórico de pedidos",
      });
      navigate("/orders/history");
    } catch (error) {
      handleError(error, toast, "criar seu pedido");
    } finally {
      setIsFinishing(false);
    }
  };

  const handleDropOrder = () => {
    setIsFinishing(true);
    dropOrder();
    toast.fire({
      icon: "warning",
      title: "Pedido abandonado",
      text: "Você será redirecionado para o histórico de pedidos",
    });
    navigate("/orders/history");
  };

  useEffect(() => {
    setIsLoading(true);

    const currentOrder = storage.get<Order>("currentOrder");
    const currentOrderId = currentOrder?.id ?? 0;
    setOrderId(currentOrderId);

    getOrderById(currentOrderId)
      .then((curOrder) => {
        if (!curOrder) throw new Error("Pedido não localizado.");
        updateOrder(curOrder, true);
      })
      .catch((error) => {
        handleError(error, toast, "buscar os dados do carrinho");
        navigate("/orders");
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle change Billing For
  useEffect(() => {
    storage.set("billingFor", formData.billingFor!);

    if (formData.billingFor === billingForMap[BillingFor.Customer].id) return;

    const firstProd = order.items[0];
    if (!firstProd) return;

    // handleEditProductQuantity(firstProd.productId, firstProd.quantity);
    handleResetProductPrices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.billingFor]);

  if (isLoading) return <Loading />;

  return (
    <MainTemplate>
      <CartHeader
        formData={formData}
        isRenewal={isRenewal}
        isHubspotQuote={order.isHubspotQuote}
        setFormData={setFormData}
      />

      <Card>
        <h1 className="h3">Produtos</h1>
        <CartTable
          order={order}
          items={listOrderItems(order.items)}
          isLoading={isEditing}
          isHubspotQuote={order.isHubspotQuote}
          canChangeValue={
            formData.billingFor === billingForMap[BillingFor.Customer].id
          }
          onSelectItem={handleSelectItem}
          onDeleteItem={handleDeleteItem}
        />

        <div className="d-flex justify-content-end gap-2">
          {!(isRenewal || order.isHubspotQuote) && (
            <button
              className="btn btn-secondary"
              onClick={() => navigate("/order/products")}
              disabled={isFinishing || isEditing}
            >
              Voltar {(isFinishing || isEditing) && <Loader />}
            </button>
          )}
          <button
            className="btn btn-danger"
            onClick={handleDropOrder}
            disabled={isFinishing || isEditing}
          >
            Abandonar pedido {(isFinishing || isEditing) && <Loader />}
          </button>
          <button
            className="btn btn-primary"
            onClick={() => handleFinishOrder()}
            disabled={isFinishing || isEditing}
          >
            Finalizar pedido {(isFinishing || isEditing) && <Loader />}
          </button>
        </div>
      </Card>

      <CartModal
        isShown={selectedItem !== null}
        isFinishingCart={isFinishing}
        item={selectedItem}
        quantity={selectedItem?.quantity ?? 0}
        priceBRL={selectedItem?.totalInvoicePriceBRL ?? 0}
        type={modalType}
        onEditPrice={handleEditProductPrice}
        onEditQuantity={handleEditProductQuantity}
        onClose={() => {
          setSelectedItem(null);
        }}
      />

      <ConfirmationModal
        isShown={showConfirmModal}
        customerName={customer?.name ?? ""}
        customerDocument={customer?.cpfcnpj ?? ""}
        isLoading={isFinishing}
        onSave={() => {
          handleFinishOrder(true);
        }}
        onClose={() => {
          setShowConfirmModal(false);
        }}
      />
    </MainTemplate>
  );
};
