import { Product } from "./Product";
import { RefreshedSkuItem } from "./RefreshSku";

export interface OrderItem {
  id: number;
  orderId: number;
  productId: number;
  quantity: number;
  resellerPriceUSD: number;
  resellerPriceBRL: number;
  invoicePriceUSD: number;
  invoicePriceBRL: number;
  totalInvoicePriceUSD: number;
  totalInvoicePriceBRL: number;
  product: Product;
}

export interface OrderFinishItem {
  orderId: number;
  productId: number;
  quantity: number;
  resellerPriceUSD: number;
  resellerPriceBRL: number;
  invoicePriceUSD: number;
  invoicePriceBRL: number;
}

export interface AddOrderItem {
  orderId: number;
  productId: number;
  quantity: number;
  resellerPriceUSD: number;
  resellerPriceBRL: number;
  invoicePriceUSD: number;
  invoicePriceBRL: number;
  totalInvoicePriceUSD: number;
  totalInvoicePriceBRL: number;
}

export const toAddOrderItem = (
  orderId: number,
  product: RefreshedSkuItem
): AddOrderItem => {
  const { quantity, resellerPriceBRL, resellerPriceUSD } = product;

  const orderItem: AddOrderItem = {
    orderId: orderId,
    productId: product.productId,
    quantity: quantity,
    resellerPriceUSD: resellerPriceUSD,
    invoicePriceUSD: resellerPriceUSD,
    totalInvoicePriceUSD: resellerPriceUSD * quantity,
    resellerPriceBRL: resellerPriceBRL,
    invoicePriceBRL: resellerPriceBRL,
    totalInvoicePriceBRL: resellerPriceBRL * quantity,
  };

  return orderItem;
};

export const newOrderItem = (): OrderItem => {
  return {
    id: 0,
    orderId: 0,
    productId: 0,
    quantity: 0,
    resellerPriceUSD: 0,
    resellerPriceBRL: 0,
    invoicePriceUSD: 0,
    invoicePriceBRL: 0,
    totalInvoicePriceUSD: 0,
    totalInvoicePriceBRL: 0,
    product: {
      id: 0,
      sku: "",
      license: "",
      version: "",
      platform: "",
      configuration: "",
      language: "",
      detail1: "",
      detail2: "",
      duration: "",
      numberOfUsers: "",
      licenseLevel: "",
      points: 0,
      validityStart: null,
      validityEnd: null,
      resellerPriceUSD: 0,
      resellerPriceBRL: 0,
      fobCostMonthlyPriceUSD: 0,
      active: false,
    },
  };
};
