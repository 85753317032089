import React, { createContext, useContext, useState, useCallback } from "react";
import { sendLogin } from "../services/api/LoginService";
import { AxiosError } from "axios";
import { AuthUser } from "../models/AuthUser";
import { storage } from "../utils/storage";
import { dropOrder } from "../services/cartService";
import { useNavigate } from "react-router-dom";

interface AuthContextType {
  authUser: AuthUser | null;
  isAuthenticated: boolean;
  isFirstLogin?: boolean;
  isReseller?: boolean;
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
  passwordChanged(): void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();

  const [authUser] = useState<AuthUser | null>(
    storage.get<AuthUser>("authUser") ?? null
  );
  const [isReseller] = useState<boolean>(authUser?.profile.isReseller ?? false);
  const [isFirstLogin, setIsFirstLogin] = useState<boolean>(
    authUser?.firstLogin ?? false
  );
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => {
    const token = localStorage.getItem("authToken");
    if (!token) return false;

    return !!token;
  });

  const login = async (username: string, password: string) => {
    try {
      const user = await sendLogin({ email: username, password });

      if (!user) throw new Error("Falha ao obter o token.");

      localStorage.setItem("authToken", user.token);
      localStorage.setItem("authUser", JSON.stringify(user));

      setIsFirstLogin(user.firstLogin);

      setIsAuthenticated(true);
    } catch (error) {
      if (error instanceof AxiosError && error.status === 401) {
        throw new Error("Usuário ou senha incorretos.");
      } else {
        console.error("Erro ao autenticar:", error);
        throw new Error("Erro inesperado. Tente novamente.");
      }
    } finally {
      navigate("/");
    }
  };

  const logout = useCallback(() => {
    storage.delete("authToken");
    storage.delete("authUser");

    dropOrder();
    setIsAuthenticated(false);

    navigate("/");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const passwordChanged = useCallback(() => {
    logout();
  }, [logout]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        login,
        logout,
        isFirstLogin,
        authUser,
        passwordChanged,
        isReseller,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth deve ser usado dentro de um AuthProvider");
  }
  return context;
};
