import { DTData } from "../../models/DTData";
import { toLicenseLevel } from "../../models/enums/LicenseLevel";
import { toTransactionLevel } from "../../models/enums/TransactionLevel";
import { CustomerProduct } from "../../models/Product";
import { ProductImport } from "../../models/ProductImport";
import apiClient, { jsonConfig } from "./ApiClient";
import {
  RefreshedSku,
  RefreshSku,
  RefreshSkuItem,
} from "../../models/RefreshSku";
import { handleAxiosError } from "../../utils/handleAxiosError";

interface ProductImportation {
  file?: File | null;
}

export const sendProduct = async (
  request: ProductImportation
): Promise<void> => {
  if (!request.file) return;

  const formData = new FormData();
  formData.append("file", request.file);

  const apiConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const { data, status } = await apiClient.post<any>(
    "/Products/upload-importation-file",
    formData,
    apiConfig
  );

  if (status !== 200) {
    throw new Error("Erro desconhecido");
  }

  return data;
};

export const getProductImports = async (
  page: number,
  size: number
): Promise<DTData<ProductImport>> => {
  let { data } = await apiClient.get<any>(
    `/Products/Importations?Page=${page}&Size=${size}`
  );

  let total = data.total;
  let length = data.content.length;
  const importations: ProductImport[] = data.content ?? [];

  return {
    data: importations,
    recordsTotal: total,
    recordsFiltered: length,
  };
};

export const getProductsByCustomer = async (
  customerId: BigInt | number,
  page: number,
  size: number,
  licenseLevel: string | null = null,
  transactionLevel: string | null = null,
  filter?: string
): Promise<DTData<CustomerProduct>> => {
  let url = `/customers/${customerId}/products?page=${page}&size=${size}`;

  if (filter) url += `&filter=${filter}`;
  if (licenseLevel) url += `&licenseLevel=${toLicenseLevel(licenseLevel)}`;
  if (transactionLevel)
    url += `&transactionLevel=${toTransactionLevel(transactionLevel)}`;

  const response = await apiClient.get(url);
  const { content, total } = response.data;

  return {
    data: content,
    recordsFiltered: content.length,
    recordsTotal: total,
  };
};

export const refreshProducts = async (
  orderId: number,
  product: RefreshSkuItem | RefreshSkuItem[]
): Promise<RefreshedSku> => {
  try {
    const url = "products/refresh-products";
    let data: RefreshSku = {
      orderId: orderId,
      products: Array.isArray(product) ? product : [product],
    };

    const response = await apiClient.post<RefreshedSku>(url, data, jsonConfig);
    return response.data;
  } catch (error) {
    throw handleAxiosError(error);
  }
};
