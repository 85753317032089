import { OptionsProps } from "../OptionsProps";

export enum orderStatus {
  All= -1,
  Draft,
  CreditAnalysis,
  CreditDenied,
  FinancialRefused,
  WaitingSendingAdobe,
  Processed,
  WaitingSendingBilling,
  Billed,
  ErrorSendingAdobe,
}

export const orderStatusMap: { [key in orderStatus]: OptionsProps } = {
  [orderStatus.All]: { id: "-1", text: "Todos" },
  [orderStatus.Draft]: { id: "Draft", text: "Rascunho" },
  [orderStatus.CreditAnalysis]: { id: "CreditAnalysis", text: "An�lise de Cr�dito" },
  [orderStatus.CreditDenied]: { id: "CreditDenied", text: "Cr�dito Negado" },
  [orderStatus.FinancialRefused]: { id: "FinancialRefused", text: "Recusado Financeiro" },
  [orderStatus.WaitingSendingAdobe]: { id: "WaitingSendingAdobe", text: "Aguardando Envio Adobe" },
  [orderStatus.Processed]: { id: "Processed", text: "Processado" },
  [orderStatus.WaitingSendingBilling]: { id: "WaitingSendingBilling", text: "Aguardando Envio Faturamento" },
  [orderStatus.Billed]: { id: "Billed", text: "Faturado" },
  [orderStatus.ErrorSendingAdobe]: { id: "ErrorSendingAdobe", text: "Erro no Envio para Adobe" },
};