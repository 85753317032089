import { Profile } from "../../models/Profiles";
import apiClient from "./ApiClient";

export const getProfiles = async (
  page: number,
  size: number,
  isReseller: boolean | undefined = undefined
): Promise<Profile[]> => {
  let url = `/Profiles?Page=${page}&Size=${size}`;

  if (isReseller !== undefined) url = `${url}&isReseller=${isReseller}`;

  let response = await apiClient.get<any>(url);
  let profiles = response.data.content;

  return profiles ?? [];
};
