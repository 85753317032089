import { useState, useRef } from "react";
import { renderToString } from "react-dom/server";
import { MainTemplate } from "../../components/templates/MainTemplate";
import { Card } from "../../components/mols/Card";
import { Icon } from "../../components/atoms/Icon";
import { Input } from "../../components/atoms/Input";
import { Select } from "../../components/atoms/Select";
import { resellerStatus } from "../../models/enums/ResellerStatus";
import { useDataTable } from "../../hooks/useDataTables";
import { Config } from "datatables.net-dt";
import ptbr from "../../assets/lib/js/dataTables/pt-br.json"; 
import { useGetFilteredResellers } from "../../hooks/useGetFilteredResellers";

import 'datatables.net-bs5';
import 'datatables.net-responsive';
import 'datatables.net-buttons-bs5';
import $ from "jquery";

import JSZip from 'jszip';
// @ts-ignore
window.JSZip = JSZip;

export const ResellerList: React.FC = () => {
  const [resellerFilter, setResellerFilter] = useState<string>("");
  const [statusFilter, setStatusFilter] = useState<string>("");

  const { data: resellers, loading, error } = useGetFilteredResellers(resellerFilter, statusFilter  === "0" ? "" : statusFilter);

  const tableRef = useRef<any>(null);

  const columns = [
    {
      data: "resellerId",
      title: "Reseller ID", 
      width: "10%", 
    },
    { data: "corporateName" },
    { data: "tradeName" },
    { data: "contactName" },
    {
      data: "status",
      render: (status: string) => {
        return resellerStatus.find((st) => status === st.id)?.text ?? "";
      },
    },
    {
      data: "id",
      orderable: false,
      render: (id: number) => {
        return renderToString(
          <a href={`/reseller/edit/${id}`} className="d-block text-end">
            <Icon className="fa fa-fw fa-pen" />
          </a>
        );
      },
    },
  ];

  const dataTableOptions: Config = {
    dom: 'Bfrtip',
    language: ptbr,
    buttons: [
      {
        extend: "excelHtml5",
        text: '<i class="fa fa-file-excel"></i>',
        filename: "Revendas",
        className: "btn btn-success",
        exportOptions: {
          columns: ":visible",
        }
      },
      {
        extend: "csvHtml5",
        text: '<i class="fa fa-file-csv"></i>',
        filename: "Revendas",
        className: "btn btn-info",
        exportOptions: {
          columns: ":visible",
        },
        init: function (api, node) {
          $(node).css("margin-left", "5px");
        }

      }
    ],
    columns,
    ordering: false,
    lengthChange: false,
    searching: false,
    data: resellers ?? [],
  };

  useDataTable({ dataTableOptions, data: resellers ?? [], tableRef });

  const handleFilter = (e: React.FormEvent) => {
    e.preventDefault();
  };

  return (
    <MainTemplate>
      <Card className="mb-3">
        <form onSubmit={handleFilter}>
          <div className="d-flex justify-content-between">
            <h1 className="h3">Revendas</h1>
            <div>
              <a href="/reseller/add" className="btn btn-primary">
                <Icon className="fa fa-fw fa-plus" /> Nova Revenda
              </a>
            </div>
          </div>

          <div className="row">
            <div className="col col-8">
              <Input
                label="Revenda"
                placeholder="Pesquise pelo nome, CNPJ ou ResellerId da Revenda"
                value={resellerFilter}
                onChange={(e) => setResellerFilter(e.target.value)}
              />
            </div>
            <div className="col col-4">
              <Select
                label="Status"
                options={resellerStatus}
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
              />
            </div>
          </div>

          <div className="d-flex justify-content-end gap-3">
          <button className="btn btn-secondary" id="clearFilter" type="reset" onClick={() => {
              setResellerFilter("")
              setStatusFilter("0")
            }}>
              Limpar Filtro
            </button>

            <button className="btn btn-primary" type="submit">
              Filtrar <Icon className="fa fa-fw fa-filter" />
            </button>
          </div>
        </form>
      </Card>

      <Card>
        <table ref={tableRef} className="table table-sm table-striped">
          <thead>
            <tr>
              <th>Reseller ID</th> 
              <th>Razão Social</th>
              <th>Nome Fantasia</th>
              <th>Responsável</th>
              <th>Status</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </Card>
    </MainTemplate>
  );
};
