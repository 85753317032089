import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Order } from "../../../models/Order";
import { getOrderById } from "../../../services/api/OrderService";
import { MainTemplate } from "../../../components/templates/MainTemplate";
import { Loading } from "../../../components/orgs/Loading";
import { useToast } from "../../../components/atoms/Toast";
import HeaderDetailRma from "./HeaderDetailRma";
import TableDetailRma, { TableDetailRmaData } from "./TableDetailRma";

const RmaDetail: React.FC = () => {
  const location = useLocation();
  const { id: orderId } = useParams();
  const Toast = useToast();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [order, setOrder] = useState<Order>();

  const tableDetail = useMemo((): TableDetailRmaData | null => {
    if (!order) return null;

    return {
      orderId: order.id,
      resellerTotalPriceUSD: order.resellerTotalPriceUSD,
      resellerTotalPriceBRL: order.resellerTotalPriceBRL,
      invoiceTotalPriceUSD: order.invoiceTotalPriceUSD,
      invoiceTotalPriceBRL: order.invoiceTotalPriceBRL,
      items: order.items,
      status: location.state.status,
    };
  }, [location.state.status, order]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const order = await getOrderById(Number(orderId));

        setOrder(order);
      } catch (error) {
        if (error instanceof Error) {
          Toast.fire({
            icon: "error",
            title: error.message,
          });
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  if (isLoading) return <Loading />;

  if (!order) return null;

  return (
    <MainTemplate>
      <HeaderDetailRma data={order} />
      <TableDetailRma data={tableDetail} />
    </MainTemplate>
  );
};

export default RmaDetail;
