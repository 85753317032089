import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { renderToString } from "react-dom/server";
import { useToast } from "../../../components/atoms/Toast";
import { Card } from "../../../components/mols/Card";
import { Loader } from "../../../components/atoms/Loader";
import { FilterRenewList, RenewOrders } from "../../../models/Renew";
import { getRenewOrders } from "../../../services/api/RenewService";
import { putNewOrder } from "../../../services/api/OrderService";
import { ModalRenewList } from "./ModalRenewList";
import { storage } from "../../../utils/storage";
import { useErrorHandler } from "../../../hooks/useErrorHandler";

import DataTable, { DataTableRef } from "datatables.net-react";
import ptbr from "../../../assets/lib/js/dataTables/pt-br.json";
import DT from "datatables.net-dt";
import $ from "jquery";
import "datatables.net-bs5";
import "datatables.net-responsive";
import "datatables.net-buttons-bs5";
import JSZip from "jszip";
// @ts-ignore
window.JSZip = JSZip;

DataTable.use(DT);
interface TableListRenewProps {
  filters: FilterRenewList;
}

const TableListRenew: React.FC<TableListRenewProps> = ({ filters }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { handleError } = useErrorHandler();
  const dataTableRef = useRef<DataTableRef>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [confirmModalOpened, setConfirmModalOpened] = useState(false);
  const [customerId, setCustomerId] = useState(0);

  const handleCustomerDetail = useCallback(
    (customerId: number) => {
      navigate(`/customer/detail/${customerId}`);
    },
    [navigate]
  );

  const handleRenew = useCallback(
    async (customerId: number, confirm?: boolean) => {
      setCustomerId(customerId);

      if (!confirm) {
        setConfirmModalOpened(true);
        return;
      }

      try {
        setIsLoading(true);

        const result = await putNewOrder({
          customerId: customerId,
          isRenewal: true,
        });

        storage.set("currentOrder", result);
        navigate("/order/cart");
      } catch (error) {
        handleError(error, toast);
      } finally {
        setIsLoading(false);
        setConfirmModalOpened(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toast, navigate]
  );

  const loadTableRef = useRef<(data: any, callback: any) => void>();
  const loadTable = useCallback(
    async (data: any, callback: any) => {
      const { start, length, draw } = data;
      const currentPage = Math.floor(start / length);
      const newFilters = { ...filters, page: currentPage, size: length };

      try {
        const result = await getRenewOrders(newFilters);

        callback({
          draw: draw,
          recordsTotal: result.total,
          recordsFiltered: result.total,
          data: result.content,
        });
      } catch (error) {
        handleError(error, toast, "carregar a lista de Renovações");

        callback({
          draw: draw,
          recordsTotal: 0,
          recordsFiltered: 0,
          data: [],
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toast, filters]
  );

  useEffect(() => {
    loadTableRef.current = loadTable;
  }, [filters, loadTable]);

  useEffect(() => {
    if (!dataTableRef.current) return;
    dataTableRef.current.dt()?.ajax.reload();
  }, [filters]);

  useEffect(() => {
    const dt = dataTableRef.current?.dt();
    const table = dt?.table();

    table?.off("click", "tbody tr button.btnRenew");
    table?.on("click", "tbody tr button.btnRenew", function () {
      const tr = this?.parentElement?.parentElement;
      if (!tr) return;

      const rowData = dt?.row(tr).data();
      if (!rowData) return;

      handleRenew((rowData as RenewOrders).customerId);
    });

    table?.off("click", "tbody tr p.lnkCustomer");
    table?.on("click", "tbody tr p.lnkCustomer", function () {
      const tr = this?.parentElement?.parentElement;
      if (!tr) return;

      const rowData = dt?.row(tr).data();
      if (!rowData) return;

      handleCustomerDetail((rowData as RenewOrders).customerId);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTableRef]);

  return (
    <Card>
      <DataTable
        ref={dataTableRef}
        className="table table-sm table-striped"
        options={{
          dom: "<'row mb-2'<'col col-6'B><'col col-6'f>>rt<'row my-2'<'col col-12 d-flex justify-content-center'p>>",
          language: ptbr,
          buttons: [
            {
              extend: "excelHtml5",
              text: '<i class="fa fa-file-excel"></i>',
              filename: "Renovações",
              className: "btn btn-success",
              exportOptions: {
                columns: ":visible",
              },
            },
            {
              extend: "csvHtml5",
              text: '<i class="fa fa-file-csv"></i>',
              filename: "Renovações",
              className: "btn btn-info",
              exportOptions: {
                columns: ":visible",
              },
              init: function (api, node) {
                $(node).css("margin-left", "5px");
              },
            },
          ],
          lengthMenu: [
            [64, 128, 256, 512],
            [64, 128, 256, 512],
          ],
          ordering: false,
          serverSide: true,
          processing: true,
          ajax: (data: any, callback: any) => {
            if (!loadTableRef.current) return;
            loadTableRef.current(data, callback);
          },
        }}
        columns={[
          { data: "reseller", type: "string" },
          {
            data: "customer",
            type: "string",
            render: (customer: string, _: string, row: RenewOrders) => {
              return renderToString(
                <p className="pointer p-0 m-0 lnkCustomer">{customer}</p>
              );
            },
          },
          {
            data: "cotermDate",
            type: "string",
            width: "7.5em",
            render: (data) => {
              return format(new Date(data), "dd/MM/yyyy");
            },
          },
          { data: "days", type: "string", width: "10em" },
          {
            data: "customerId",
            type: "string",
            width: "3em",
            orderable: false,
            render: (customerId: string, _: string, row: RenewOrders) => {
              if (!row.canRenew) return "";

              return renderToString(
                <button
                  className="btn btn-primary btn-sm btnRenew"
                  type="button"
                >
                  Renovar
                </button>
              );
            },
          },
        ]}
      >
        <thead>
          <tr>
            <th>Revenda</th>
            <th>Cliente</th>
            <th>Dt. Aniversário</th>
            <th>Prazo de renovação</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
      </DataTable>

      <ModalRenewList
        isShown={confirmModalOpened}
        onConfirm={() => {
          handleRenew(customerId, true);
        }}
        onClose={() => setConfirmModalOpened(false)}
      />

      {isLoading && (
        <div
          className="load"
          style={{ backgroundColor: "rgba(255,255,255, 0.7)", zIndex: 9999 }}
        >
          <Loader />
        </div>
      )}
    </Card>
  );
};

export default TableListRenew;
