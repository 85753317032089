import { MainTemplate } from "../../components/templates/MainTemplate";
import { Input } from "../../components/atoms/Input";
import DataTable from "datatables.net-react";
import DT, { Config } from "datatables.net-dt";
import "datatables.net-bs5";
import React, { useRef, useState, useEffect } from "react";
import {
  getProductImports,
  sendProduct,
} from "../../services/api/ProductService";
import { format } from "date-fns";
import { useToast } from "../../components/atoms/Toast";

import $ from "jquery";
import ptbr from "../../assets/lib/js/dataTables/pt-br.json";

import "datatables.net-bs5";
import "datatables.net-responsive";
import "datatables.net-buttons-bs5";

import JSZip from "jszip";
import { Card } from "../../components/mols/Card";
// @ts-ignore
window.JSZip = JSZip;

DataTable.use(DT);
const ProductImport: React.FC = () => {
  const toast = useToast();
  const [fileInput, setInputFile] = useState<File | null>(null);
  const tableRef = useRef<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setInputFile(e.target.files[0]);
    }
  }

  function ajaxCall() {
    return (data: any, callback: any) => {
      const { start, length, draw } = data;
      const currentPage = Math.floor(start / length);
      getProductImports(currentPage || 0, length || 10).then((result) => {
        callback({
          draw: draw,
          recordsTotal: result.recordsTotal,
          recordsFiltered: result.recordsFiltered,
          data: result.data,
        });
      });
    };
  }

  useEffect(() => {
    const dataTableOptions: Config = {
      dom: "B<'row'<'col-sm-6'i><'col-sm-6'f>>rt<'row'<'col-12 d-flex justify-content-center'p>>",
      language: ptbr,
      columns: [
        { data: "s3FileName", width: "50%", type: "string" },
        {
          data: "dateCreated",
          width: "15%",
          render: (data: string) => {
            if (data) return format(new Date(data), "dd/MM/yyyy");
            else return "-";
          },
        },
        { data: "status", type: "string", width: "15%" },
      ],
      buttons: [
        {
          extend: "excelHtml5",
          text: '<i class="fa fa-file-excel"></i>',
          filename: "Importacoes",
          className: "btn btn-success",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "csvHtml5",
          text: '<i class="fa fa-file-csv"></i>',
          filename: "Importacoes",
          className: "btn btn-info",
          exportOptions: {
            columns: ":visible",
          },
          init: function (api, node) {
            $(node).css("margin-left", "5px");
          },
        },
      ],
      ordering: false,
      serverSide: true,
      processing: true,
      lengthMenu: [
        [10, 64, 128, 256, 512],
        [10, 64, 128, 256, 512],
      ],
      ajax: ajaxCall(),
    };

    if (tableRef.current) {
      if ($.fn.dataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }
      $(tableRef.current).DataTable({
        ...dataTableOptions,
        ajax: ajaxCall(),
      });
    }
  }, []);

  function uploadFile() {
    if (!fileInput) {
      toast.fire({
        icon: "warning",
        title: `Selecione um arquivo`,
      });

      return;
    }

    setIsLoading(true);
    sendProduct({ file: fileInput })
      .then((response) => {
        setIsLoading(false);
        toast
          .fire({
            icon: "info",
            title: `Upload concluído, a tela será recarregada em 3s`,
            timer: 3000,
          })
          .then(() => {
            window.location.reload();
          });
      })
      .catch((error) => {
        toast
          .fire({
            icon: "error",
            title: `Ocorreu um erro ao realizar o upload, tente novamente`,
            timer: 3000,
          })
          .then(() => {
            window.location.reload();
          });
      });
  }

  // @ts-ignore
  return (
    <MainTemplate>
      <Card className="mb-3">
        <h1>Importar Arquivo</h1>

        <div className="d-flex align-items-center justify-content-between mb-2">
          <div className="input-group">
            <Input
              label=""
              placeholder=""
              type="file"
              onChange={handleFileChange}
            />
          </div>

          <button
            className="btn btn-primary"
            onClick={uploadFile}
            disabled={isLoading}
          >
            Enviar
            {isLoading && (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
          </button>
        </div>

        <div className="d-flex align-items-center justify-content-end">
          Something
        </div>
      </Card>

      <Card>
        <table ref={tableRef} className="table table-sm table-striped">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Data de Upload</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </Card>
    </MainTemplate>
  );
};

export { ProductImport };
