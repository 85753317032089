import { AxiosError } from "axios";

import {
  CreateResellerDto,
  Reseller,
  EditResellerDto,
} from "../../models/Reseller";
import apiClient, { ErrorApi } from "./ApiClient";
import { BaseListResponse } from "../../models/Base";
import {
  Company
} from "../../models/Customer";

const apiConfig = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const getReseller = async (
  page: number,
  size: number,
  filter: string = "", 
  status: string = "" 
): Promise<Reseller[]> => {
  let url = `/Resellers?Page=${page}&Size=${size}`;

  if (filter) {
    url += `&Filter=${encodeURIComponent(filter)}`;
  }

  if (status) {
    url += `&Status=${encodeURIComponent(status)}`;
  }

  let response = await apiClient.get<any>(url);
  let resellers: Reseller[] = response.data.content;

  resellers.sort((a, b) => a.tradeName.localeCompare(b.tradeName));

  return resellers ?? [];
};

export const getResellerById = async (id: BigInt): Promise<Reseller> => {
  let response = await apiClient.get<Reseller>("/Resellers/" + id);

  return response.data as unknown as Reseller;
};

export const addReseller = async (
  reseller: CreateResellerDto
): Promise<string> => {
  return new Promise(async (res, rej) => {
    await apiClient
      .put("/Resellers", reseller, apiConfig)
      .then(() => {
        res("");
      })
      .catch((error: string) => {
        rej(error);
      });
  });
};

export const editReseller = async (
  reseller: EditResellerDto
): Promise<void> => {
  let response = await apiClient.post("/Resellers", reseller, apiConfig);
};

export const autocompleteResellers = async (search: string): Promise<Reseller[]> => {
  try {
    let url = `/resellers?filter=${search}`;
    let response = await apiClient.get<BaseListResponse<Reseller>>(url);

    return response.data.content;
  } catch (error) {
    if (error instanceof AxiosError) {
      const err = error.response?.data as ErrorApi | undefined;
      console.error("Não foi possível carregar a lista revendedores:", err?.message);
    }
    throw new Error("Não foi possível carregar a lista revendedores.");
  }
};

export const getByCnpj = async (cnpj: string): Promise<Company> => {
  const response = await apiClient.get<Company>(`public-data/cnpj/${cnpj}`);
  return response.data;
};
