export const storage = {
  set: (key: string, value: string | object): void => {
    if (typeof value === "string") localStorage.setItem(key, value);

    localStorage.setItem(key, JSON.stringify(value));
  },

  get: <T>(key: string): T | null => {
    const value = localStorage.getItem(key);
    if (!value) return null;
    return JSON.parse(value) as T;
  },

  getStr: (key: string): string | null => {
    const value = localStorage.getItem(key);
    if (!value) return null;
    return JSON.parse(value);
  },

  delete: (key: string): void => {
    localStorage.removeItem(key);
  },
};
