import { OptionsProps } from "../OptionsProps";

export enum NewOrderPaymentCondition {
  Cash,
  // AnotherWay,
  Installment7Days,
  Installment9Days,
  Installment10Days,
  Installment14Days,
  Installment15Days,
  Installment18Days,
  Installment20Days,
  Installment21Days,
  Installment28Days,
  Installment30Days,
  TwoInstallments30And60DaysEqual,
  ThreeInstallments30And60And90DaysEqual,
}

export const newOrderPaymentConditionMap: {
  [key in NewOrderPaymentCondition]: OptionsProps;
} = {
  [NewOrderPaymentCondition.Cash]: { id: "Cash", text: "À Vista" },
  // [NewOrderPaymentCondition.AnotherWay]: { id: "AnotherWay", text: "Outras formas de pagamento", },
  [NewOrderPaymentCondition.Installment7Days]: {
    id: "Installment7Days",
    text: "01 parcela: 07 dias",
  },
  [NewOrderPaymentCondition.Installment9Days]: {
    id: "Installment9Days",
    text: "01 parcela: 09 dias",
  },
  [NewOrderPaymentCondition.Installment10Days]: {
    id: "Installment10Days",
    text: "01 parcela: 10 dias",
  },
  [NewOrderPaymentCondition.Installment14Days]: {
    id: "Installment14Days",
    text: "01 parcela: 14 dias",
  },
  [NewOrderPaymentCondition.Installment15Days]: {
    id: "Installment15Days",
    text: "01 parcela: 15 dias",
  },
  [NewOrderPaymentCondition.Installment18Days]: {
    id: "Installment18Days",
    text: "01 parcela: 18 dias",
  },
  [NewOrderPaymentCondition.Installment20Days]: {
    id: "Installment20Days",
    text: "01 parcela: 20 dias",
  },
  [NewOrderPaymentCondition.Installment21Days]: {
    id: "Installment21Days",
    text: "01 parcela: 21 dias",
  },
  [NewOrderPaymentCondition.Installment28Days]: {
    id: "Installment28Days",
    text: "01 parcela: 28 dias",
  },
  [NewOrderPaymentCondition.Installment30Days]: {
    id: "Installment30Days",
    text: "01 parcela: 30 dias",
  },
  [NewOrderPaymentCondition.TwoInstallments30And60DaysEqual]: {
    id: "TwoInstallments30And60DaysEqual",
    text: "02 parcelas: 30/60 dias - iguais",
  },
  [NewOrderPaymentCondition.ThreeInstallments30And60And90DaysEqual]: {
    id: "ThreeInstallments30And60And90DaysEqual",
    text: "03 parcelas: 30/60/90 dias - iguais",
  },
};

export const getNewOrderPaymentConditionById = (
  id: string
): OptionsProps | null => {
  const options = Object.values(newOrderPaymentConditionMap);
  const found = options.find((condition) => condition.id === id);
  return found ?? null;
};

export const toNewOrderPaymentCondition = (
  key: string | null
): NewOrderPaymentCondition | null => {
  if (!key) return null;

  const paymentCondition = Object.entries(newOrderPaymentConditionMap).find(
    ([, value]) => value.id === key
  );

  return paymentCondition
    ? (paymentCondition[0] as unknown as NewOrderPaymentCondition)
    : null;
};
