import React from "react";

export interface AccordionItemProps {
  parentId?: string;
  id: number;
  header: string;
  children: React.ReactNode;
  show?: boolean;
}

export const AccordionItem: React.FC<AccordionItemProps> = ({
  parentId,
  id,
  header,
  children,
  show = false,
}) => {
  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id={`${parentId}-heading-${id}`}>
        <button
          className={`accordion-button ${show ? "" : " collapsed"}`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#${parentId}-collapse-${id}`}
          aria-expanded={show}
          aria-controls={`${parentId}-collapse-${id}`}
        >
          {header}
        </button>
      </h2>
      <div
        id={`${parentId}-collapse-${id}`}
        className={`accordion-collapse collapse${show ? " show" : ""}`}
        aria-labelledby={`${parentId}-heading-${id}`}
        data-bs-parent={`#${parentId}`}
      >
        <div className="accordion-body">{children}</div>
      </div>
    </div>
  );
};
