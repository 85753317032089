import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Input } from "../../components/atoms/Input";
import { useToast } from "../../components/atoms/Toast";

import "../../assets/styles/css/login.css";
import { changePasssword } from "../../services/PasswordService";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

const ChangePasswordFirstAccess: React.FC = () => {
  const Toast = useToast();
  const navigate = useNavigate();
  const { passwordChanged, logout, isFirstLogin } = useAuth();

  const formRef = useRef<HTMLFormElement| null>(null);

  const [password, setPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState("");

  const [newPassword, setNewPassword] = useState("");
  const [errorNewPassword, setErrorNewPassword] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorConfirmPassword, setErrorConfirmPassword] = useState("");

  const policyPassword = useMemo(() => {
    const validMinLength = newPassword.length >= 10;
    const validUppercase = newPassword.match(/([A-Z])/);
    const validLowercase = newPassword.match(/([a-z])/);
    const validNumber = newPassword.match(/([0-9])/);
    return {
      min: {
        class: validMinLength ? 'success': '',
        valid: validMinLength
      },
      uppercase: {
        class: validUppercase ? 'success': '',
        valid: validUppercase
      },
      lowercase: {
        class: validLowercase ? 'success': '',
        valid: validLowercase
      },
      number: {
        class: validNumber ? 'success': '',
        valid: validNumber
      }
    }
  }, [newPassword]);
  
  const classError = useMemo(() => {
    return errorPassword || errorNewPassword || errorConfirmPassword ? 'was-validated' : '';
  }, [errorConfirmPassword, errorNewPassword, errorPassword]);

  const isConfPasswordSamePassword = useCallback(() => {
    const form = formRef.current;
    if(!form)
      return true;

    const newPasswordRef = form.elements.namedItem('txtNewPassword') as HTMLInputElement;
    const confirmPasswordRef = form.elements.namedItem('txtConfirmPassword') as HTMLInputElement;

    if (newPasswordRef.value !== confirmPasswordRef.value) {
      confirmPasswordRef.setCustomValidity('Confirmação Senha diferente da Nova Senha');
      setErrorConfirmPassword("Confirmação Senha diferente da Nova Senha");
      return false
    }

    confirmPasswordRef.setCustomValidity('');
    setErrorConfirmPassword("Confirmação Senha diferente da Nova Senha");

    return true;
  }, []);

  const isValid = useCallback((): boolean => {
    let isValid = true;

    const form = formRef.current;
    if(!form)
      return true;

    const passwordRef = form.elements.namedItem('txtPassword') as HTMLInputElement;
    const newPasswordRef = form.elements.namedItem('txtNewPassword') as HTMLInputElement;
    const confirmPasswordRef = form.elements.namedItem('txtConfirmPassword') as HTMLInputElement;

    if (passwordRef.validity.valueMissing) {
      setErrorPassword("Senha Atual obrigatório");
      isValid = false;
    }

    if (newPasswordRef.validity.valueMissing) {
      setErrorNewPassword("Nova Senha obrigatório");
      isValid = false;
    }

    
    if (confirmPasswordRef.validity.valueMissing) {
      setErrorConfirmPassword("Confirmação Senha obrigatório");
      isValid = false;
    } else if(!isConfPasswordSamePassword()) {
      isValid = false;
    }

    if (!policyPassword.min.valid || !policyPassword.uppercase.valid 
      || !policyPassword.lowercase.valid || !policyPassword.number.valid){
      isValid = false;
    }

    return isValid;
  }, [isConfPasswordSamePassword, policyPassword.lowercase.valid, policyPassword.min.valid, policyPassword.number.valid, policyPassword.uppercase.valid]);

  const handleSubmit = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
    if (!isValid()){
      Toast.fire({
        icon: "warning",
        title: "Por favor verifique os campos.",
      });
      return;
    }

    try {
      const validation = await changePasssword({
        oldPassword: password,
        newPassword: newPassword,
      })
      
      if(validation)
      {
        Toast.fire({
          icon: "warning",
          title: validation
        });
      }
      else
      {
        passwordChanged();

        Toast.fire({
          icon: "success",
          title: "Senha alterada com sucesso!",
        });
      }
      
    } catch (error) {
      
      Toast.fire({
        icon: "error",
        title: "Erro ao solicitar a alteração de senha do primeiro acesso.",
      });
    }
  }, [Toast, isValid, newPassword, password, passwordChanged]);

  const handleBack = useCallback(() => {
    if(isFirstLogin) {
      logout();
    }

    navigate('/');
  }, [isFirstLogin, logout, navigate]);

  useEffect(() => {
    if (isFirstLogin)
      return;

    navigate('/');
  }, [isFirstLogin, navigate]);

  return (
    <div className="login-background">
      <div className="container d-flex justify-content-center align-items-center vh-100">
          <form ref={formRef} className={
            `card p-4 col-4 needs-validation ${classError}`
          } 
            onSubmit={handleSubmit} 
            noValidate
          >
            <h2 className="text-center">Primeiro Login</h2>
            <Input
              id="txtPassword"
              placeholder="Senha Atual"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              error={errorPassword}
            />

            <Input
              id="txtNewPassword"
              placeholder="Nova Senha"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              error={errorNewPassword}
            />

            <Input
              id="txtConfirmPassword"
              placeholder="Confirmação da Senha"
              type="password"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                isConfPasswordSamePassword();
              }}
              required
              error={errorConfirmPassword}
            />

            <ul id="policy-password-container">
              <li className={policyPassword.min.class}>Minímo 10 caracteres</li>
              <li className={policyPassword.uppercase.class}>Minímo 1 letra maiúscula</li>
              <li className={policyPassword.lowercase.class}>Minímo 1 letra minúscula</li>
              <li className={policyPassword.number.class}>Minímo 1 número</li>
            </ul>

            <button
              type="submit"
              className="btn btn-primary my-3"
            >
              Alterar
            </button>

            <button
              type="button" 
              className="btn btn-secondary"
              onClick={handleBack}
            >
              Voltar
            </button>
          </form>
      </div>
    </div>
  );
};

export default ChangePasswordFirstAccess;
