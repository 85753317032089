import React from "react";
import InputMask from "react-input-mask";
import { PaymentCondition } from "../../models/enums/PaymentCondition";
import { BillingFor } from "../../models/enums/BillingFor";

interface InputProps {
  id?: string;
  label?: string;
  type?: string;
  placeholder?: string;
  value?: string | Date | PaymentCondition | BillingFor | null;
  mask?: string | Array<string | RegExp>;
  required?: boolean;
  pattern?: string;
  error?: string;
  disabled?: boolean;
  readonly?: boolean;
  min?: string;
  max?: string;
  maxLength?: number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

export const Input: React.FC<InputProps> = ({
  id,
  label,
  type = "text",
  placeholder,
  value,
  mask,
  required,
  pattern,
  error,
  disabled = false,
  readonly,
  min,
  max,
  maxLength,
  onChange,
  onKeyDown,
  onFocus,
  onBlur,
}) => {
  let input;
  let valueStr = value?.toString();

  if (mask) {
    input = (
      <InputMask
        id={id}
        type={type}
        className="form-control my-2"
        placeholder={placeholder}
        value={valueStr}
        mask={mask}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onFocus={onFocus} 
        onBlur={onBlur}
        autoComplete="off"
        required={required}
        pattern={pattern}
        disabled={disabled}
        readOnly={readonly}
      />
    );
  } else {
    input = (
      <input
        id={id}
        type={type}
        className="form-control my-2"
        placeholder={placeholder}
        value={valueStr}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onFocus={onFocus} 
        onBlur={onBlur} 
        autoComplete="off"
        required={required}
        pattern={pattern}
        disabled={disabled}
        readOnly={readonly}
        min={min}
        max={max}
        maxLength={maxLength}
      />
    );
  }

  return (
    <div className="form-group">
      {label && <label {...(id && { htmlFor: id })}>{label}</label>}
      {input}
      <div className="invalid-feedback">{error}</div>
    </div>
  );
};
