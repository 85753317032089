import React from "react";

interface ModalProps {
  id?: string;
  title?: string;
  body?: string | React.ReactNode;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  isShown: boolean;
  size?: "small" | "normal" | "large" | "extra-large";
  onClose: () => void;
}

export const Modal: React.FC<ModalProps> = ({
  id,
  title,
  body,
  children,
  footer,
  isShown,
  size = "normal",
  onClose,
}) => {
  if (!isShown) return null;

  let sizeClass: string = "";

  switch (size) {
    case "small":
      sizeClass = " modal-sm";
      break;

    case "large":
      sizeClass = " modal-lg";
      break;

    case "extra-large":
      sizeClass = " modal-xl";
      break;

    case "normal":
    default:
      break;
  }

  return (
    <div
      className={`modal fade show ${sizeClass}`}
      style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      tabIndex={-1}
      role="dialog"
      aria-labelledby={id ? id : "modalLabel"}
      aria-hidden={!isShown}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id={id ? id : "modalLabel"}>
              {title ?? ""}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            ></button>
          </div>
          <div className="modal-body">{body ?? children}</div>
          {footer && <div className="modal-footer">{footer}</div>}
        </div>
      </div>
    </div>
  );
};
