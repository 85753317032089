import React from "react";
import { Icon } from "../../../components/atoms/Icon";
import { BillingFile } from "../models/BillingFile";

interface DataRowProps {
  file: BillingFile;
  index: number;
  onDownload: (fileUrl: string) => void;
}

export const DataRow: React.FC<DataRowProps> = ({
  file,
  index,
  onDownload,
}) => {
  return (
    <tr key={index}>
      <td>{file.fileName}</td>
      <td>{new Date(file.startDate).toLocaleDateString()}</td>
      <td>{new Date(file.endDate).toLocaleDateString()}</td>
      <td>{file.fileSize ? `${file.fileSize} KB` : "N/A"}</td>
      <td>
        <button
          className="btn btn-link"
          onClick={() => onDownload(file.fileUrl || "")}
        >
          {file.fileUrl ? (
            <>
              <Icon className="fa fa-download" /> Download
            </>
          ) : (
            "Não disponível"
          )}
        </button>
      </td>
    </tr>
  );
};
