import { MenuButton } from "../../models/MenuButton";

const getMenuItems = async (): Promise<MenuButton[]> => {
  
  const authUserJson = localStorage.getItem("authUser");
  const authUser = JSON.parse(authUserJson!);
  
  const formattedResponse = authUser.profile.modules.map((module: any) => ({
    id: module.moduleId,
    icon: module.icon,
    label: module.name,
    href: module.hRef,
  }));

  return formattedResponse;
};

export { getMenuItems };
