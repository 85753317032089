import { useState, useEffect } from "react";
import { Reseller } from "../models/Reseller";
import { getReseller } from "../services/api/ResellerService"; 


export const useGetFilteredResellers = (
  filter: string,
  status: string,
  page: number = 0,
  size: number = 10
) => {
  const [data, setData] = useState<Reseller[] | null>(null); 
  const [loading, setLoading] = useState<boolean>(true); 
  const [error, setError] = useState<string | null>(null); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); 
        setError(null); 

     
        const result = await getReseller(page, size, filter, status);
        
        
        setData(result);
      } catch (err) {
        setError("Erro ao carregar as revendas.");
        console.error("Erro ao buscar revendas:", err);
      } finally {
        setLoading(false); 
      }
    };

    fetchData(); 
  }, [filter, status, page, size]); 

  return { data, loading, error }; 
};
