import { useEffect } from "react";
import $ from "jquery";
import { Config } from "datatables.net-dt";

interface UseDataTableProps {
  dataTableOptions: Config;
  data: any[];
  tableRef: React.RefObject<any>;
}

export const useDataTable = ({
  dataTableOptions,
  data,
  tableRef,
}: UseDataTableProps) => {
  useEffect(() => {
    const table = $(tableRef.current);
    if (table.length && !$.fn.dataTable.isDataTable(tableRef.current)) {
      table.DataTable(dataTableOptions);
    } else if (table.length) {
      table.DataTable().clear().rows.add(data).draw();
    }
  }, [data]);
};
