import { useToast } from "../components/atoms/Toast";

export interface AddressForm {
  address: string;
  number: string;
  neighborhood: string;
  addressComplement?: string;
}

export const useValidateAddressLength = () => {
  const toast = useToast();

  const validateAddressLength = (AddressForm: AddressForm): boolean => {
    const totalLength1 = AddressForm.address.length + AddressForm.number.length;

    if (totalLength1 > 29) {
      toast.fire({
        icon: "warning",
        title: "A soma dos campos Logradouro e Número que compõem o endereço, devem ter um total de no máximo 29 caracteres."
      });
      return false;
    }

    const totalLength2 =
      AddressForm.neighborhood.length +
      (AddressForm.addressComplement ? AddressForm.addressComplement.length : 0);

    if (totalLength2 > 29) {
      toast.fire({
        icon: "warning",
        title: "A soma dos campos Bairro e Complemento que compõem o endereço, devem ter um total de no máximo 29 caracteres.",
      });
      return false;
    }

    return true;
  };

  return { validateAddressLength };
};
