import { RefreshedSkuItem } from "./RefreshSku";

export interface Product {
  id: number;
  sku: string;
  license: string;
  version: string;
  platform: string;
  configuration: string;
  language: string;
  detail1: string;
  detail2: string;
  duration: string;
  numberOfUsers: string;
  licenseLevel: string;
  transactionLevel?: string;
  points: number;
  validityStart?: string | null;
  validityEnd?: string | null;
  resellerPriceUSD: number;
  resellerPriceBRL: number;
  fobCostMonthlyPriceUSD: number;
  active: boolean;
}

export interface CustomerProduct {
  id: number;
  name: string;
  sku: string;
  details: string;
  resellerPriceUSD: number;
  resellerPriceBRL: number;
}

export interface ProductOrderPrice {
  customerId: number;
  productId: number;
  totalPriceBRL: number;
  quantity: number;
}

export interface ProductOrderRefreshedPrices {
  productId: number;
  unitPriceBRL: number;
  totalPriceBRL: number;
  unitPriceUSD: number;
  totalPriceUSD: number;
  quantity: number;
}

export const toCustomerProduct = (item: RefreshedSkuItem): CustomerProduct => {
  return {
    ...item,
    id: item.productId,
    name: item.license,
    sku: item.sku,
    details: item.detail,
  };
};
