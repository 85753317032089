import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "./Icon";

interface SidebarItemProps {
  icon: string;
  label: string;
  isSidebarOpen: boolean;
  href: string;
}

export const SidebarItem: React.FC<SidebarItemProps> = ({
  icon,
  label,
  isSidebarOpen,
  href,
}) => {
  return (
    <Link to={href} className="sidebar-item" style={{ textDecoration: "none" }}>
      <Icon className={icon} />
      {isSidebarOpen && <span className="ms-2">{label}</span>}
    </Link>
  );
};
