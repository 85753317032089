import React, { useEffect } from "react";
import { storage } from "../../utils/storage";
import { Order } from "../../models/Order";
import { useNavigate } from "react-router-dom";

export const OrderRedirect: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const currentOrder = storage.get<Order>("currentOrder");

    let url: string;

    if (!currentOrder) url = "/order/customer";
    else if (currentOrder.hubspotLink || currentOrder.isRenewal)
      url = "/order/cart";
    else url = "/order/products";

    navigate(url);
  }, [navigate]);

  return <></>;
};
