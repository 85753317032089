import React, { useState } from "react";
import { MainTemplate } from "../../components/templates/MainTemplate";
import { Card } from "../../components/mols/Card";
import { convertToCreateReseller } from "./models/ResellerForm";
import { addReseller } from "../../services/api/ResellerService";
import { useResellerForm } from "./hooks/useResellerForm";
import { toastAndRedirect, useToast } from "../../components/atoms/Toast";
import { ResellerFormTemplate } from "./templates/ResellerFormTemplate";
import { AddressForm, useValidateAddressLength } from "../../hooks/useValidateAddressLength";

export const NewReseller: React.FC = () => {
  const toast = useToast();
  const {
    formData,
    setFormData,
    handleInputChange,
    handleCheckboxChange,
    handleError,
  } = useResellerForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { validateAddressLength } = useValidateAddressLength();

  const submitForm = async (evt: React.FormEvent) => {
    evt.preventDefault();

    const trimmedName = formData.contactName.trim();
    const nameParts = trimmedName.split(" ");

    if (nameParts.length < 2) {
      toast.fire({
        icon: "warning",
        title: "Por favor, insira pelo menos dois nomes no campo Nome Completo.",
      });
      return;
    }

    const addressForm: AddressForm = {
      address: formData.street,
      number: formData.number,
      neighborhood: formData.neighborhood,
      addressComplement: formData.addressComplement
    };

    if (!validateAddressLength(addressForm)) {
      return;
    }

    try {
      setIsSubmitting(true);
      const updatedFormData = { ...formData, contactName: trimmedName };
      const reseller = convertToCreateReseller(updatedFormData);
      await addReseller(reseller);

      toastAndRedirect(
        toast,
        "success",
        "Revenda criada",
        "/resellers",
        "Você será redirecionado para a tela de revendas em 3s"
      );
    } catch (ex) {
      handleError(ex, toast);
    } finally {
      setIsSubmitting(false);
    }
  };  

  return (
    <MainTemplate>
      <Card>
        <ResellerFormTemplate
          title="Cadastrar Revenda"
          formData={formData}
          setFormData={setFormData}
          handleCheckboxChange={handleCheckboxChange}
          handleInputChange={ handleInputChange}
          submitForm={submitForm}
          isLoading={isSubmitting}
        />
      </Card>
    </MainTemplate>
  );
};
