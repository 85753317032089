import React from "react";
import { useNavigate } from "react-router-dom";
import { MainTemplate } from "../components/templates/MainTemplate";
import { Icon } from "../components/atoms/Icon";

interface ErrorPageProps {
  statusCode: "403" | "404";
}

export const ErrorPage: React.FC<ErrorPageProps> = ({ statusCode }) => {
  const navigate = useNavigate();

  let icon: string = "";
  let title: string = "";
  let message: string = "";

  switch (statusCode) {
    case "403":
      icon = "fa fa-fw fa-ban";
      title = "Você não tem acesso a essa página";
      message = "Entre em contato com a equipe da PARS.";
      break;

    case "404":
      icon = "fas fa-fw fa-magnifying-glass";
      title = "Página não encontrada";
      message = "Verifique a URL e tente novamente";
      break;

    default:
      icon = "fa fa-fw fa-bug";
      title = "Houve um erro desconhecido";
      message = "Verifique a URL e tente novamente";
      break;
  }

  return (
    <MainTemplate>
      <div className="text-center">
        <div className="display-2 text-muted">
          <Icon className={icon} />
        </div>

        <h1 className="display-1 ">
          <strong>ERRO {statusCode}</strong>
        </h1>

        <h2 className="display-5">{title}</h2>

        <p>{message}</p>

        <button className="btn btn-secondary" onClick={() => navigate(-1)}>
          <Icon className="fa fa-fw fa-chevron-left" />
          Voltar
        </button>
      </div>
    </MainTemplate>
  );
};
