import {
  CreateResellerDto,
  EditResellerDto,
  Reseller,
} from "../../../models/Reseller";

export interface ResellerForm {
  id?: number;
  resellerId?: string;
  cnpj: string;
  stateRegistration: string;
  municipalRegistration: string;
  corporateName: string;
  tradeName: string;
  activity: {
    [key: string]: boolean;
  };
  membership: string;
  zipCode: string;
  city: string;
  state: string;
  street: string;
  neighborhood: string;
  number: string;
  addressComplement?: string;
  contactName: string;
  phoneType: string;
  contactPhone: string;
  contactEmail: string;
  dateCreated?: string;
  accountLevel: string;
  expirationDate: Date | string | null;
}

const isValidCnpj = (cnpj: string): boolean => {
  const weights = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
  const cleanedCnpj = cnpj.replace(/[^0-9]/g, "");

  // O CNPJ deve ter exatamente 14 dígitos
  if (cleanedCnpj.length !== 14) return false;
  // O CNPJ não pode ser composto de todos os dígitos iguais (por exemplo, '00000000000000')
  if (/^0{14}$/.test(cleanedCnpj)) return false;

  // Calcula o primeiro dígito verificador
  let sum = 0;
  for (let i = 0; i < 12; i++) {
    sum += parseInt(cleanedCnpj[i]) * weights[i + 1];
  }
  let firstVerificationDigit = sum % 11 < 2 ? 0 : 11 - (sum % 11);

  if (parseInt(cleanedCnpj[12]) !== firstVerificationDigit) return false;

  // Calcula o segundo dígito verificador
  sum = 0;
  for (let i = 0; i < 13; i++) {
    sum += parseInt(cleanedCnpj[i]) * weights[i];
  }
  let secondVerificationDigit = sum % 11 < 2 ? 0 : 11 - (sum % 11);

  // Verifica se o segundo dígito verificador está correto
  if (parseInt(cleanedCnpj[13]) !== secondVerificationDigit) return false;

  return true;
};

export const convertToCreateReseller = (
  formData: ResellerForm
): CreateResellerDto => {
  const {
    activity: { businessSegment, educationSegment, governmentSegment },
    expirationDate,
    cnpj,
    zipCode,
    contactPhone,
    ...rest
  } = formData;

  if (expirationDate === null)
    throw new Error("Data de expiração é um campo obrigatório!");
  if (!businessSegment && !educationSegment && !governmentSegment)
    throw new Error("Escolha ao menos um segmento para a Revenda");
  if (!isValidCnpj(cnpj)) throw new Error("CNPJ inválido!");

  let request: CreateResellerDto = {
    businessSegment,
    educationSegment,
    governmentSegment,
    expirationDate: new Date(expirationDate ?? Date.now()).toISOString(),
    cnpj: cnpj.replace(/[^\d]/g, ""),
    zipCode: zipCode.replace(/[^\d]/g, ""),
    contactPhone: contactPhone.replace(/[^\d]/g, ""),
    ...rest,
  };

  return request;
};

export const convertToEditResellerDto = (
  formData: ResellerForm
): EditResellerDto => {
  const {
    activity: { businessSegment, educationSegment, governmentSegment },
    id,
    resellerId,
    expirationDate,
    cnpj,
    zipCode,
    contactPhone,
    ...rest
  } = formData;

  if (id === null) throw new Error("ID é um campo obrigatório!");
  if (expirationDate === null)
    throw new Error("Data de expiração é um campo obrigatório!");
  if (!businessSegment && !educationSegment && !governmentSegment)
    throw new Error("Escolha ao menos um segmento para a Revenda");
  if (!isValidCnpj(cnpj)) throw new Error("CNPJ inválido!");

  let request: EditResellerDto = {
    id: Number(id),
    resellerId,
    businessSegment,
    educationSegment,
    governmentSegment,
    expirationDate: new Date(expirationDate ?? Date.now()).toISOString(),
    cnpj: cnpj.replace(/[^\d]/g, ""),
    zipCode: zipCode.replace(/[^\d]/g, ""),
    contactPhone: contactPhone.replace(/[^\d]/g, ""),
    ...rest,
  };

  return request;
};

export const convertToResellerForm = (reseller: Reseller): ResellerForm => {
  const { businessSegment, educationSegment, governmentSegment, ...rest } =
    reseller;

  let response: ResellerForm = {
    activity: {
      businessSegment: businessSegment,
      educationSegment: educationSegment,
      governmentSegment: governmentSegment,
    },
    ...rest,
  };

  return response;
};
