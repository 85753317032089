import { useState } from "react";
import { Card } from "../../../components/mols/Card";
import { useToast } from "../../../components/atoms/Toast";
import { TextArea } from "../../../components/atoms/TextArea";
import { useNavigate } from "react-router-dom";

export interface OrderStatusFormProps {
  data: string;
  orderId: number;
  onApprove(comments: string, orderId: number): void;
  onReject(comments: string, orderId: number): void;
}

export const OrderStatusFooter: React.FC<OrderStatusFormProps> = ({
  data,
  orderId,
  onApprove,
  onReject,
}) => {
  const [comments, setComments] = useState<string>(data);
  const Toast = useToast();
  const navigate = useNavigate();

  const handleApprove = () => {
    if (!comments.trim()) {
      Toast.fire({
        icon: "warning",
        title: "É necessário inserir comentários para aprovar.",
      });
      return;
    }
    onApprove(comments.trim(), orderId);
  };

  const handleReject = () => {
    if (!comments.trim()) {
      Toast.fire({
        icon: "warning",
        title: "É necessário inserir comentários para reprovar.",
      });
      return;
    }
    onReject(comments.trim(), orderId);
  };

  const handleBack = () => {
    navigate("/orders/history", { replace: true });
  };

  return (
    <>
      <Card className="mb-3">
        <h2 className="h5">Comentários</h2>
        <TextArea
          label=""
          rows={5}
          value={comments}
          onChange={(e) => setComments(e.target.value)}
        />
      </Card>

      <div className="d-flex justify-content-end gap-2">
        <button className="btn btn-secondary" onClick={handleBack}>
          Voltar
        </button>
        <button className="btn btn-danger" onClick={handleReject}>
          Reprovar
        </button>
        <button className="btn btn-success" onClick={handleApprove}>
          Aprovar
        </button>
      </div>
    </>
  );
};
