import React, { useCallback, useEffect, useRef, useState } from 'react';
import DataTable, { DataTableRef } from 'datatables.net-react';
import DT from 'datatables.net-dt';
import ptbr from "../../../assets/lib/js/dataTables/pt-br.json";

import 'datatables.net-bs5';
import 'datatables.net-responsive';
import 'datatables.net-buttons-bs5';
import { OrderHistoryFilterRequest } from '../../Orders/OrdersHistory/OrdersHistoryList';
import { orderStatus } from '../../../models/enums/orderStatus';
import { orderType } from '../../../models/enums/orderType';
import { getOrdersHistory } from '../../../services/api/OrderService';
import { useToast } from '../../../components/atoms/Toast';
import { Card } from '../../../components/mols/Card';
import { OrderHistoryResponse } from '../../../models/OrderHistory';

DataTable.use(DT);

function mapOrderHistoryResponseByCustomer(apiData: OrderHistoryResponse[]): OrderHistoryResponse[] | null {
    if (!(apiData.length > 0)) {
        return null;
    }

    return apiData.map((item) => ({
        id: item.id,
        customerIdAdobe: item.customer.split(" - ")[0]?.trim() || "",
        reseller: item.reseller,
        customer: item.customer,
        orderDate: new Date(item.orderDate).toLocaleDateString('pt-BR'),
        totalValue: item.totalValue,
        pedCustomer: item.pedCustomer,
        pedReseller: item.pedReseller,
        pedAdobe: item.pedAdobe,
        status: item.status as orderStatus
    }));
}

interface CustomerOrderHistoryProps {
    customerId: number;
    resellerId: number;
};

export const CustomerOrderHistory: React.FC<CustomerOrderHistoryProps> = ({ customerId, resellerId }) => {
    const Toast = useToast();
    
    const [filters] = useState<OrderHistoryFilterRequest>({
        page: 0,
        size: 20,
        startDate: null,
        endDate: null,
        resellerId: resellerId,
        customerId: customerId,
        customerIdAdobe: null,
        type: orderType.All,
        status: orderStatus.All,
        value: null,
        pedCustomer: null,
        pedReseller: null,
        pedAdobe: null
    });

    const dataTableRef = useRef<DataTableRef>(null);

    const loadTableRef = useRef<(data: any, callback: any) => void>();

    const loadTable = useCallback(async (data: any, callback: any) => {
        const { start, length, draw } = data;
        const currentPage = Math.floor(start / length);

        const newFilters = { ...filters, page: currentPage, size: length };

        try {
            const result = await getOrdersHistory(newFilters);

            const mappedData = mapOrderHistoryResponseByCustomer(result?.content ?? []);

            callback({
                draw: draw,
                recordsTotal: result?.total,
                recordsFiltered: result?.total,
                data: mappedData
            });
        } catch (error) {
            if (error instanceof Error) {
                Toast.fire({
                    icon: "error",
                    title: error.message,
                });
            }

            callback({
                draw: draw,
                recordsTotal: 0,
                recordsFiltered: 0,
                data: [],
            });
        }
    }, [Toast, filters]);

    useEffect(() => {
        loadTableRef.current = loadTable;
    }, [filters, loadTable]);

    useEffect(() => {
        if (dataTableRef.current) {
            dataTableRef.current.dt()?.ajax.reload();
        }
    }, [filters]);

    return (
        <Card>
            <DataTable
                ref={dataTableRef}
                className="table table-sm table-striped"
                options={{
                    language: ptbr,
                    ordering: false,
                    serverSide: true,
                    processing: true,
                    lengthChange: false,
                    search: false,
                    searching: false,
                    autoWidth: false,
                    columnDefs: [
                        { width: "15%", targets: 0, className: "text-center" },
                        { width: "15%", targets: 1, className: "text-center" },
                        { width: "20%", targets: 2, className: "text-center" },
                        { width: "20%", targets: 3, className: "text-center" },
                        { width: "30%", targets: 4, className: "text-center" }
                    ],
                    ajax: (data: any, callback: any) => {

                        if (loadTableRef.current) {
                            loadTableRef.current(data, callback);
                        }
                    }
                }}
                columns={[
                    {
                        data: "orderDate",
                        title: "Data do Pedido",
                        render: (data: string) =>
                            `<div style="text-align: center;">${data}</div>`,
                    },
                    {
                        data: "totalValue",
                        title: "Valor Total",
                        render: (data: number) =>
                            `<div style="text-align: center;">
                            R$ ${new Intl.NumberFormat('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }).format(data)}
                          </div>`,
                    },
                    {
                        data: "pedCustomer",
                        title: "Ped. Cliente",
                        render: (data: string) =>
                            `<div style="text-align: center;">${data ?? "-"}</div>`,
                    },
                    {
                        data: "pedAdobe",
                        title: "Ped. Adobe",
                        render: (data: string) =>
                            `<div style="text-align: center;">${data ?? "-"}</div>`,
                    },
                    {
                        data: "status",
                        title: "Status",
                        render: (data: string) => {
                            let statusText = "";

                            switch (data) {
                                case "Draft":
                                    statusText = "Rascunho";
                                    break;
                                case "CreditAnalysis":
                                    statusText = "Em Análise de Crédito";
                                    break;
                                case "CreditDenied":
                                    statusText = "Crédito Negado";
                                    break;
                                case "FinancialRefused":
                                    statusText = "Recusado Financeiro";
                                    break;
                                case "WaitingSendingAdobe":
                                    statusText = "Aguardando Envio Adobe";
                                    break;
                                case "Processed":
                                    statusText = "Processado";
                                    break;
                                case "WaitingSendingBilling":
                                    statusText = "Aguardando Faturamento";
                                    break;
                                case "Billed":
                                    statusText = "Faturado";
                                    break;
                                case "ErrorSendingAdobe":
                                    statusText = "Erro no Envio Adobe";
                                    break;
                                default:
                                    statusText = "Desconhecido";
                                    break;
                            }

                            return `<div style="text-align: center;">${statusText}</div>`;
                        },
                    }
                ]}
            >
            </DataTable>
        </Card>
    );
};