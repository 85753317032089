import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import { PrivateRoute } from "../components/mols/PrivateRoute";
import { ErrorPage } from "../pages/ErrorPage";
import { RenewList } from "../pages/Renew";
import { BillingReport } from "../pages/Billing/BillingReport";
import ForgotPassword from "../pages/ForgotPassword";
import {
  OrderCart,
  OrderRedirect,
  OrderSelectCustomer,
  OrderSelectProduct,
} from "../pages/Orders";
import OrderHistoryList from "../pages/Orders/OrdersHistory/OrdersHistoryList";
import { OrderStatus } from "../pages/Orders/OrderStatus/OrderStatus";
import { CustomerList } from "../pages/Customer/CustomerList/CustomerList";
import { NewCustomer } from "../pages/Customer/NewCustomer";
import DetailCustomer from "../pages/Customer/DetailCustomer";
import { EditCustomer } from "../pages/Customer/EditCustomer";
import { ProductImport } from "../pages/Product/ProductImport";
import { ResellerList } from "../pages/Reseller/ResellerList";
import { NewReseller } from "../pages/Reseller/NewReseller";
import { EditReseller } from "../pages/Reseller/EditReseller";
import { ResellerChange } from "../pages/Reseller/ResellerChange";
import { RmaDetail, RmaList } from "../pages/Rma";
import { UserList } from "../pages/Users/UserList";
import { NewUser } from "../pages/Users/NewUser";
import { EditUser } from "../pages/Users/EditUser";
import ChangePasswordFirstAccess from "../pages/ChangePasswordFirstAccess";

export const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<PrivateRoute code="*" element={<Home />} />} />
    <Route path="*" element={<ErrorPage statusCode="404" />} />

    {/* Billing */}
    <Route
      path="/billing"
      element={<PrivateRoute code="BILLING" element={<BillingReport />} />}
    />

    {/* Customers */}
    <Route
      path="/customers"
      element={<PrivateRoute code="CUSTOMERS" element={<CustomerList />} />}
    />
    <Route
      path="/customer/add"
      element={<PrivateRoute code="CUSTOMERS" element={<NewCustomer />} />}
    />
    <Route
      path="/customer/detail/:id"
      element={<PrivateRoute code="CUSTOMERS" element={<DetailCustomer />} />}
    />
    <Route
      path="/customer/edit/:id"
      element={<PrivateRoute code="CUSTOMERS" element={<EditCustomer />} />}
    />

    {/* Orders */}
    <Route
      path="/orders"
      element={<PrivateRoute code="ORDERS" element={<OrderRedirect />} />}
    />
    <Route
      path="/order/customer"
      element={<PrivateRoute code="ORDERS" element={<OrderSelectCustomer />} />}
    />
    <Route
      path="/order/products"
      element={<PrivateRoute code="ORDERS" element={<OrderSelectProduct />} />}
    />
    <Route
      path="/order/cart"
      element={<PrivateRoute code="ORDERS" element={<OrderCart />} />}
    />

    <Route
      path="/orders/history"
      element={
        <PrivateRoute code="ORDER_HISTORY" element={<OrderHistoryList />} />
      }
    />
    <Route
      path="/orders/status/:id"
      element={<PrivateRoute code="ORDER_HISTORY" element={<OrderStatus />} />}
    />

    <Route
      path="/renew"
      element={<PrivateRoute code="RENEW" element={<RenewList />} />}
    />

    {/* Products */}
    <Route
      path="/product/import"
      element={
        <PrivateRoute code="PRODUCT_IMPORT" element={<ProductImport />} />
      }
    />

    {/* Resellers */}
    <Route
      path="/resellers"
      element={<PrivateRoute code="RESELLERS" element={<ResellerList />} />}
    />

    <Route
      path="/reseller/add"
      element={<PrivateRoute code="RESELLERS" element={<NewReseller />} />}
    />

    <Route
      path="/reseller/edit/:id"
      element={<PrivateRoute code="RESELLERS" element={<EditReseller />} />}
    />

    <Route
      path="/resellerchange"
      element={
        <PrivateRoute code="RESELLER_CHANGE" element={<ResellerChange />} />
      }
    />

    {/* RMA */}
    <Route
      path="/rma"
      element={<PrivateRoute code="RMA" element={<RmaList />} />}
    />
    <Route
      path="/rma/detail/:id"
      element={<PrivateRoute code="RMA" element={<RmaDetail />} />}
    />

    {/* Users */}
    <Route
      path="/users"
      element={<PrivateRoute code="USERS" element={<UserList />} />}
    />
    <Route
      path="/user/add"
      element={<PrivateRoute code="USERS" element={<NewUser />} />}
    />
    <Route
      path="/user/edit/:id"
      element={<PrivateRoute code="USERS" element={<EditUser />} />}
    />

    {/* Login */}
    <Route
      path="/change-password-first-access"
      element={
        <PrivateRoute code="USERS" element={<ChangePasswordFirstAccess />} />
      }
    />
    <Route path="/forgot-password" element={<ForgotPassword />} />
  </Routes>
);
