import apiClient from "./ApiClient";
import { ResellerChange } from "../../models/ResellerChange"; 

const apiConfig = {
  headers: {
    "Content-Type": "application/json",
  },
};


export const getResellerChanges = async (
  page: number,
  size: number,
  filter?: string
): Promise<{ content: ResellerChange[]; total: number }> => {
  const url = `/reseller-changes?Page=${page}&Size=${size}${filter ? `&filter=${filter}` : ""}`;
  const response = await apiClient.get<any>(url);

  return {
    content: response.data.content as ResellerChange[],
    total: response.data.total,
  };
};

export const submitExchangeRequest = async (request: { customerId: string; approvalCode: string; resellerId: number }) => {
  const response = await apiClient.put("/reseller-changes", request);
  return response.data;
};
