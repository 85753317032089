import { AxiosError } from "axios";

export const handleAxiosError = (error: unknown): Error => {
  if (!(error instanceof AxiosError)) return new Error(`${error}`);

  const ex = error.response?.data ?? error;

  if ([404, 406].includes(error.status ?? 0)) return new Error(ex.message);

  if (error.status === 400) return new Error(ex.title);

  return new Error(`Erro ${error.status}: ${ex.message}`);
};
