export const licenseLevel: Record<string, number> = {
  Level1: 1,
  Level2: 2,
  Level3: 3,
  Level4: 4,
  Level12: 12,
  Level13: 13,
  Level14: 14,
};

export const toLicenseLevel = (level?: string | null): number | null => {
  if (!level) return null;
  const value = licenseLevel[level];
  return value !== undefined ? value : null;
};
