import { OrderFinish } from "../models/Order";
import {
  AddOrderItem,
  OrderFinishItem,
  OrderItem,
  toAddOrderItem,
} from "../models/OrderItem";
import {
  ProductOrderPrice,
  ProductOrderRefreshedPrices,
} from "../models/Product";
import {
  RefreshedSku,
  RefreshedSkuItem,
  RefreshSkuItem,
} from "../models/RefreshSku";
import { handleAxiosError } from "../utils/handleAxiosError";
import { storage } from "../utils/storage";
import {
  patchOrderItems,
  postFinishOrder,
  refreshProductOrderPrice,
} from "./api/OrderService";
import { refreshProducts } from "./api/ProductService";

const removeDuplicatesBySku = <T extends RefreshedSkuItem | RefreshSkuItem>(
  products: T[]
): T[] => {
  const skuMap = new Map<string, T>();
  products.forEach((item) => {
    skuMap.set(item.sku, item);
  });

  return Array.from(skuMap.values());
};

const removeDuplicatesById = <T extends OrderFinishItem>(items: T[]): T[] => {
  const itemMap = new Map<number, T>();
  items.forEach((item) => {
    itemMap.set(item.productId, item);
  });

  return Array.from(itemMap.values());
};

const updateCart = async (
  orderId: number,
  refreshedSkuItemList: RefreshedSkuItem[]
) => {
  const orderItems: AddOrderItem[] = refreshedSkuItemList.map((item) => {
    return toAddOrderItem(orderId, item);
  });

  await patchOrderItems(orderId, orderItems);
};

const updateLicenseLevel = (cart: RefreshedSku) => {
  const current = storage.get<string>("licenseLevel");
  const licenseLevel = cart.licenseLevel ?? current;
  storage.set("licenseLevel", licenseLevel);
  return licenseLevel;
};

const updateTransactionLevel = (cart: RefreshedSku) => {
  const current = storage.get<string>("transactionLevel");
  const transactionLevel = cart.transactionLevel ?? current;
  storage.set("transactionLevel", transactionLevel);
  return transactionLevel;
};

/** Refresh the price of the product based on API */
const getRefreshedProduct = async (
  updatedProduct: ProductOrderPrice
): Promise<ProductOrderRefreshedPrices> => {
  if (updatedProduct.quantity === 0)
    return {
      ...updatedProduct,
      unitPriceBRL: 0,
      totalPriceBRL: 0,
      unitPriceUSD: 0,
      totalPriceUSD: 0,
    };

  return await refreshProductOrderPrice(updatedProduct);
};

/** Create a AddOrderItem object to call API */
const updateOrderItems = (
  orderItems: OrderItem[],
  refreshedProduct: ProductOrderRefreshedPrices
): AddOrderItem[] => {
  return orderItems.map((item) => {
    if (item.productId !== refreshedProduct.productId) return { ...item };

    return {
      ...item,
      quantity: refreshedProduct.quantity,
      invoicePriceUSD: refreshedProduct.unitPriceUSD,
      invoicePriceBRL: refreshedProduct.unitPriceBRL,
      totalInvoicePriceBRL: refreshedProduct.totalPriceBRL,
      totalInvoicePriceUSD: refreshedProduct.totalPriceUSD,
    };
  });
};

const resetOrderItems = (orderItems: OrderItem[]): AddOrderItem[] => {
  return orderItems.map((item) => {
    return {
      ...item,
      invoicePriceUSD: item.resellerPriceUSD,
      invoicePriceBRL: item.resellerPriceBRL,
      totalInvoicePriceUSD: item.resellerPriceUSD * item.quantity,
      totalInvoicePriceBRL: item.resellerPriceBRL * item.quantity,
    };
  });
};

export const updateProductPrices = async (
  orderId: number,
  updatedProduct: ProductOrderPrice,
  orderItems: OrderItem[]
): Promise<void> => {
  const refreshedProduct = await getRefreshedProduct(updatedProduct);
  const products = updateOrderItems(orderItems, refreshedProduct);
  await patchOrderItems(orderId, products);
};

export const refreshProductsInCart = async (
  orderId: number,
  skuList: RefreshSkuItem[],
  updatedSkus: RefreshSkuItem[]
): Promise<RefreshedSkuItem> => {
  const currentSku: RefreshSkuItem = updatedSkus[0];

  skuList = removeDuplicatesBySku<RefreshSkuItem>([...skuList, currentSku]);
  const refreshedCart = await refreshProducts(orderId, skuList);

  const response = refreshedCart.products.find(
    (product) => product.sku.slice(0, 8) === currentSku.sku.slice(0, 8)
  );

  if (!response) throw new Error("Falha ao atualizar o SKU");

  return response;
};

export const refreshCart = async (
  orderId: number,
  skuList: RefreshSkuItem[]
): Promise<RefreshedSku> => {
  try {
    skuList = removeDuplicatesBySku<RefreshSkuItem>(skuList);
    let cart: RefreshedSku = await refreshProducts(orderId, skuList);
    cart.products = removeDuplicatesBySku<RefreshedSkuItem>(cart.products);

    await updateCart(orderId, cart.products);

    cart.licenseLevel = updateLicenseLevel(cart);
    cart.transactionLevel = updateTransactionLevel(cart);

    return cart;
  } catch (error) {
    throw handleAxiosError(error);
  }
};

export const updateSkuList = (
  product: RefreshSkuItem,
  skuList: RefreshSkuItem[]
): RefreshSkuItem[] => {
  const { productId, quantity } = product;
  const alreadyInCart = skuList.some((item) => item.productId === productId);

  let updatedSkuList: RefreshSkuItem[];
  if (!alreadyInCart) {
    updatedSkuList = [...skuList, product];
  } else {
    updatedSkuList = skuList.map((item) =>
      item.productId !== productId
        ? item
        : { productId, quantity, sku: item.sku }
    );
  }
  return updatedSkuList;
};

export const resetProductPrices = async (
  orderId: number,
  orderItems: OrderItem[]
): Promise<void> => {
  const products: AddOrderItem[] = resetOrderItems(orderItems);
  await patchOrderItems(orderId, products);
};

export const editProductPrice = async (
  orderId: number,
  customerId: number,
  orderItems: OrderItem[],
  productId: number,
  quantity: number,
  invoicePriceBRL: number
): Promise<void> => {
  const productPrice: ProductOrderPrice = {
    customerId: customerId,
    productId: productId,
    quantity: quantity,
    totalPriceBRL: Number(invoicePriceBRL.toFixed(2)),
  };

  await updateProductPrices(orderId, productPrice, orderItems);
};

export const dropOrder = () => {
  storage.delete("currentOrder");
  storage.delete("skuList");
  storage.delete("licenseLevel");
  storage.delete("transactionLevel");
  storage.delete("billingFor");
};

export const finishOrder = async (orderId: number, order: OrderFinish) => {
  order.items = removeDuplicatesById(order.items);
  order.items = order.items.filter((item) => item.quantity > 0);
  await postFinishOrder(orderId, order);
  dropOrder();
};
