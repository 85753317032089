import React, { Dispatch, SetStateAction } from "react";
import { Input } from "../../components/atoms/Input";
import { Icon } from "../../components/atoms/Icon";
import { orderStatusOptions } from "./models/orderStatusOptions";

interface GenerateFormProps {
  startDate: string;
  endDate: string;
  status: string;
  setStartDate: Dispatch<SetStateAction<string>>;
  setEndDate: Dispatch<SetStateAction<string>>;
  setStatus: Dispatch<SetStateAction<string>>;
  onGenerateFile: () => void;
}

export const GenerateForm: React.FC<GenerateFormProps> = ({
  startDate,
  endDate,
  status,
  setStartDate,
  setEndDate,
  setStatus,
  onGenerateFile,
}) => {
  return (
    <div className="row">
      <div className="col col-md-3 col-6">
        <Input
          label="Data Inicial"
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
      </div>
      <div className="col col-md-3 col-6">
        <Input
          label="Data Final"
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
      </div>
      <div className="col col-md-3 col-6">
        <label>Status</label>
        <select
          className="form-select my-2"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
        >
          {orderStatusOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          ))}
        </select>
      </div>

      <div className="col col-md-3 col-6">
        <p className="mb-2">&nbsp;</p>
        <button className="btn btn-primary w-100" onClick={onGenerateFile}>
          Gerar <Icon className="fa fa-download" />
        </button>
      </div>
    </div>
  );
};
