import React, { useCallback, useState } from "react";

import { FilterRmaList } from "../../../models/Rma";

import { MainTemplate } from "../../../components/templates/MainTemplate";

import FilterListRma, { FormFilterListRma } from "./FilterListRma";
import TableListRma from "./TableListRma";
import { Card } from "../../../components/mols/Card";

const RmaList: React.FC = () => {
  const [filters, setFilters] = useState<FilterRmaList>({
    page: 0,
    size: 10,
    resellerId: "",
    customerId: "",
    rmaCode: "",
    pedAdobe: "",
    pedCustomer: "",
    pedReseller: "",
  });

  const handleSearch = useCallback((form: FormFilterListRma): void => {
    setFilters({ ...form, page: 0, size: 10 });
  }, []);

  return (
    <MainTemplate>
      <Card className="mb-3">
        <h1 className="h3">RMA</h1>
        <FilterListRma onSearch={handleSearch} />
      </Card>

      <TableListRma filters={filters} />
    </MainTemplate>
  );
};

export default RmaList;
