import { useEffect, useState } from "react";
import { Profile } from "../models/Profiles";
import { getProfiles } from "../services/api/ProfileService";

let cachedProfiles: Profile[] | null = null;

export const useGetProfiles = () => {
  const [data, setData] = useState<Profile[] | null>(cachedProfiles);
  const [loading, setLoading] = useState<boolean>(!cachedProfiles);

  useEffect(() => {
    if (!cachedProfiles) {
      const fetchData = async () => {
        const result = await getProfiles(0, 1000);
        cachedProfiles = result;
        
        setData(result);
        setLoading(false);
      };
      fetchData();
    }
  }, []);

  return { data, loading };
};
