export enum AdobeStatus {
  Pending = "Pending",
  Processing = "Processing",
  Completed = "Completed",
  Failed = "Failed",
}

export const adobeStatusMap: { id: string; text: string }[] = [
  { id: "0", text: "Todos" },
  { id: AdobeStatus.Processing, text: "Processando" },
  { id: AdobeStatus.Pending, text: "Pendente" },
  { id: AdobeStatus.Completed, text: "Completo" },
  { id: AdobeStatus.Failed, text: "Falha" },
];
